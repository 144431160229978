@import url("https://fonts.googleapis.com/css2?family=Amatic+SC&display=swap");

// Variables
$COLOR_LIGHT: var(--COLOR_LIGHT);

// Main styles
.logo {
  display: inline-block;
  padding: 1rem 2rem;
  z-index: 5;
  filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.5));
  background-color: transparent;

  .logo__content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 0.2rem;
    flex-wrap: nowrap;

    .logo__img {
      width: 25%;
      cursor: pointer;
    }

    .logo__title {
      color: $COLOR_LIGHT;
      cursor: pointer;
      font-family: "Amatic SC", cursive;
      font-size: 2.5rem;
    }
  }
}
