$input-background-color: #f3f6ff;
$input-padding: 15px 20px;
$input-border-radius: 22px;
$button-border-radius: 22px;
$button-hover-background-color: #1050d1;

@keyframes slideUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes popUp {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes blinkBorder {
    0%, 100% {
      border: 1px solid #1050d1;
    }
    50% {
      border: 1px solid #05338f;
    }
  }

.input {
  height: 55px;
  background-color: $input-background-color;
  padding: $input-padding;
  position: relative;
  animation: slideUp 0.6s ease-out, popUp 0.3s ease-in;

  .alert {
    position: absolute;
    top: -10%;
    left: 4%;
    color: #eb2562;
    font-size: 11px;
    width: calc(100% - 50px);
    text-align: center;
    animation: popUp 0.3s ease-in;
  }

  .inputs {
    display: flex;
    align-items: center;
    gap: 5px;
    animation: slideUp 0.6s ease-out;

    textarea {
      width: calc(100% - 30px);
      resize: none;
      border: none;
      outline: none;
      padding: 10px 10px;
      padding-top: 15px;
      padding-bottom: 7px;
      font-size: 14px;
      border-radius: $input-border-radius;
      height: 30px;
      transition-duration: 0.3s;
      @media screen and (max-width: 480px) {
        width: calc(100% - 10px);
        font-size: 12px;
        padding: 5px 5px;
        padding-top: 15px;
        padding-bottom: 4px;
        border-radius: 6px;
      }
      &:focus {
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      }
    
    }
  }

  

  .send {
    display: flex;
    align-items: center;
    gap: 6px;
    position: relative;
    animation: slideUp 0.6s ease-out;
    @media screen and (max-width: 480px) {
      flex-direction: row;
    }

    label,
    .emojis {
      font-size: 18px;
      background-color: $input-background-color;
      border: 1px solid #24242418;
      padding: 7px;
      height: 30px;
      width: 30px;
      border-radius: 50%;
      transition-duration: 0.4s;
      display: flex;
      align-items: center;
      justify-content: center;


      &:hover {
        cursor: pointer;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
          rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
        border: none;
      }
    }
    @media screen and (max-width: 480px) {
      .emojis{
        display: none;
      }
    }

    button {
      border: none;
      display: flex;
      align-items: center;
      padding: 10px 20px;
      color: white;
      border-radius: $button-border-radius;
      background-color: #286eff;
      font-size: 10px;
      box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
      transition-duration: 0.4s;

      @media screen and (max-width: 480px) {
        padding: 10px;
      }
    
      &:hover {
        cursor: pointer;
        background-color: $button-hover-background-color;
      }
    }

    .preview-image {
      position: relative;

      .previewImage {
        width: 50px;
        height: 50px;
        object-fit: cover;
        object-position: center;
      }

      button {
        position: absolute;
        top: 0;
        right: 0;
        background-color: #d24141;
        border-radius: 50%;
        padding: 5px;
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
