$primary-color: #286eff;
$secondary-color: #033391;


.Appbar {
  position: fixed;
  width: calc(100vw - 78px);
  z-index: 3;
  top: 0;

  // mobile and tablet
  @media screen and (max-width: 768px) {
    width: 100%;
    display: none;
  }

  @media screen and (max-width: 480px){
    width: 100%;
    display: none;
  }

  .news-text {
    width: 100%;
    background-color: #276dfd;
    color: white;
    .scrolling-text {
      width: 100%;
      padding: 2px 0px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      font-size: 14px;
      font-weight: 600;
      white-space: nowrap;
      // animation: scroll-text 30s linear infinite;
      @media screen and (max-width: 768px){
        animation: scroll-text 30s linear infinite;
        display: none;
        
      }

      @media screen and (max-width: 480px){
        animation: scroll-text 30s linear infinite;
        display: none;
        
      }

      i {
        // mix  radial gradiend red  fire yellow and more color text color
        background: linear-gradient(90deg, #958903, #ffa500, #ffff00);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 14px;
        font-weight: 600;
      }

      button {
        background-color: #fff;
        color: #276dfd;
        border: #00000000 1px solid;
        padding: 2px 10px;
        border-radius: 5px;
        &:hover {
          background-color: #276dfd;
          color: white;
          border: #ffffff5a solid 1px;
          cursor: pointer;
        }
      }
    }

    @keyframes scroll-text {
      0% {
        transform: translateX(100%);
      }
      100% {
        transform: translateX(-100%);
      }
    }
  }

  .Navbar {
    background-color: #fff;
    border-bottom: #0707071b 1px solid;
    padding: 0 3%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;

    @media screen and (max-width: 768px) {
      padding: 0 2%;
      flex-direction: column;
    }

    @media screen and (max-width: 480px) {
      padding: 0 2%;
      flex-direction: column;
      
    }

    ul {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 0px;
      margin: 0px;
      list-style: none;

     

      li {
        display: inline;
        padding: 15px 10px;
        border-right: #07070713 1px solid;
        text-transform: uppercase;

        @media screen and (max-width: 768px) {
          padding: 10px 5px;
          
        }

        @media screen and (max-width: 480px){
          padding: 10px 5px;
          
        }

        &:hover {
          background: linear-gradient(
            45deg,
            $secondary-color,
            $primary-color,
            $secondary-color
          );

          background-size: 600% 600%;
          animation: gradientAnimation 2s linear infinite;
          cursor: pointer;
          

          a {
            color: #fff;
          }
        }

        &:last-child {
          border-right: none;
        }

        a {
          text-decoration: none;
          color: #464646;
          font-size: 16px;
          font-weight: 600;

          @media screen and (max-width: 768px) {
            font-size: 13px;
            
          }

          &:hover {
            color: #fff;
          }
        }
      }
    }
    .university-filter-select {
      display: flex;
      align-items: center;
      gap: 10px;
      select {
        padding: 2px 10px;
        border: none;
        border-radius: 0px;
        background-color: #fff;
        color: #242424;
        font-size: 14px;
        cursor: pointer;
        &:hover {
          border-bottom: #07070731 1px solid;
          cursor: pointer;
        }
      }
      p {
        color: #515151;
        font-family: "Poppins", sans-serif;
      }
    }
    .user-and-notification {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 20px;

      @keyframes shine {
        0% {
          transform: translateX(-30px) rotate(-25deg);
        }

        100% {
          transform: translateX(250px) rotate(-25deg);
        }
      }


      .shine {
        background: $primary-color;
        color: #ffffff;
        padding: 10px 15px;
        font-size: 16px;
        border-radius: 0px;
        border: none;
        position: relative;
        overflow: hidden;
        transition: all 100ms linear;
        border-radius: 5px;
        box-shadow: 0px 10px 10px #2870ff61;

        @media screen and (max-width: 768px) {
          padding: 5px 10px;
          font-size: 14px;
          
        }

        &:hover {
            // transform: scale(1.05) rotate(-2.5deg);
            cursor: pointer;
          }
      }


      .shine:hover::after {
        content: "";
        display: block;
        width: 75px;
        height: 175%;
        background: rgb(255, 255, 255);
        background: linear-gradient(
          90deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 1) 25%,
          rgba(255, 255, 255, 1) 50%,
          rgba(255, 255, 255, 1) 75%,
          rgba(255, 255, 255, 0) 100%
        );
        opacity: 0.5;
        position: absolute;
        top: -20px;
        left: 0;
        animation: shine  0.6s linear;
        transform: translateX(250px) rotate(-25deg);
      }

      
    }
  }
}
.mobile-title{
  background-color: #286eff;
  width: calc(100vw - 40px);
  display: none;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  color: #fff;

  i{
    color: #fff;
    font-size: 20px;
    &:hover{
      cursor: pointer;
    }
  }

  @media screen and (max-width: 768px){
    display: flex;
  }

  @media screen and (max-width: 480px){
    display: flex;
  }

}

.Appbar-mobile{
  display: none;
  flex-direction: column;
  align-items: center;
  height: 95vh;
  width: 60%;
  position: fixed;
  top: 0;
  z-index: 30;
  @media screen and (max-width: 768px){
    display: none;
  }

  @media screen and (max-width: 480px){
    display: none;
  }

  .Navbar{
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: baseline;
    width: 100%;
    height: 100%;
    background-color: #f6f9ff;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
    padding: 0;
    margin: 0;
    animation: sliderfromlefttpright 0.3s ease-in-out;


    @keyframes sliderfromlefttpright {
      0% {
        transform: translateX(-100%);
      }
      100% {
        transform: translateX(0%);
      }
      
    }

    .close{
      display: flex;
      align-items: center;
      justify-content: center;
      width: calc(100% - 20px);
      padding: 10px;
      background: linear-gradient(
        45deg,
        $secondary-color,
        $primary-color,
        $secondary-color
      );

      background-size: 600% 600%;
      animation: gradientAnimation 2s linear infinite;
      cursor: pointer;

      i{
        color: #fff;
        font-size: 20px;
      }
    }

    ul{
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: flex-start;
      padding: 0;
      margin: 10% 0 0 0;
      list-style: none;
      width: calc(100% - 20px);
      gap: 10px;

      li{
        padding: 15px 10px;
        border-bottom: #07070713 1px solid;
        text-transform: uppercase;
        width: 100%;

        &:hover{
          background: linear-gradient(
            45deg,
            $secondary-color,
            $primary-color,
            $secondary-color
          );

          background-size: 600% 600%;
          animation: gradientAnimation 2s linear infinite;
          cursor: pointer;

          a{
            color: #fff;
          }
        }

        a{
          text-decoration: none;
          color: #464646;
          font-size: 16px;
          font-weight: 600;

          &:hover{
            color: #fff;
          }
        }
      }
    }
    .user-and-notification{
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;
      width: calc(100% - 20px);
      margin-top: 5%;

      .shine{
        background: $primary-color;
        color: #ffffff;
        padding: 10px 15px;
        font-size: 16px;
        border-radius: 0px;
        border: none;
        position: relative;
        overflow: hidden;
        transition: all 100ms linear;
        border-radius: 5px;
        box-shadow: 0px 10px 10px #2870ff61;

        &:hover{
          cursor: pointer;
        }
      }

      .shine:hover::after{
        content: "";
        display: block;
        width: 75px;
        height: 175%;
        background: rgb(255, 255, 255);
        background: linear-gradient(
          90deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 1) 25%,
          rgba(255, 255, 255, 1) 50%,
          rgba(255, 255, 255, 1) 75%,
          rgba(255, 255, 255, 0) 100%
        );
        opacity: 0.5;
        position: absolute;
        top: -20px;
        left: 0;
        animation: shine  0.6s linear;
        transform: translateX(250px) rotate(-25deg);
      }
    }
    .university-filter-select{
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 10px;
      margin-top: 10%;
      select{
        padding: 2px 10px;
        border: none;
        border-radius: 0px;
        background-color: #fff;
        color: #242424;
        font-size: 14px;
        cursor: pointer;
        &:hover{
          border-bottom: #07070731 1px solid;
          cursor: pointer;
        }
      }
      p{
        color: #515151;
        font-family: "Poppins", sans-serif;
      }
    }
  }

}
