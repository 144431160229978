.setConfession {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.651);
  height: 100vh;
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: slideFromTop 0.5s ease-in-out forwards;

  .inner {
    .alert {
      font-size: 12px;
      color: #fd2b4e;
      padding: 10px 6px;
    }
    .close {
      position: absolute;
      top: 10px;
      right: 10px;
      font-size: 23px !important;
      color: #fff;
      background-color: transparent !important;
      cursor: pointer;
      border: none;
      width: 50px;
      background-image: none !important;
      &:hover {
        color: #fa5974;
        background-color: transparent;
      }
    }
    p {
      font-size: 20px;
      font-weight: 600;
      color: #000;
      margin: 4px 10px;
      background: linear-gradient(45deg, #fa5974, #fa4362);
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent;
    }
    width: 500px;
    @media screen and (max-width: 480px) {
      width: 370px;
    }
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 10px;
    border-radius: 4px;
    border-top: #fa5974 40px solid;

    .border {
      border-bottom: 1px solid #1515151d;
    }
    .form-inputs {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px;

      p {
        color: #262626;
        font-size: 12px;
        font-weight: 400;
      }
      label {
        padding: 0px 4px;
        &:hover {
          cursor: pointer;
        }
      }

      input[type="checkbox"] {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: #fa4362;
        &:hover {
          cursor: pointer;
          animation: changeBackgroundColor 0.3s ease-in-out forwards;
        }
      }
      textarea {
        width: calc(100% - 20px);
        height: 170px;
        resize: none;
        margin: 10px;
        font-size: 12px;
        &:focus {
          animation: scaleOnHover 0.3s ease-in-out forwards;
        }
      }
    }

    button {
      width: 60%;
      height: 40px;
      border-radius: 4px;
      border: none;
      background-color: #fa5974;
      font-size: 12px;
      font-weight: 600;
      color: #fff;
      padding: 4px 10px;
      text-transform: uppercase;
      cursor: pointer;
      &:hover {
        background-color: #fd2b4e;
        animation: scaleOnHover 0.3s ease-in-out forwards,
          changeBackgroundColor 0.3s ease-in-out forwards;
        color: #fff;
      }
    }
  }
}
@keyframes popIn {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes slideFromTop {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0%);
  }
}

@keyframes scaleOnHover {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.05);
  }
}

@keyframes changeBackgroundColor {
  from {
    background-color: #fa5974;
  }
  to {
    background-color: #fd2b4e;
  }
}
