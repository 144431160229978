.UserSettings{
    background-color: #F8F9FE;
    height: 100vh;

    .controls{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;

        @media screen and (max-width: 480px) {
            flex-direction: column;
            height: calc(100vh - 100px);
            overflow-y: scroll;
            
        }
        @media screen and (max-width: 768px) {
            flex-direction: column;
          }

    }

}