.DatingProfile_view {
  width: 70%;
  height: calc(100vh);

  @media screen and (max-width: 768px) {
    height: calc(100vh - 40px);
    width: 100%;
  }

  @media screen and (max-width: 480px) {
    width: 100%;
    height: calc(100vh - 40px);
  }

  .Dcontainer {
    margin: 15px 20px;
    background-color: #f5e7f7;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 12px;

    @media screen and (max-width: 768px) {
      flex-direction: column;
      margin: 0;
    }


    .profile-images {
      width: 60%;
      height: calc(100vh - 30px);
      position: relative;

      @media screen and (max-width: 768px) {
        width: 100%;
        height: 60%;
      }

      .bigControls {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: absolute;
        width: 100%;
        top: 0%;
        .left,
        .right {
          width: 30%;
          height: 90vh;
          z-index: 100;

          @media screen and (max-width: 768px) {
            height: 75vh;
            width: 20%;
          }

          &:hover {
            background: rgba(248, 206, 242, 0.055);
            backdrop-filter: blur(4px);
            -webkit-backdrop-filter: blur(4px);
            cursor: pointer;
            border-radius: 12px;

            @media screen and (max-width: 768px) {
              border-radius: 0px;
              opacity: 0;
            }

            @media screen and (max-width: 480px) {
              border-radius: 0px;
              opacity: 0;
            }
          }
        }
      }

      img {
        width: 100%;
        height: calc(100vh - 30px);
        border-radius: 12px 0 0 12px;
        object-fit: cover;
        pointer-events: none;
        display: none;

        @media screen and (max-width: 768px) {
          border-radius: 0px;
          height: calc(80vh - 30px);
        }

        &.active {
          display: block;
        }
      }
      .image-counter {
        position: absolute;
        bottom: 3%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        .counter {
          width: 76px;
          height: 6px;
          border-radius: 22px;
          background-color: #cccccca8;
          &:hover {
            cursor: pointer;
          }
          &.active {
            background: linear-gradient(75deg, #eb3fb3, #e125cc, #c718e7);
          }
        }
      }
    }

    .profile-details {
      width: 40%;
      height: calc(100vh - 30px);
      position: relative;

      @media screen and (max-width: 768px) {
        width: 100%;
        height: 40%;
      }

      .container {
        padding: 20px;
        display: flex;
        flex-direction: column;
        box-shadow: none;
        text-align: center;
        padding-top: 10%;
        h2 {
          font-size: 24px;
          margin-bottom: 10px;
          color: #242424;
          i {
            color: #47a8ec;
          }
        }
        .branch {
          font-size: 12px;
          background: linear-gradient(75deg, #eb3fb3, #e125cc, #c718e7);
          -webkit-background-clip: text;
          background-clip: text;
          color: transparent;
          font-weight: 600;
          margin-bottom: 10px;
        }
        p {
          font-size: 13px;
          color: #424242;
          margin-bottom: 10px;
          line-height: 1.4rem;
        }
        .about {
          margin-top: 20px;
          text-align: start;
        }
      }

      .profile-actions {
        display: flex;
        justify-content: center;
        gap: 20px;
        align-items: center;
        position: absolute;
        bottom: 4%;
        width: 100%;

        @media screen and (max-width: 768px) {
          position: relative;
          bottom: 0;
          margin: 20px 0;
          margin-bottom: 50px;
        }

        @media screen and (max-width: 480px) {
          position: relative;
          bottom: 0;
          margin: 20px 0;
          margin-bottom: 50px;
        }

        button {
          border: none;
          background-color: #fff;
          color: #286eff;
          height: 50px;
          width: 50px;
          border-radius: 50%;
          box-shadow: #959da533 0px 8px 24px;
          color: #fff;
          font-size: 22px;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: box-shadow 0.3s ease;

          &:hover {
            box-shadow: #959da533 0px 8px 24px,
              rgba(0, 0, 0, 0.2) 0px 6px 10px -5px,
              rgba(0, 0, 0, 0.2) 0px 0px 30px;
          }

          &.like {
            background: linear-gradient(75deg, #eb3fb3, #e125cc, #c718e7);
            box-shadow: 0 8px 10px rgba(235, 63, 178, 0.37),
              0 6px 10px rgba(225, 37, 203, 0.134),
              0 0 30px rgba(200, 24, 231, 0.167);
            animation: changeDegree 1s ease infinite;
            height: 55px;
            width: 55px;
            i {
              animation: pulseAnimation 1.6s ease infinite;
            }
          }

          &.skip {
            background: linear-gradient(75deg, #eb3fb3, #e125cc, #c718e7);
            -webkit-background-clip: text;
            background-clip: text;
            color: transparent;
            box-shadow: 0 2px 10px rgba(235, 63, 178, 0.152),
              0 2px 10px rgba(225, 37, 203, 0.134),
              0 0 30px rgba(200, 24, 231, 0.167);
            height: 44px;
            width: 44px;
          }

          &.star {
            background: linear-gradient(75deg, #e3c136, #f6de67, #b1a007);
            -webkit-background-clip: text;
            background-clip: text;
            color: transparent;
            height: 44px;
            width: 44px;
            box-shadow: 0 2px 10px rgba(235, 227, 63, 0.152),
              0 2px 10px rgba(222, 225, 37, 0.178),
              0 0 30px rgba(231, 203, 24, 0.167);
          }

          &:hover {
            cursor: pointer;
          }
        }
      }

      .report-profile {
        color: #424242;
        font-size: 13px;
        font-weight: 600;
        text-align: center;
        padding-bottom: 10px;
        display: flex;
        justify-content: center;
        align-items: center;

        span {
          transition: color 0.2s ease;
          width: fit-content;
          &:hover {
            cursor: pointer;
            color: #eb3fb3;
          }
        }

        @media screen and (max-width: 768px) {
          padding-bottom: 40;
        }

        @media screen and (max-width: 480px) {
          padding-bottom: 40;
        }
      }
    }
  }
}

@keyframes blurAnimation {
  0% {
    filter: grayscale(100%);
    filter: blur(2px);
  }
  50% {
    filter: grayscale(50%);
    filter: blur(1px);
  }
  100% {
    filter: grayscale(0%);
    filter: blur(0);
  }
}

.active {
  animation: blurAnimation 0.3s ease-in-out forwards;
}

@keyframes changeBagroundOnHover {
  0% {
    background: linear-gradient(75deg, #eb3fb3, #e125cc, #c718e7);
  }
  50% {
    background: linear-gradient(75deg, #e3c136, #f6de67, #b1a007);
  }
  100% {
    background: linear-gradient(75deg, #eb3fb3, #e125cc, #c718e7);
  }
}

@keyframes changeDegree {
  0% {
    background: linear-gradient(75deg, #eb3fb3, #e125cc, #c718e7);
  }
  25% {
    background: linear-gradient(90deg, #eb3fb3, #e125cc, #c718e7);
  }
  50% {
    background: linear-gradient(105deg, #eb3fb3, #e125cc, #c718e7);
  }
  75% {
    background: linear-gradient(120deg, #eb3fb3, #e125cc, #c718e7);
  }
  100% {
    background: linear-gradient(135deg, #eb3fb3, #e125cc, #c718e7);
  }
}
