// Variables (if needed)
$COLOR_PRIMARY_DARK: var(--COLOR_PRIMARY_DARK);

// Main styles
.progress__bar__component {
  z-index: 30;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  background-color: transparent;
  color: $COLOR_PRIMARY_DARK;

  // Media query breakpoints
  @media (max-width: 700px) {
    font-size: 0.8rem;
  }
}
