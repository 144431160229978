.DatingPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: linear-gradient(45deg,#f3f6ff, #f3f6ffcd ,#f3f6ff7f), url('../img/datingbg2.jpeg');
  // background-image: url("");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  @media (max-width: 480px) {
    margin-top: 20px;
  }
  @media (max-width: 720px) {
    margin-top: 20px;
  }

  .buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 10px;
    margin-bottom: 10px;
    flex-wrap: wrap;
    display: none;
    @media (max-width: 480px) {
      display: flex;
    }
    @media (max-width: 720px) {
      display: flex;
    }

    button {
      width: 120px;
      padding: 10px;
      border-radius: 8px;
      border: none;
      // background: linear-gradient(75deg, #f153bd, #e272d5, #d116f1);
      color: #fff;
      font-weight: 600;
      box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
      cursor: pointer;
      transition: all 0.3s ease;
      &:hover {
        transform: scale(1.05);
      }
    }
  }

  @media (max-width: 480px) {
    height: calc(100vh - 80px);
  }
  .main-continer {
    height: 90%;
    width: 70%;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -50px, rgba(0, 0, 0, 0.3) 0px 60px 60px -30px;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    @media (max-width: 480px) {
      flex-direction: column;
      width: 97%;
      height: calc(80vh);
    }

    .left {
      width: 30%;
      background-color: #f5e7f7;
      background-image: url("../img/dating-sidebar.png");
      background-size: 200px;
      background-position: bottom left;
      background-repeat: no-repeat;
      border-radius: 8px 0 0 8px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      @media (max-width: 480px) {
        display: none;
      }
      @media (max-width: 720px) {
        display: none;
      }

      .container {
        position: absolute;
        top: 2%;

        .img {
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 160px;
            height: 160px;
            border-radius: 8px;
            object-fit: cover;
            object-position: center;
            box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
            border: 4px #fff solid;
          }
        }
      }
      .user-details {
        padding: 20px 12px;
        text-align: center;
        h2 {
          background: linear-gradient(75deg, #eb3fb3, #e272d5, #b305d2);
          -webkit-background-clip: text;
          background-clip: text;
          color: transparent;
        }
        p {
          font-size: 1.2rem;
          font-weight: 600;
          color: #494949;
        }
        .setdating {
          font-size: 12px;
        }
      }

      .button {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 10px;
        margin-top: 10%;

        button {
          width: 100%;
          padding: 10px;
          border-radius: 8px;
          border: none;
          // background: linear-gradient(75deg, #f153bd, #e272d5, #d116f1);
          color: #fff;
          font-weight: 600;
          box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
          cursor: pointer;
          transition: all 0.3s ease;
          &:hover {
            transform: scale(1.05);
          }
        }
      }

      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    }

    .right {
      width: 70%;
      padding: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px;
      background-image: url("../img/dating_bg.png");
      background-size: 200px;
      border-radius: 8px;

      img,
      .d-card {
        pointer-events: none;
      }
      .matches {
        width: 100%;
        height: calc(100vh - 100px);
        overflow: scroll;
        h2 {
          background: linear-gradient(75deg, #eb3fb3, #e272d5, #77078a);
          -webkit-background-clip: text;
          background-clip: text;
          color: transparent;
          padding: 10px;
          text-align: center;
        }

        .matched-data {
          // background-color: red;
          width: 100%;
          display: flex;
          gap: 20px;
          flex-wrap: wrap;
          justify-content: start;
          .matched-card {
            background: linear-gradient(75deg, #f58fd3, #f482e6, #d11bf1);
            padding: 5px 8px;
            border-radius: 8px;
            box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
            display: flex;
            gap: 10px;
            width: calc(50% - 30px);
            @media (max-width: 480px) {
              width: calc(100%);
            }
            @media (max-width: 720px) {
              width: calc(100%);
            }
            .top {
              text-align: center;
              img {
                width: 80px;
                height: 80px;
                object-fit: cover;
                object-position: center;
                border-radius: 4px;
              }
            }
            .center-data {
              text-align: center;
              display: flex;
              flex-direction: column;
              align-items: start;
              padding: 10px;
              justify-content: center;
              width: 80%;
              h3 {
                color: #fff;
              }
              p {
                color: #ececec;
              }
            }
            .chat-icon {
              text-align: center;
              display: flex;
              align-items: center;
              justify-content: center;
              i {
                background-color: #fff;
                padding: 10px;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                color: #494949;
                box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
                &:hover {
                  background-color: #f58fd3;
                  cursor: pointer;
                }
              }
            }
          }
        }
      }

      .Stats {
        width: 100%;
        height: calc(90vh - 100px);
        overflow: scroll;
        background-color: #F3F6FF;
        background-image: url("../img/stats_bg.png");
        background-size: 80%;
        background-position: bottom center;
        background-repeat: no-repeat;
        h2 {
          background: linear-gradient(75deg, #eb3fb3, #e272d5, #77078a);
          -webkit-background-clip: text;
          background-clip: text;
          color: transparent;
          padding: 10px;
          text-align: center;

        }

        .stats-data {
          display: flex;
          gap: 10px;
          justify-content: space-between;
          flex-wrap: wrap;
          padding: 10px;
          .stats-card {
            background: linear-gradient(30deg, #f469c5, #f093e5, #9f10b8);
            width: 150px;
            height: 80px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            border-radius: 8px;
            font-weight: 600;
            color: #fff;
            box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
            background-size: 600% 600%;
            animation: gradientAnimation 4s linear infinite,
              popAnimation 0.3s ease;
              p{
                font-size: 24px;
                font-weight: 600;
              }
          }
        }
      }

      @media (max-width: 480px) {
        width: 100%;
        padding: 0;
        order: 2;
      }
    }
  }
}
