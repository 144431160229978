.UMusic {
  display: flex;

  margin: 10% 0%;
  position: relative;

  @media screen and (max-width: 480px) {
    display: none;
  }

  @media screen and (max-width: 768px) {
    display: none;
    
  }


  iframe {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    width: 430px;
    height: 250px;
    object-fit: cover;
    border-radius: 10px;
  }

  .blurbg {
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.12);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(2.3px);
    -webkit-backdrop-filter: blur(2.3px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    position: absolute;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    
    .controlls{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 20px;
        i{
            font-size: 6rem;
            color: #fff;
            margin: 0 10px;
            cursor: pointer;
        }
        .url{
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 3px;
            background-color: #00000090;
            border-radius: 4px;
           

            i{
                font-size: 32px;
                cursor: none;
            }

            input{
                width: 300px;
                padding: 4px 10px;
                background-color: transparent;
                border: none;
                color: #fff;
            }
        }
        
    }

  }
}
