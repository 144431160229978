.Manage-Support {
  display: flex;
  flex-direction: row;
  //   background: linear-gradient(320deg, #f27121, #e94057, #8a2387);

  background-image: url("../../img/managesupport.png");
  background-size: cover;
  background-position: bottom;

  .support-sidebar {
    width: 25%;
    // background-image: url("https://cdn.pixabay.com/photo/2021/10/18/18/07/tree-6721755_1280.jpg");

    background-size: cover;
    background-position: center left;
    background-position: right;

    background: #ffffff1f;
    background: linear-gradient(320deg, #ffffff1f, #ffffff1f, #8a2387);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10.8px);
    -webkit-backdrop-filter: blur(10.8px);
    border-right: 1px solid rgba(255, 255, 255, 0.3);
    .log-user {
      background-color: #286eff;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      padding: 10px;
      display: flex;
      gap: 10px;
      justify-content: start;
      align-items: center;
      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: 2px solid #fff;
      }

      h3 {
        text-transform: uppercase;
        color: #fff;
      }
      p {
        color: #fff;
        font-size: 13px;
      }
    }

    .problems {
      height: calc(100vh - 71px);
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: 4px 2px;

      .support-sidebar-card {
        padding: 15px 20px;
        border-bottom: 1px solid #e5e5e5;
        cursor: pointer;
        position: relative;
        background: rgba(255, 255, 255, 0.18);
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(20px);
        -webkit-backdrop-filter: blur(20px);
        border: 1px solid rgba(255, 255, 255, 0.3);
        border-radius: 8px;
        line-height: 1.5rem;
        .title {
          font-size: 15px;
          color: #3a3a3a;
        }
        .date {
          font-size: 12px;
          font-weight: 600;
        }

        &:hover {
          background-color: #f5f5f5;
        }

        &.selected {
          background: rgba(4, 209, 236, 0.632);
          box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
          backdrop-filter: blur(20px);
          -webkit-backdrop-filter: blur(20px);
          border: 1px solid rgba(255, 255, 255, 0.3);
          color: #fff;
          h3 {
            color: #333333;
          }
          .date {
            color: #a10303;
          }
        }

        .resolved {
          position: absolute;
          color: #fff;
          transform: rotate(90deg);
          font-size: 8px;
          font-weight: 600;
          right: -1%;
          top: 36%;
          background-color: green;
          padding: 4px 8px;
          border-radius: 4px;
          line-height: normal;
        }
        .notResolved {
          position: absolute;
          color: #fff;
          transform: rotate(90deg);
          font-size: 8px;
          font-weight: 600;
          right: -1%;
          top: 36%;
          background-color: red;
          padding: 4px 8px;
          border-radius: 4px;
          line-height: normal;
        }
      }
    }
  }

  .support-content {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .support-content-card {
      /* From https://css.glass */
      background: rgba(255, 255, 255, 0.11);
      box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
      backdrop-filter: blur(6.2px);
      -webkit-backdrop-filter: blur(6.2px);
      border: 1px solid rgba(255, 255, 255, 0.3);
      margin: 20px 60px;
      width: 92%;
      padding: 10px 20px;
      border-radius: 16px;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      height: 90vh;
      position: relative;
      background-size: 300px;
      background-position: bottom right;
      background-repeat: no-repeat;
      .title {
        font-size: 20px;
        font-weight: 600;
        color: #3a3a3a;
        text-align: center;
        text-transform: uppercase;
        margin: 8px 0px;
        padding: 15px;
        background-color: #286eff;
        color: #fff;
        border-radius: 6px;

        /*

            background: rgba(40, 110, 255, 0.34);
            border-radius: 16px;
            box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
            backdrop-filter: blur(5px);
            -webkit-backdrop-filter: blur(5px);
            border: 1px solid rgba(40, 110, 255, 0.3);



            */
      }
      .details {
        padding: 20px 0px;
        border-bottom: #3a3a3a31 1px solid;
        line-height: 1.6rem;

        i {
          font-size: 22px;
          color: #3a3a3a;
          margin-right: 10px;
          padding: 4px 0px;
          &:nth-child(1) {
            font-size: 17px;
          }
        }

        h2 {
          font-size: 22px;
        }
        .email {
          font-size: 16px;
          font-weight: 600;
          color: #3a3a3a;
        }
        .date {
          font-size: 14px;
          font-weight: 600;
          color: #3a3a3a;
        }
        .status {
          font-size: 12px;
          font-weight: 600;
          &.resolved {
            color: green;
          }
          &.notResolved {
            color: red;
          }
        }
      }
      .description {
        z-index: 1;
        padding: 20px 0px;
        h3 {
          padding: 10px 0px;
        }
        p {
          font-size: 14px;
          line-height: 1.6rem;
          color: #3a3a3a;
        }
      }
      .compose {
        display: flex;
        flex-direction: column;
        gap: 10px;
        position: absolute;
        bottom: 0px;
        width: calc(100% - 80px);
        padding: 10px 20px;
        display: none;
        background: rgba(255, 255, 255, 0.64);
        backdrop-filter: blur(2.1px);
        -webkit-backdrop-filter: blur(2.1px);
        border-radius: 16px;
        margin-bottom: 8px;
        z-index: 10;

        .action {
          display: flex;
          flex-direction: row;
          gap: 10px;
          font-size: 14px;
          align-items: center;
          .closebtn {
            width: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 11px;
          }
        }

        input {
          width: calc(100% - 40px);
          padding: 10px 20px;
          border-radius: 4px;
          border: 1px #2870ffb9 solid;
          background: rgba(255, 255, 255, 0.64);
          backdrop-filter: blur(5.1px);
          -webkit-backdrop-filter: blur(5.1px);
        }
        textarea {
          padding: 10px 20px;
          resize: none;
          height: 100px;
          border-radius: 4px;
          border: 1px #2870ffb9 solid;
          width: calc(100% - 40px);
          background: rgba(255, 255, 255, 0.64);
          backdrop-filter: blur(5.1px);
          -webkit-backdrop-filter: blur(5.1px);
        }
        button {
          padding: 10px 20px;
          background-color: #286eff;
          color: #fff;
          border: none;
          border-radius: 6px;
          font-size: 16px;
          font-weight: 600;
          cursor: pointer;
          &:hover {
            background-color: #1e4dd8;
          }
        }
      }
      .compose-button {
        position: absolute;
        bottom: 2%;
        left: 0px;
        width: 100%;
        padding: 10px;
        text-align: center;

        button {
          padding: 10px 20px;
          background-color: #286eff;
          color: #fff;
          border: none;
          border-radius: 6px;
          font-size: 16px;
          font-weight: 600;
          cursor: pointer;
          &:hover {
            background-color: #1e4dd8;
          }
        }
      }
    }
  }
}
