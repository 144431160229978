$search-form-padding: 20px 10px;
$search-input-padding: 12px 20px;
$search-input-width: calc(100% - 60px);
$primary-color: #F3F6FF;
$error-color: #EB2562;

.search {
  width: 100%;
  padding: $search-form-padding;
  .Searching{
    color: #286EFF;
    padding: 8px 10px;
    font-size: 11px;
    height: 4px;
  }

  .searchForm {
    text-align: center;
    width: calc(100% - 20px);

    input {
      width: $search-input-width;
      background-color: $primary-color;
      border: none;
      padding: $search-input-padding;
      border-radius: 8px;
      box-shadow: rgba(0, 0, 0, 0.06) 0px 1px 4px 0px inset;
      transition: 0.6s ease-in-out; // Add smooth transition for box-shadow
      
      &:focus {
        outline: none;
        box-shadow: rgba(0, 0, 0, 0.06) 0px 3px 4px 0px inset;
      }
    }

    .userNotFound {
      text-align: center;
      color: $error-color;
      opacity: 1;
      transform: translateY(0);
      transition: opacity 0.3s ease-out, transform 0.3s ease-out;

      &.hidden {
        opacity: 0;
        transform: translateY(-5px);
      }
    }
  }

  .userChat {
    width: calc(100% - 40px);
    margin-top: 6px;
    background-color: #eef1f8;
    border-left: #286EFF 4px solid !important;
    .userChatInfo {
      color: #424242;
      
      .joineddate{
        font-size: 12px;
        color: #286EFF;
      }
    }
    .alluser{
      display: block;
    }


    &.hidden {
      opacity: 0;
      transform: translateY(-10px);
    }
  }
  .Messages{
    display: flex;
    align-items: center;
    justify-content: space-between;
    h2 {
      padding: 15px 8px 0px 8px;
      color: #242424;
    }
  }
 

  // Appear animation for .userChat elements
  .userChat {
    animation: appear 0.5s ease-in-out;
  }

  @keyframes appear {
    0% {
      opacity: 0;
      transform: translateY(-10px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  // More animations can be added here...
}
