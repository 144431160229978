.Dating_new {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f6e4f9;
  background-image: url("../../img/dating_bg.png");
  background-size: 100px;
  background-repeat: repeat;
  flex-direction: row-reverse;
  height: 100vh;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    height: calc(100vh - 40px);
    overflow: auto;
  }

  @media screen and (max-width: 480px) {
    flex-direction: column;
    background-size: 400px;
    
  }
}
