/* Main styles */
.exroom__page {
  background-image: url("../../assets/exRoom3.png");
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .exroom__contents {
    display: block;
    gap: 20px;
    text-align: center;
    background-color: #ffffff86;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 0.5rem;
    padding: 1rem 2rem;
    min-width: 50%;
    max-width: 50%;


    .exroom__heading {
      color: #424242;
    }
    .exroom__form {
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      gap: 20px;

      @media (max-width: 480px) {
        display: flex;
        flex-direction: column;
      }

      @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
      }

      input {
        background-color: #f5ede8;
        border-radius: 0.2rem;
        border: none;
        outline: none;
        padding: 0.5rem 1rem;
        animation: pop 0.5s ease-in-out;
      }
      .buttons {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        .join__room__btn {
          padding: 0.5rem 1rem;
          background-color: #3b51ed;
          color: #fff;
          outline: none;
          border: none;
          border-radius: 0.25rem;
          transition-duration: 0.3s;

          &:focus,
          &:hover {
            background-color: #1a2cb3;
            cursor: pointer;
          }

          /* Animation for the button */
          animation: pop 0.5s ease-in-out;
        }
      }
    }
  }
  .input__error {
    font-size: 14px;
    font-weight: 600;
  }
  hr {
    color: #62626235;
    margin: 20px 0px;
  }

  .moreRoom {
    h2 {
      color: #424242;
    }

    .public-rooms {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      gap: 20px;
      margin: 10px 0px;
      max-height: 400px;
      overflow: scroll;
      padding: 20px 10px;

      @media (max-width: 480px) {
        max-height: 200px;
      }

      .room-card {
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        padding: 10px;
        width: 25%;
        border-radius: 6px;
        gap: 30px;

        @media (max-width: 480px) {
          width: 100%;
        }

        @media (max-width: 768px) {
          width: 50%;
        }
        
        background-color: #f9e9c4;
        &:nth-child(odd) {
          background-color: #99c4a4;
        }

        /* Animation for the room card */
        animation: pop 0.5s ease-in-out;
      }
    }
  }
}

/* Keyframes for animations */
@keyframes slideIn {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideUp {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes pop {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
