.SNavbar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    flex-direction: row;
   

    .search{
        display: flex;
        align-items: center;
        flex-direction: row;
        gap: 20px;
        margin-left: 5%;

        @media screen and (max-width: 480px) {
            display: none;
        }

        @media screen and (max-width: 768px) {
            display: none;
          }

        .inner{
            display: flex;
            align-items: center;
            flex-direction: row;
            gap: 10px;
            border: 1px solid #455dc6d4;
            border-radius: 4px;
            padding: 5px 10px;
            width: 35%;
        }
       

        input{
            font-size: 16px;
            font-weight: 600;
            color: #242424;
            padding: 6px 10px;
            width: 100%;
            border: none;  
        }

    }


    .nav{
        display: flex;
        align-items: center;
        flex-direction: row;
        gap: 10px;

        @media screen and (max-width: 480px) {
            display: none;
        }

        @media screen and (max-width: 768px) {
            display: none;
          }


        i{
            width: 40px;
            height: 40px;
            background-color: #f1f1f1;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            border: 1px solid #24242450;
            &:hover{
                cursor: pointer;
            }
        }

        .message{
            position: relative;
            display: flex;
            align-items: center;
            flex-direction: row;
            gap:0;
            
            .count{
                position: relative;
                top: -40%;
                right: -80%;
                background-color: red;
                color: #fff;
                font-weight: 600;
                border-radius: 50%;
                padding: 6px;
                width: 8px;
                height: 8px;
                font-size: 14px;
                display: flex;
                align-items: center;
            }
        }

        .user{

            display: flex;
            align-items: center;
            flex-direction: row;
            margin-right: 20px;
            gap: 10px;

            i{
                background-color: transparent;
                color: rgb(3, 164, 3);
                border: none;
            }

            p{
                width: 200px;
                font-size: 24px;
                font-weight: 700;
            }
        }

    }

    .mobile-menu{
        display: none;
        background-color: #fff;
        padding: 20px 5%;
        width: 100%;
        @media screen and (max-width: 480px) {
            display: flex;
            align-items: center;
            flex-direction: row;
            justify-content: space-between;
            gap: 10px;
            margin-right: 5%;
        }

        @media screen and (max-width: 768px) {
            display: flex;
            align-items: center;
            flex-direction: row;
            justify-content: space-between;
            gap: 10px;
            margin-right: 5%;
        }

    }

}