// Variables
$COLOR_DARK: var(--COLOR_DARK);
$COLOR_LIGHT: var(--COLOR_LIGHT);

// Main styles
.file__content {
  padding: 0.75rem 1rem;
  word-wrap: break-word;
  white-space: pre-wrap;
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 0.75rem;
  width: 50%;

  &.other {
    justify-content: start;
  }

  .documents-file {
    background: #0044d0;
    box-shadow: 8px 8px 16px #0b0b0b, -8px -8px 16px #212121;
    padding: 6px 10px;
    margin-right: 8%;
    font-size: 14px;
    border-radius: 8px;
    &.other {
      margin-left: 8%;
      background: #161616;
      color: #fff;
    }

    .FileName {
      // border: 1px solid #f0f0f0;
      border-radius: 6px;
      padding: 8px 12px;
      color: #242424;
      box-shadow: rgba(0, 0, 0, 0.168) 0px 1px 2px 0px;
      background: linear-gradient(to right, #d3ffec, #befffd, #b2e8ff);
      animation: gradientAnimation 4s linear infinite;
      background-size: 600% 600%;
    }
  }

  .download__icon__wrapper {
    padding: 0.75rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      cursor: pointer;
    }

    &.other {
      color: #fff;
    }

    &.you {
      // background-color: $COLOR_DARK;
      display: none;
    }

    &.other {
      background-color: $COLOR_LIGHT;
    }

    .download__icon {
      &.you {
        color: $COLOR_LIGHT;
      }

      &.other {
        color: $COLOR_DARK;
      }
    }
  }

  .file__size {
    font-size: 0.7rem;
    letter-spacing: 1px;
  }
}

// Media query breakpoints
@media (max-width: 700px) {
  .file__content {
    max-width: 18rem;
    padding: 0.5rem 0.75rem;
    font-size: 0.95rem;
    gap: 0.5rem;

    .download__icon__wrapper {
      padding: 0.6rem;
    }
  }
}

@media (max-width: 600px) {
  .file__content {
    max-width: 15rem;
  }
}

@media (max-width: 440px) {
  .file__content {
    max-width: 13rem;
  }
}
