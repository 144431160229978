@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300&display=swap');
.formContainer {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("../img/login.jpg");
  background-position: center bottom;
  background-size: cover;

  .form-body-instruction {
    position: absolute;
    background-color: #fff;
    box-shadow: rgba(125, 130, 136, 0.256) 0px 8px 24px;
    min-width: 350px;
    max-width: 500px;
    border-radius: 10px;
    animation: slideUp 0.6s ease-out, popUp 0.3s ease-in;
    .instructionHead{
      text-align: center;
      padding: 10px;
      background-color: #286eff;
      padding: 10px 16px;
      text-align: center;
      width: calc(100% - 32px);
      border-radius: 10px 10px 0 0;
      animation: slideUp 0.6s ease-out, popUp 0.3s ease-in;
      color: #fff;
    }
    .instructionBody{
      border-radius: 0 0 10px 10px ;
      background-color: #fff;
      padding: 10px 16px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      
      p{
        font-size: 12px;
        span{
          color: rgb(216, 0, 0);
        }
        padding: 10px;
        text-align: center;
      }

      .instructions{
        
          p{
            font-size: 14px;
            text-align: left;
            font-family: 'Rubik', sans-serif;
          }
      }
      button {
        background-color: #286eff;
        color: white;
        padding: 10px;
        font-weight: bold;
        border: none;
        cursor: pointer;
        width: 180px;
        border-radius: 4px;
        transition: background-color 0.3s ease;

        &:hover {
          background-color: #033391;
        }
        animation: slideUp 0.6s ease-out;
      }
    }
  }

  .formWrapper {
    background-color: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 0;
    align-items: center;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    animation: slideUp 0.6s ease-out, popUp 0.3s ease-in;
    width: 30%;

    .form-head {
      background-color: #286eff;
      padding: 10px 16px;
      text-align: center;
      width: calc(100% - 32px);
      border-radius: 10px 10px 0 0;
      animation: slideUp 0.6s ease-out, popUp 0.3s ease-in;

      @media screen and (max-width: 480px) {
        padding: 0px 16px;
      }
      

      .logo {
        color: #fff;
        font-weight: bold;
        font-size: 2rem;
        @media screen and (max-width: 480px) {
          font-size: 1.5rem;
        }
        .u,
        .c {
          color: #fff;
        }
      }
    }
   

    .form-body {
      padding: 10px 60px;
      padding-bottom: 20px;
      word-wrap: break-word;
      width: calc(100% - 100px);
      text-align: center;
      animation: slideUp 0.6s ease-out;

      p {
        color: #286eff;
        font-size: 12px;
        margin-top: 10px;
        font-weight: 600;
        animation: slideUp 0.6s ease-out;
      }

      .title {
        color: #242424;
        font-size: 18px;
        font-family: Calibri, "Trebuchet MS", sans-serif;
        padding-bottom: 20px;
        animation: slideUp 0.6s ease-out;
      }

      .Warning {
        color: #424242;
        animation: slideUp 0.6s ease-out;
      }

      a {
        text-decoration: none;
        color: #033391;
        &:hover {
          color: #286eff;
          cursor: pointer;
        }
      }

      .resend {
        font-weight: 600;
        font-size: 13px;
        animation: slideUp 0.6s ease-out;
      }
      .uploadProgress {
        background-color: #e6e6e6;
        height: 6px;
        border-radius: 4px;
        border: none;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
          rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
      }

      .displayImage {
        display: flex;
        justify-content: center;
        align-items: center;
        animation: slideUp 0.6s ease-out;

        .placeholder {
          width: 100px;
          height: 100px;
          object-fit: cover;
          object-position: center;
          border-radius: 50%;
          background-color: #033391;
          padding: 0;
          border: #b6b6b6a2 1px solid;
          animation: popUp 0.6s ease-out;

          @media screen and (max-width: 480px) {
            width: 60px;
            height: 60px;
            
          }

        }

        .image {
          display: block;
          position: relative;
          .fa-check-circle {
            color: #04c804;
          }

          &:hover {
            i {
              color: #f46c10;
            }
          }

          i {
            position: absolute;
            top: 6%;
            right: 2%;
            color: #fff;
            background-color: #bebebe;
            border-radius: 50%;
            padding: 3px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            cursor: pointer;
          }
        }

        .selectedImage {
          width: 60px;
          height: 60px;
          object-fit: cover;
          object-position: center;
          border-radius: 50%;
          animation: popUp 0.6s ease-out;
        }
      }
    }

    .error {
      color: #f46c10;
      font-size: 12px;
      height: 1px;
      padding: 10px 0;
      animation: slideUp 0.6s ease-out;
    }

    form {
      display: flex;
      flex-direction: column;
      gap: 15px;
      padding-top: 20px;
      animation: slideUp 0.6s ease-out;

      input {
        padding: 10px 5px;
        border: none;
        width: 100%;
        border-bottom: 1px solid #286eff;
        animation: slideUp 0.6s ease-out;

        &::placeholder {
          color: rgb(175, 175, 175);
        }
      }

      button {
        background-color: #286eff;
        color: white;
        padding: 10px;
        font-weight: bold;
        border: none;
        cursor: pointer;
        border-radius: 4px;
        transition: background-color 0.3s ease;

        &:hover {
          background-color: #033391;
        }
        animation: slideUp 0.6s ease-out;
      }

      label {
        display: flex;
        align-items: center;
        gap: 10px;
        color: #286eff;
        font-size: 12px;
        cursor: pointer;

        img {
          width: 32px;
        }
      }
    }
  }

  // Responsive Styles
  @media screen and (max-width: 480px) {
    .formWrapper {
      width: 90% !important; 
      padding: 0px !important;
      .form-body{
        padding: 20px !important;
      }
    }
    .form-body-instruction{
      margin: 3%;
    }
  }

  @media screen and (max-width: 768px) {
    .formWrapper {
      padding: 0px !important;
      width: 80%;
      max-width: 500px;
    }
  }

  @media screen and (max-width: 1200px) {
    .formWrapper {
      padding: 0px !important;
      width: 70%;
      max-width: 600px;
    }
  }

  // Animation Styles
  @keyframes slideUp {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes popUp {
    0% {
      opacity: 0;
      transform: scale(0.8);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
}
