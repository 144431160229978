// Variables
$COLOR_WHITE: #ffffff;
$COLOR_GREY: #808080;
$COLOR_DARK: #333333;
$COLOR_LIGHT: #f0f0f0;
@import url("https://fonts.googleapis.com/css2?family=Gochi+Hand&family=Noto+Sans+JP:wght@600&family=Rubik:wght@300&display=swap");
// Main styles
.chatroom__page {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  position: relative;

  @media (max-width: 480px) {
    height: 100vh;
  }

  @media (max-width: 768px) {
    height: 100vh;
  }

  .chatroom__menu {
    // background-color: red;
    width: 22%;
    animation: slideRight 2s forwards;

    z-index: 2;
    @media (max-width: 480px) {
      width: 100%;
      position: absolute;
    }
  }

  .chatroom__contents {
    width: 78%;
    height: calc(100vh);
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    // background-image: url("../../assets/groupChatChatRoombg.png");
    // background-size: cover;
    // background-position: center;
    // background-repeat: no-repeat;
    background: linear-gradient(12deg, #1c2129, #16181b);

    @media (max-width: 480px) {
      width: 100%;
      height: 100vh;
    }

    .item__author {
      font-size: 11px;
      font-weight: 600;
      font-family: "Open Sans", sans-serif;
      background-color: #286eff;
      padding: 15px;
      height: 10px;
      width: 10px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
    }

    .item__time {
      font-size: 11px;
      font-weight: 600;
      font-family: "Open Sans", sans-serif;
      color: #cfcfcf;
    }

    .file_metaDescriptions {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 1px 5px;
      font-size: 14px;
      padding: 1px 6px;
      color: #fff;

      .file__size {
        font-weight: 600;
        font-size: 11px;
      }
      .download__icon__wrapper {
        .fa-download {
          font-size: 12px;
          color: #fff;
          transition-duration: 0.3s;
          &.other {
            color: #fff;
          }
          &:hover {
            cursor: pointer;
            color: #232323;
            transform: scale(1.5);
          }
        }
      }
    }

    .chatroom__box {
      display: flex;
      flex-direction: column;
      overflow-y: scroll;
      height: calc(100vh - 125px);
      @media (max-width: 480px) {
        height: calc(100vh - 200px);
      }
      .Welcome {
        display: none;
        &:nth-child(1) {
          &::before {
            text-align: center;
            content: "Chat room ready! Share the ROOM CODE to invite your friends.";
            font-size: 14px;
            font-weight: 600;
            color: #286eff;
            display: block;
            margin: 0.5rem 0rem;
            text-transform: uppercase;
            p {
              font-family: "Noto Sans JP", sans-serif;
              font-weight: 600;
            }
          }
        }
        &:nth-child(4n + 1) {
          display: block;
        }
      }
    }

    .chatroom__input {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin: 1% 2% 2%;
      gap: 20px;

      .chatroom__attachment__menu {
        position: relative;
        .chatroom__clip {
          align-items: center;
          border: 1px solid rgba(36, 36, 36, 0.094);
          border-radius: 50%;
          display: flex;
          font-size: 20px;
          height: 30px;
          width: 30px;
          justify-content: center;
          padding: 7px;
          transition-duration: 0.4s;

          border-radius: 8px;
          background: #1b1f26;
          box-shadow: 6px -6px 12px #0d0f12, -6px 6px 12px #292f3a;
          color: #FFF;

          &:hover {
            color: #1159ea;
            background: linear-gradient(145deg, #14171d, #222730);
            border-radius: 8px;
            box-shadow: 5.71px 5.71px 6px #111318, -5.71px -5.71px 6px #252b34;
            cursor: pointer;
          }
        }

        .chatroom__float__menu {
          position: absolute;
          z-index: 5;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 0.6rem;
          bottom: 120%;

          .wrapper {
            padding: 0.6rem;
            filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.2));
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            border-radius: 8px;
            background: #1b1f26;
            box-shadow: 6px -6px 12px #0d0f12, -6px 6px 12px #292f3a;
            font-size: 22px;
            height: 30px;
            width: 30px;
            justify-content: center;
            padding: 7px;
            transition-duration: 0.4s;
            &:hover {
              color: #fff;
              background: linear-gradient(145deg, #14171d, #222730);
              border-radius: 8px;
              box-shadow: 5.71px 5.71px 6px #111318, -5.71px -5.71px 6px #252b34;
              cursor: pointer;
            }

            .icon {
              color: $COLOR_WHITE;
              font-size: 1.6rem;
            }
          }

          @keyframes popSlideUp {
            0% {
              transform: translateY(100%);
              opacity: 0;
            }
            60% {
              transform: translateY(-10%);
              opacity: 1;
            }
            100% {
              transform: translateY(0);
            }
          }

          .file__wrapper {
            animation: popSlideUp 0.5s ease-out;
            color: #E6480C;
          }

          .picture__wrapper {
            animation: popSlideUp 0.5s ease-out;
            color: #e2b205;
          }

          .video__wrapper {
            animation: popSlideUp 0.7s ease-out;
            color: #02a8ae;
          }

          .audio__wrapper {
            animation: popSlideUp 0.8s ease-out;
            color: #00B894;
          }
        }
      }
    }
  }

  .chatroom__textarea__div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 70%;
    .chatroom__textarea {
      width: 100%;
      border: none;
      outline: none;
      text-align: left;
      resize: none;
      height: 30px;
      width: 100%;
      border-radius: 8px;
      font-size: 14px;
      padding: 15px 10px 7px;
      color: #fff;
      background: #1b1f26;
      border-radius: 8px;
      box-shadow: inset 4.2px 4.2px 8px #0f1115, inset -3.8px -3.8px 8px #272d37;
      transition-duration: 0.6s;
      &:focus,
      &:hover {
        transition-duration: 0.6s;
        background: linear-gradient(45deg, #181c22, #1d2129);
        box-shadow: 5.71px 5.71px 6px #111318, -5.71px -5.71px 6px #252b34;
      }
    }
  }
  .chatroom__send {
    align-items: center;
    border: none;
    border-radius: 8px;
    background: linear-gradient(45deg, #286eff, #286eff);
    box-shadow: 6px -6px 12px #0d0f12, -6px 6px 12px #292f3a;
    color: #fff;
    display: flex;
    font-size: 10px;
    padding: 10px 20px;
    transition-duration: 0.6s;
    &:hover {
      cursor: pointer;
      background: linear-gradient(45deg, #1159ea, #0444c4);
      box-shadow: 5.71px 5.71px 6px #111318, -5.71px -5.71px 6px #252b34;
      transform: rotate(-2deg) translateX(5px);
    }
  }
}

// Media query breakpoints
@media (max-width: 1100px) {
  .chatroom__float__menu {
    margin-left: 0rem;
  }
}

@media (max-width: 850px) {
  .chatroom__menu {
    display: flex;
  }
}

@media (max-width: 700px) {
  .chatroom__input {
    bottom: 0.5rem;
    margin: 0rem 0.7rem;

    .wrapper {
      padding: 0.5rem;
      filter: none;

      .icon {
        font-size: 1.4rem;
        color: $COLOR_DARK;
      }

      .chatroom__clip {
        font-size: 1.6rem;
      }

      .chatroom__send {
        font-size: 1.6rem;
      }
    }
  }
}

@media (max-width: 440px) {
  .chatroom__input {
    gap: 0.25rem;

    .chatroom__textarea {
      padding: 0.35rem;
    }
  }
}
