// Color Theme
$input-background-color: #f3f6ff;
$input-padding: 15px 20px;
$input-border-radius: 22px;
$button-border-radius: 8px;
$button-hover-background-color: #1050d1;
$primary-color: #286eff;
$danger-color: #d24141;

// Keyframe Animations
@keyframes popUp {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes slideLeftToRight {
  0% {
    opacity: 0;
    transform: translateX(-50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

// Layout Styles
.News {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
  padding: 0;

  .left {
    width: 23%;
    border-right: 1px solid #b1b1b138;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    height: 100vh;

    .form {
      padding: 2%;

      h3 {
        text-align: center;
        font-size: 22px;
        padding-bottom: 4px;
      }

      form {
        padding: 2% 1%;

        .input {
          padding: 1% 1%;
          animation: slideLeftToRight 0.6s ease-out;
        }

        .coverImage {
          width: 100%;
          border-radius: 4px;
          height: 200px;
          object-fit: cover;
          object-position: center;
          animation: popUp 0.6s ease-out;
        }

        input {
          width: calc(100% - 20px);
          border: none;
          border-bottom: 1px solid #fff;
          padding: 10px;
          background-color: #fff;
          color: #fff;
          font-weight: 500;
          color: #000;
          outline: none;
          border-radius: 8px;
          box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
          &:focus {
            border-bottom: 1px solid $primary-color;
          }
          &::placeholder {
            color: #000;
          }
          animation: slideLeftToRight 0.6s ease-out;
        }

        select{
            width: calc(100% - 10px);
            padding: 9px;
            margin: 4px;
        }

        textarea {
          width: calc(100% - 20px);
          border: none;
          border-bottom: 1px solid #fff;
          padding: 10px;
          background-color: #fff;
          color: #fff;
          height: 200px;
          font-weight: 500;
          color: #000;
          outline: none;
          box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
          resize: none;
          border-radius: 8px;
          &:focus {
            border-bottom: 1px solid $primary-color;
          }
          &::placeholder {
            color: #000;
          }
          animation: slideLeftToRight 0.6s ease-out;
        }

        .button {
          padding: 10px;
          text-align: center;

          button {
            background-color: $primary-color;
            color: white;
            padding: 8px 10px;
            font-weight: bold;
            border: none;
            cursor: pointer;
            border-radius: $button-border-radius;
            width: 100%;
            box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
            &:hover {
              background-color: $button-hover-background-color;
              color: white;
              box-shadow: none;
            }
            animation: slideLeftToRight 0.6s ease-out;
          }
        }

        .error {
          font-size: 12px;
          color: $danger-color;
        }

        label {
          font-size: 12px;
          text-align: center;
        }

        .progress-bar {
          width: calc(100% - 20px);
          height: 10px;
          border-radius: 4px;
          margin-top: 10px;
          justify-content: space-between;
          align-items: center;
          gap: 10px;
          display: flex;
          font-size: 12px;

          progress {
            width: 100%;
            border-radius: 4px;
          }
        }

        .upload-complete {
          width: 100%;
          padding: 2px;
          text-align: center;
          font-size: 12px;
        }
      }
    }
  }
  .right{
    width: 80%;
    height: 100vh;
  }
}
