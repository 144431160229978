.RoomSettings {
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 3% 0%;
  width: 100%;
  .roomMode {
    display: none;
  }
  .share {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 15px;
    padding: 2% 5%;
    border-radius: 5px;
    background: linear-gradient(45deg, #181c22, #1b1f26);
    box-shadow: -5px 5px 4px #171717, 5px -5px 4px #1c1c1c;
    transition-duration: 0.6s;
    &:hover {   
      background: #161616;
      box-shadow: 5px -5px 8px #111111, -5px 5px 8px #141414;
    }

    p {
      background: #1d2129;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      padding: 4px;
      border-radius: 100%;
      box-shadow: 3.3px 3.3px 7px #0c0f12, -3.3px -3.3px 7px #111519;
      transition-duration: 0.6s;
      &:hover {
        transform: scale(1.1);
        cursor: pointer;
      }
    }
  }
}
