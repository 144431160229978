.TabContents{
    width: 60%;
    background-color: #fff;
    border-radius: 8px;
    margin: 2% 0;
    height: 80vh;
    box-shadow: rgba(27, 31, 35, 0.04) 0px 1px 0px, rgba(255, 255, 255, 0.25) 0px 1px 0px inset;
    overflow-y: auto;

    @media screen and (max-width: 480px) {
        width: calc(100% - 20px);
        margin: 0 2%;
    }

    @media screen and (max-width: 768px){
        width: calc(100% - 20px);
        margin: 0 2%;
    }

}