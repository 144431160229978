.StartupCard {
  display: flex;
  // background color gradient of FEE2EE FFECE9
  background: linear-gradient(30deg, #F5F6FA, #F5F6FA);
  flex-direction: column;
    margin-bottom: 10%;

    @media screen and (max-width: 768px) {
      flex-direction: column;
        
    }

    @media screen and (max-width: 480px) {
      flex-direction: column;
        
    }

  .heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4% 5%;
    h2 {
      font-size: 2rem;
      color: #242424;
      margin: 0;
      padding: 0;
    }
    button {
      background-color: #fff;
      color: #242424;
      border: #00000000 1px solid;
      padding: 2px 10px;
      border-radius: 5px;
      box-shadow: 0px 0px 10px #2870ff61;
      &:hover {
        background-color: #276dfd;
        color: white;
        border: #ffffff5a solid 1px;
        cursor: pointer;
      }
    }
  }
  .cards{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
    padding: 2% 4%;

    @media screen and (max-width: 768px) {
      flex-direction: column;
        
    }

    @media screen and (max-width: 480px) {
      flex-direction: column;
        
    }
    .card{
        width: 28%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        border-radius: 10px;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

        @media screen and (max-width: 768px) {
          width: 100%;
            
        }

        @media screen and (max-width: 480px) {
          width: 100%;
            
        }

        .image{
            width: 100%;
            height: 250px;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center top;
                border-radius: 10px 10px 0 0;
            }
        }
        .details{
            width: calc(100% - 10px);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 10px;
            .location-type{
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 5px 10px;
                width: calc(100% - 20px);
                font-weight: 700;
                p{
                    font-size: 1rem;
                    color: #242424;
                    margin: 0;
                    padding: 0;

                    &:first-child{
                        background-color: #e3ecff;
                        padding: 2px 4px;
                        border-radius: 4px;
                        i{
                            font-size: 1.2rem;
                            color: #276dfd;
                            animation: spreadTextShadow 1s infinite;
                            margin-right: 5px;
                        
                        }
                    }

                    
                }
            }
            hr{
                width: calc(100% - 20px);
                border: #00000015 1px solid;
            
            }
        }
        .info{
            padding: 10px;
            display: flex;
            flex-direction: column;
            gap: 10px;
            width: calc(100% - 20px);
            h2{
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 1.5rem;
                color: #242424;
                margin: 0;
                padding: 0;
                span{
                    font-size: 1rem;
                    color: #276dfd;
                    margin-left: 5px;
                
                }
            }
            p{
                font-size: 0.8rem;
                color: #555555;
                margin: 0;
                padding: 0;
                span{
                    font-weight: 700;
                    color: #3c3c3c;
                
                }
            }
        
        }

        .CTA{
            
            display: flex; 

            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap: 20px;
            width: calc(100% - 20px);
            
            button{
                padding: 10px 13px;
                border: none;
                margin: 0 0 10px 0 ;
                border-radius: 5px;
                background-color: #03ce25;
                color: white;
                font-weight: 600;
                box-shadow: 0px 10px 10px #03ce2561;
                
                &:first-child{
                    background-color: #ff3d3d;
                    box-shadow: 0px 10px 10px #ff3d3d61;
                    &:hover{
                        background-color: #ff3d3d;
                        box-shadow: none;
                    
                    }
                }
                
                &:hover{
                    background-color: #03a81f;
                    box-shadow: none;
                    cursor: pointer;
                }
            }
        }

        

        progress{
            margin-top: 2%;
            width: calc(100%);
            height: 10px;
            border-radius: 0 0 10px 10px;
            background-color: #f3f3f3;
            box-shadow: 0px 10px 10px #03ce2561;
            &::-webkit-progress-bar {
                background-color: #286eff;
                border-radius: 5px;
            }
            &::-webkit-progress-value {
                background-color: #286eff;
                border-radius: 5px;
            }
        
        }
    }
  }
}


@keyframes spreadTextShadow {
    0% {
        text-shadow: 0px 10px 10px #276dfd;
    }
    50% {
        text-shadow: 0px 10px 10px #276efd00;
    } 
    100% {
        text-shadow: 0px 10px 10px #276dfd;
    }
}


@keyframes bubbles {
  0% {
    opacity: 0;
  }
  20% {
    //show and hint at moving
    opacity: 1;
    transform: translate(0, -20%);
  }
  100% {
    opacity: 0;
    transform: translate(0, -1000%); //Big bubbles move faster
  }
}
@keyframes hearts {
  0% {
    opacity: 0;
    transform: translate(0, 0%) rotate(45deg);
  }
  20% {
    //show and hint at moving
    opacity: 0.8;
    transform: translate(0, -20%) rotate(45deg);
  }
  100% {
    opacity: 0;
    transform: translate(0, -1000%) rotate(45deg); //Big hearts move faster
  }
}
@keyframes lines {
  0%,
  50%,
  100% {
    transform: translateY(0%);
  }
  25% {
    transform: translateY(100%);
  }
  75% {
    transform: translateY(-100%);
  }
}
@keyframes confetti {
  0% {
    opacity: 0;
    transform: translateY(0%) rotate(0deg);
  }
  10% {
    opacity: 1;
  }
  35% {
    transform: translateY(-800%) rotate(270deg);
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(2000%) rotate(1440deg);
  }
}
@keyframes fires {
  0% {
    transform: rotate(-70deg) translateY(0%);
  }
  25% {
    transform: rotate(-20deg) translateY(-5%);
    opacity: 1;
  }
  50% {
    transform: rotate(-70deg) translateY(-10%);
  }
  75% {
    transform: rotate(-20deg) translateY(-20%);
  }
  100% {
    transform: rotate(-70deg) translateY(-40%);
    opacity: 1;
  }
}
@keyframes sunbeams {
  0% {
    transform: translateY(40%) rotate(0deg);
  }
  50% {
    transform: translateY(-40%) rotate(180deg);
  }
  100% {
    transform: translateY(40%) rotate(360deg);
  }
  0%,
  14%,
  17%,
  43%,
  53%,
  71%,
  80%,
  94%,
  100% {
    opacity: 0;
  }
  6%,
  15%,
  24%,
  28%,
  48%,
  55%,
  78%,
  82%,
  99% {
    opacity: 1;
  }
}
