// Main styles
.chat__topbar {
  display: flex;
  flex-direction: row;
  padding: 6px 20px;
  // box-shadow: rgba(0, 0, 0, 0.049) 1px 0px 8px 0px;
  // border-bottom: 1px solid #313131e4;
  justify-content: space-between;
  background: linear-gradient(145deg, #16191f, #20252d);
  box-shadow: 4.2px 4.2px 7px #0e1013, -4.2px -4.2px 7px #292f39;

  .twoColumn {
    display: flex;
    flex-direction: row;
    padding: 0px 5px;
    gap: 10px;
    align-items: center;
    .usersNumber {
      width: 25px;
      height: 25px;
      border-radius: 100%;
      background-color: #011f5d;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 11px;
      color: #fff;
      font-weight: 600;
      position: relative;
      display: none;
      @media (max-width: 480px) {
        display: flex;
      }
      p {
        position: absolute;
        top: -40%;
        background-color: #b00404;
        padding: 1px 3px;
        right: -2%;
      }
    }
    .roomName {
      text-align: center;
      h2 {
        font-size: 22px;
        color: #c1c1c1;
      }
    }
  }

  .topbar__btns {
    display: flex;
    flex-direction: row;
    gap: 15px;
    align-items: center;
    justify-content: space-around;
    padding: 9px 2px 5px;
    color: #fff;
    border-radius: 8px;
    background: #161616;
    border: 1px #cccccc15 solid;
    box-shadow: -6px 6px 6px #0e0e0e, 6px -6px 6px #1e1e1e;
    margin-right: 6px;
    .fa-portal-exit {
      color: #dd3705;
    }
    .fa-share-alt {
      color: #28c804;
    }
  }

  .topbar__icons {
    cursor: pointer;
    font-size: 1.2rem;
    padding: 4px 5px;
    margin: 0px 5px;
    width: 20px;
    text-align: center;
    border-radius: 5px;
    background: linear-gradient(225deg, #181818, #141414);
    box-shadow: 5px -5px 8px #090909, -5px 5px 8px #232323;
    transition-duration: 0.6s;

    &:hover {
      background: #161616;
      box-shadow: 5px -5px 8px #111111, -5px 5px 8px #383838;
    }
  }

  .topbar__attachment__menu {
    position: relative;

    .topbar__float__menu {
      position: absolute;

      z-index: 10;
      right: 0;
      top: 2.5rem;
      border-radius: 5px;

      > p {
        padding: 0.6rem 2rem;
        border-radius: 5px;
      }
    }
  }

  .voice__call,
  .video__call {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0.8rem;
    cursor: pointer;

    .call__icon {
      font-size: 1.25rem;
    }
  }
}

// Media query breakpoints
@media (max-width: 700px) {
  .chat__topbar {
    padding: 1.2rem 0rem;

    .topbar__float__menu {
      top: 2rem;

      > p {
        padding: 0.5rem 1.5rem;
        font-size: 0.95rem;
      }
    }

    .voice__call,
    .video__call {
      gap: 0.5rem;
    }
  }
}
