*{
  transition-duration: 0.6s;
  transition-timing-function: ease-in-out;
}
.App {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .sidebarLeft {
    width: 5%;
    order: 1;
    height: 100vh;
  }

  .centerComponent {
    width: 95%;
    order: 2;
    height: 100vh;
    background-color: #F3F6FD;
  }
}

textarea:focus,
input:focus {
  outline: none;
}
/* Responsive styles for laptop and tablet devices (width >= 768px) */
@media screen and (min-width: 768px) {
  .App {
    .centerComponent {
      width: 100%;
    }
  }
}

@media screen and (max-width: 480px) {
  .App {
    overflow: hidden;
    .sidebarLeft {
      height: auto;
      .top {
        padding: 0px;
        margin: 0px;
      }
    }
  }
  .home .container {
    width: 100%;
    .sidebar {
      width: 100%;
    }
    .chat {
      width: 0%;
    }
  }
  .home,
  .container {
    height: 90vh;
    @media screen and (max-width: 480px) {
      height: auto;
    }
  }
}

/* Responsive styles for mobile devices (width < 768px) */
@media screen and (max-width: 767px) {
  .App {
    flex-direction: column;
    .centerComponent {
      width: 100%;
      height: 95vh;
      order: 1;
    }

    .sidebarLeft {
      width: 100%;
      height: none;
      padding: 0;
      order: 2;
    }
  }
}

/* Scrollbar styles () */
/* Works on Firefox and other */
*{
  -ms-overflow-style: none;
  scrollbar-width: none;
  -webkit-tap-highlight-color: transparent;
}

.textcontainer {
  padding: 40px 0;
  text-align: center;
  .particletext {
    text-align: center;
    position: relative;
    &.bubbles {
      > .particle {
        opacity: 0;
        position: absolute;
        background-color: rgba(33, 150, 243, 0.5);
        animation: bubbles 3s ease-in infinite;
        border-radius: 100%;
      }
    }
    &.hearts {
      > .particle {
        opacity: 0;
        position: absolute;
        background-color: rgba(204, 42, 93, 1);
        animation: hearts 3s ease-in infinite;
        &:before,
        &:after {
          position: absolute;
          content: "";
          border-radius: 100px;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;
          background-color: rgba(204, 42, 93, 1);
        }
        &:before {
          transform: translateX(-50%);
        }
        &:after {
          transform: translateY(-50%);
        }
      }
    }
    &.lines {
      > .particle {
        position: absolute;
        background-color: rgba(244, 67, 54, 0.5);
        animation: lines 3s linear infinite;
      }
    }
    &.confetti {
      > .particle {
        opacity: 0;
        position: absolute;
        animation: confetti 3s ease-in infinite;
        &.c1 {
          background-color: rgba(76, 175, 80, 0.5);
        }
        &.c2 {
          background-color: rgba(156, 39, 176, 0.5);
        }
        &.c3 {
          background-color: rgba(255, 235, 59, 0.5);
        }
        &.c4 {
          background-color: rgba(33, 150, 243, 0.5);
        }
        &.c5 {
          background-color: rgba(244, 67, 54, 0.5);
        }
      }
    }
    &.fire {
      > .particle {
        position: absolute;
        background-color: rgba(255, 193, 7, 0.5);
        border-radius: 40px;
        border-top-right-radius: 0px;
        animation: fires 0.8s linear infinite;
        transform: rotate(-45deg);
        opacity: 0;
        &:before {
          position: absolute;
          content: "";
          top: 60%;
          left: 40%;
          transform: translate(-50%, -50%);
          width: 50%;
          height: 50%;
          border-radius: 40px;
          border-top-right-radius: 0px;
          background-color: rgba(251, 140, 0, 0.5);
        }
      }
    }
  
    &.sunbeams {
      > .particle {
        position: absolute;
        background-color: rgba(253, 216, 53, 0.5);
        animation: sunbeams 3s linear infinite;
      }
    }
  }
}