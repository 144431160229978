$particle-color: #ffcc00;
$particle-size: 4px;
$particle-spacing: 10px;
$particle-animation-duration: 1s;
$particle-count: 100;

@keyframes sprinkle {
  from {
    transform: translateY(-10px) translateX(-50%);
    opacity: 1;
  }
  to {
    transform: translateY(300px) translateX(calc(-50% + 50px));
    opacity: 0;
  }
}

@keyframes pulseAnimation {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes popAnimation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes appearAnimation {
  from {
    opacity: 0;
    transform: translateX(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes rotateAndSwipeRight {
  0% {
    transform: rotate(0deg) translateX(0);
  }
  100% {
    transform: translateY(-10px) translateX(100%) rotate(30deg);
  }
}

@keyframes rotateAndSwipeLeft {
  0% {
    transform: rotate(0deg) translateX(0);
  }
  100% {
    transform: translateY(-20px) translateX(-100%) rotate(-30deg);
  }
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes popAnimation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

// Define the keyframes for the container background animation
@keyframes containerBackgroundAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

// Define the keyframes for the cheers animation
@keyframes cheersAnimation {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(5deg);
  }
  75% {
    transform: rotate(-1deg);
  }
}

// Define the keyframes for the link animation
@keyframes linkAnimation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

// Define the keyframes for the h2 animation
@keyframes h2Animation {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
}

.Dating {
  text-align: center;
  padding: 0;
  position: relative;
  .quote{
    font-size: 12px;
    font-weight: 600;
    color: #242424d5;
  }
  .error{
    font-size: 12px;
    font-weight: 600;
    color: #E15F5F;
    padding: 4px 20px;
  }
  .datingPic {
    width: 80%;
    height: 100%;
    margin-top: 5%;
  }
  .goto {
    padding: 10px;
    margin: 5px 35px;
    background-color: #ffffff;
    border-radius: 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    animation: appearAnimation 1s ease;
    background: linear-gradient(75deg, #eb3fb3, #d65ec8, #b305d2);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

    .linkto {
      text-decoration: none;
      font-weight: 600;
      font-size: 18px;
      color: #fff;
      position: relative;
    }
  }

  h3 {
    background: linear-gradient(75deg, #eb3fb3, #e272d5, #b305d2);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    font-size: 20px;
    padding: 0;
    animation: gradientAnimation 4s linear infinite, popAnimation 0.3s ease;
  }

  .ShowCards {
    background: linear-gradient(75deg, #eb3fb3, #e125cc, #c718e7);
    color: #fff;
    border: none;
    margin-top: 10px;
    padding: 8px 20px;
    border-radius: 5px;
    font-weight: 600;
    transition: background-color 0.3s ease;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

    &:hover {
      background: linear-gradient(75deg, #d81397, #d410be, #6e0880);
      cursor: pointer;
      box-shadow: none;
      animation: infiniteLoop 3s linear infinite;
    }
  }

  //

  .matchFullScreen {
    position: absolute;
    width: 330px;
    z-index: 20;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    .container {
      background: rgb(230, 242, 249);
      background: linear-gradient(90deg, #e6f2f9 0%, #ffe7ec 100%);
      border-radius: 22px;
      box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
      height: 358px;
      display: block;
      padding: 20px 20px 10px 20px;

      .cheers {
        position: absolute;
        z-index: 8;
        top: 3.3%;
        left: 0;
      }
      img {
        width: 100%;
        object-fit: cover;
        z-index: 10;
        margin-top: 15px;
      }
      p {
        color: #ef5b76;
        font-weight: 700;
      }
      .center {
        display: flex;
        justify-content: center;
        align-items: center;
        .link {
          box-shadow: 0 8px 10px rgba(235, 63, 178, 0.43),
            0 6px 10px rgba(225, 37, 203, 0.134),
            0 0 20px rgba(200, 24, 231, 0.167);
          z-index: 20;
          background: linear-gradient(75deg, #bee4fa, #d410be, #ffe7ec);
          color: #fff;
          background-size: 600% 600%;
          animation: gradientAnimation 3s linear infinite;
          width: 120px;
          padding: 8px;
          border-radius: 5px;
          &:hover {
            animation: linkAnimation 0.5s ease-in-out infinite;
          }
          a {
            text-decoration: none;
            color: #fff;
            font-weight: 600;
          }
        }
      }

      h2 {
        background-color: #5d5d5d;
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
        background-size: 600% 600%;
        animation: gradientAnimation 4s linear infinite;
        text-align: center;
      }
    }
  }

  //
  .card-slider {
    padding: 20px 30px;

    .profiles {
      display: block;
      background-color: #fff;
      border-radius: 22px;
      box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
      overflow: hidden;

      .d-card {
        justify-content: center;
        align-items: center;
        position: relative;
        display: none;
        padding: 10px;
        border-radius: 22px 22px 0px 0px;
        // animation-name: rotateAndSwipeRight;
        animation-duration: 0.1s;
        animation-timing-function: ease-in-out;

        .data {
          border-radius: 12px;
          position: relative;
          height: 300px;
          width: 280px;
          background-size: cover;
          background-position: center;
          box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
            rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
          animation: appearAnimation 1s ease;
          transition: opacity 0.3s ease;
          &::before {
            content: "";
            position: absolute;
            top: -10px;
            left: 50%;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background-color: #ffcc00;
            animation: sprinkle 1s infinite linear;
          }

          .blur {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 45px;
            border-radius: 0px 0px 12px 12px;
            background-color: #000000ba;
            filter: blur(2px);
          }

          .info {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 45px;
            color: #fff;

            p {
              font-weight: 300;
              font-size: 12px;
              animation: gradientAnimation 4s linear infinite,
                popAnimation 0.6s ease;

              &:nth-child(1) {
                font-weight: 600;
                padding-top: 4px;
                font-size: 14px;
                span {
                  font-weight: 600;
                  font-size: 16px;
                  padding-left: 8px;
                  color: #fb92d8;
                }
              }
            }
          }
        }
        .data::before,
        .data::after,
        .data::before:nth-child(3),
        .data::before:nth-child(4),
        .data::before:nth-child(5) {
          content: "";
          position: absolute;
          width: 4px;
          height: 4px;
          background-color: #d81397;
          border-radius: 50%;
          opacity: 0;
          animation: sprinkle 1s infinite linear;
        }

        .data::before {
          left: 10px;
          animation-delay: 0s;
        }

        .data::after {
          left: 20px;
          animation-delay: 0.5s;
        }

        .data::before:nth-child(3) {
          left: 30px;
          animation-delay: 0.2s;
        }

        .data::before(4) {
          left: 40px;
          animation-delay: 0.7s;
        }

        .data::before(5) {
          left: 50px;
          animation-delay: 0.4s;
        }
      }

      .active {
        display: flex;
      }
    }

    .controls {
      padding: 10px;
      background-color: #ffffff;
      border-radius: 0px 0px 22px 22px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      animation: appearAnimation 1s ease;

      button {
        border: none;
        background-color: #fff;
        color: #286eff;
        height: 50px;
        width: 50px;
        border-radius: 50%;
        box-shadow: #959da533 0px 8px 24px;
        color: #fff;
        font-size: 22px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: box-shadow 0.3s ease;

        &:hover {
          box-shadow: #959da533 0px 8px 24px,
            rgba(0, 0, 0, 0.2) 0px 6px 10px -5px,
            rgba(0, 0, 0, 0.2) 0px 0px 30px;
        }

        &.like {
          background: linear-gradient(75deg, #eb3fb3, #e125cc, #c718e7);
          box-shadow: 0 8px 10px rgba(235, 63, 178, 0.37),
            0 6px 10px rgba(225, 37, 203, 0.134),
            0 0 30px rgba(200, 24, 231, 0.167);
          i {
            animation: pulseAnimation 1.6s ease infinite;
          }
        }

        &.skip {
          background: linear-gradient(75deg, #eb3fb3, #e125cc, #c718e7);
          -webkit-background-clip: text;
          background-clip: text;
          color: transparent;
          box-shadow: 0 2px 10px rgba(235, 63, 178, 0.152),
            0 2px 10px rgba(225, 37, 203, 0.134),
            0 0 30px rgba(200, 24, 231, 0.167);
          height: 44px;
          width: 44px;
        }

        &.star {
          background: linear-gradient(75deg, #e3c136, #f6de67, #b1a007);
          -webkit-background-clip: text;
          background-clip: text;
          color: transparent;
          height: 44px;
          width: 44px;
          box-shadow: 0 2px 10px rgba(235, 227, 63, 0.152),
            0 2px 10px rgba(222, 225, 37, 0.178),
            0 0 30px rgba(231, 203, 24, 0.167);
        }

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .pseudo-particles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: -1;

    @for $i from 1 through $particle-count {
      $random-delay: random(1) * 0.01s;
      $random-size: random(1) * $particle-size;
      $random-opacity: random(1) * 0.5;

      &:after {
        content: "";
        position: absolute;
        width: $random-size;
        height: $random-size;
        background-color: $particle-color;
        border-radius: 50%;
        opacity: $random-opacity;
        animation: sprinkle
          $particle-animation-duration
          $random-delay
          infinite
          linear;
        top: random(100) * 1%;
        left: random(100) * 1%;
        pointer-events: none;
      }
    }
  }
}
