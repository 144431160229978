$primary-color: #fb9400;
$secondary-color: #1d5cdd;
$highlight-color: lighten($primary-color, 30%);
$button-color: darken($secondary-color, 10%);
$background-color: #f8f9fa;
$text-color: #333;

.food-container {
  display: flex;
  width: 100%;
  height: 100vh;
  font-family: Arial, sans-serif;

  @media screen and (max-width: 768px)
  {
    flex-direction: column;
    
  }

  .sidebar {
    width: 200px;
    background-color: $background-color;
    padding: 20px;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);

    .menu-item {
      display: flex;
      align-items: center;
      margin: 10px 0;
      padding: 10px;
      cursor: pointer;
      color: $text-color;
      text-decoration: none;

      svg {
        margin-right: 10px;
      }

      &.active,
      &:hover {
        background-color: lighten($background-color, 10%);
      }

      select {
        margin-left: 10px;
        padding: 5px;
        width: 100%;
        font-size: 1em;
        border: 1px solid #ccc;
        border-radius: 5px;
      }
    }
  }

}


.nearby-restaurants {

  @media screen and (max-width: 768px){
    flex-direction: column;
    
  }

  h2{
    color: $text-color;
    text-transform: capitalize;
  }

  .categories{
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
    width: 100%;

    @media screen and (max-width: 768px){
      flex-direction: column;
      gap: 10px;
      
    }

    select{
      padding: 8px 5px;
      width: 80%;
      color: #fff;
      font-weight: 600;
      font-size: 1em;
      border: none;
      box-shadow: #1d5cdd24 0px 10px 10px;
      border-radius: 5px;
      background: #1d5cdd;
    }
  }

  .restaurant-cards{
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    @media screen and (max-width: 768px){
      flex-direction: column;
      gap: 10px;
      
    }

    .restaurant-card{
      background-color: white;
      padding: 10px;
      border: 1px solid #dee2e6;
      border-radius: 5px;
      text-align: center;
      width: calc(25% - 20px);
      box-sizing: border-box;
      position: relative;
      @media screen and (max-width: 768px){
        width: 100%;
        margin-bottom: 10%;
        
      }

      &:hover{
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        cursor: pointer;
      }

      img{
        width: 100%;
        height: 160px;
        object-fit: cover;
        object-position: center;
        border-radius: 5px;
      }

      h3{
        margin: 10px 0;
        color: $text-color;
      }

      .type{
        position: absolute;
        top: 10px;
        right: 10px;
        background-color: #011131c6;
        color: white;
        padding: 5px;
        border-radius: 0px 0px 0px 5px;
      }

      .vendor-and-cat{
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-top: 10px;
        color: $text-color;
        font-weight: bold;
        font-size: 11px;
        i{
          padding: 4px;
        }
        .cat{
          color: #1d5cdd;
        }
        .vendor{
          color: #242424;
        }

      }

      .price-and-ratting{
        display: flex;
        justify-content: space-around;
        align-items: center;

        margin-top: 10px;
        color: $text-color;
        font-weight: bold;
        .price{
          color: green;
          font-size: 19px;
        }
        .rating{
          color: orange;
        }
      }

      .cta-buttons{
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
        gap:10px;
        button{
          background-color: $button-color;
          color: white;
          padding: 10px;
          border: none;
          border-radius: 5px;
          width: 100%;
          box-shadow: #1d5cdd44 0px 10px 10px;

          &:nth-child(2){
            background-color: green;
            box-shadow: #02890267 0px 10px 10px;
            &:hover{
              background-color: darken(green, 10%);
              box-shadow: #11c111ac 0px 10px 10px;
            }
          }

          i{
            margin-right: 4px;
          }
          cursor: pointer;
          &:hover{
            background-color: darken($button-color, 10%);
          }
          margin-top: 5px;
        }
      }

      

      img{
        width: 100%;
        height: 160px;
        object-fit: cover;
        object-position: center;
        border-radius: 5px;
      }

      .price{
        display: block;
        margin-top: 10px;
        font-weight: bold;
        color: $text-color;
      }
    }
  }



  .filters{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    select{
      padding: 8px 5px;
      color: #fff;
      font-weight: 600;
      font-size: 1em;
      border: none;
      box-shadow: #1d5cdd24 0px 10px 10px;
      border-radius: 5px;
      background: #1d5cdd;
    }
    button{
      background-color: $button-color;
      color: white;
      padding: 10px;
      width: 200px;
      text-transform: capitalize;
      font-weight: 600;
      border: none;
      border-radius: 5px;
      box-shadow: #1d5cdd44 0px 10px 10px;
      cursor: pointer;

      &:nth-child(2){
        background-color: #039603;
        box-shadow: #02890267 0px 10px 10px;
        &:hover{
          background-color: darken(green, 10%);
          box-shadow: #11c111ac 0px 10px 10px;
        }
      }

      &:hover {
        background-color: darken($button-color, 10%);
      }
    }
    input[type="range"]{
      width: 10%;
      margin: 0 10px;
    }
  }

  h2 {
    color: $text-color;
  }

  ul {
    list-style: none;
    padding: 0;

    li {
      margin-bottom: 20px;

      h3 {
        margin: 0;
        color: $text-color;
      }

      p {
        margin: 5px 0 0;
        color: $text-color;
      }
    }
  }
}

.main-content {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  background-color: #fff2d3;

  @media screen and (max-width: 768px){
    padding: 10px;

    
  }

  .categories {
    display: flex;
    justify-content: space-around;
    margin: 20px 0;


    .category {
      background-color: $secondary-color;
      color: white;
      padding: 10px;
      border-radius: 5px;
      cursor: pointer;
    }
  }

  .most-popular {
    h3 {
      margin-bottom: 20px;
      color: $text-color;
    }
    h2 {
      padding: 10px 0;
      color: #1d5cdd;
    }

    .dropdown-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      margin: 20px 0;
      span {
        font-weight: 600;
      }

      select {
        padding: 8px 5px;
        color: #fff;
        font-weight: 600;
        font-size: 1em;
        border: none;
        box-shadow: #1d5cdd24 0px 10px 10px;
        border-radius: 5px;
        background: #1d5cdd;
        width: 100%;
      }
    }

    .university-cards {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;

      .card {
        background-color: white;
        padding: 10px;
        border: 1px solid #dee2e6;
        border-radius: 5px;
        text-align: center;
        flex: 1 1 calc(33.333% - 20px);
        box-sizing: border-box;

        &:hover {
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
          cursor: pointer;
        }

        button {
          background-color: $button-color;
          color: white;
          padding: 10px;
          border: none;
          border-radius: 5px;
          width: 100%;
          box-shadow: #1d5cdd44 0px 10px 10px;
          cursor: pointer;
          &:hover {
            background-color: darken($button-color, 10%);
          }
          margin-top: 5px;
        }

        img {
          width: 100%;
          height: 160px;
          object-fit: cover;
          object-position: center;
          border-radius: 5px;
        }

        .price {
          display: block;
          margin-top: 10px;
          font-weight: bold;
          color: #000;
        }
      }
    }

    .items {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      @media screen and (max-width: 768px){
        flex-direction: column;
      }

      .item {
        background-color: white;
        padding: 10px;
        border: 1px solid #dee2e6;
        border-radius: 5px;
        text-align: center;
        width: calc(33.333% - 20px);
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        @media screen and (max-width: 768px){
          width: 100%;
          margin-bottom: 10%;
        }

        span {
          &.vendor {
            color: #1d5cdd;
            font-weight: 600;
            i {
              color: #1d5cdd;
            }
          }
        }

        button {
          background-color: $button-color;
          color: white;
          padding: 10px;
          border: none;
          border-radius: 5px;
          width: 100%;
          box-shadow: #1d5cdd44 0px 10px 10px;
          cursor: pointer;

          
          &:hover {
            background-color: darken($button-color, 10%);
          }
          margin-top: 5px;
        }

        i{
          color: #e7e704;
        }

        img {
          max-width: 100%;
          height: 200px;
          object-fit: cover;
          object-position: center;
          border-radius: 5px;
        }

        .price {
          display: block;
          margin-top: 10px;
          font-weight: bold;
          color: $text-color;
        }
      }
    }
  }
}


.banner {
  background-color: $highlight-color;
  // background-image: radial-gradient(direction, color-stop1, color-stop2, ...), url('url');
  // background-image: url('../assets/foodbanner2.jpg');

  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.663)),
    url("../assets/foodbanner.jpg");
  background-size: cover;
  background-position: bottom right;
  padding: 20px;
  margin: 20px 0;
  text-align: center;
  border-radius: 5px;
  height: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;

  @media screen and (max-width: 768px){
    height: 100px;

    
  }

  .banner-text {
    color: #fff;
    h2 {
      margin: 0;
      color: white !important;
      font-size: 2rem;
      @media screen and (max-width: 768px){
        font-size: 1.5rem;
        
      }
    }
    p {
      margin: 5px 0 0;
      color: white;
      @media screen and (max-width: 768px){
        font-size: 0.8rem;
        
      }
    }
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .greeting {
    font-size: 1.2em;
    color: $text-color;
    font-weight: bold;
  }

  .search-bar {
    padding: 5px;
    font-size: 1em;
    border: 1px solid #ccc;
    border-radius: 5px;
    &:hover {
      border: 1px solid $button-color;
    }
  }
}

.order-summary {
  width: 20%;
  background-color: #eaeef1;
  background-image: url("../assets/foodbg.jpg");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: bottom left;
  padding: 20px;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);

  @media screen and (max-width: 768px) {
    display: none;
    
  }

  h3 {
    margin-bottom: 20px;
    color: $text-color;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  p {
    margin: 5px 0 0;
    color: $text-color;
  }

  i {
    color: white;
    background-color: $button-color;
    padding: 5px;
    cursor: pointer;
    border-radius: 5%;
  }

  button {
    background-color: $button-color;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    width: 100%;
    box-shadow: #1d5cdd44 0px 10px 10px;
    cursor: pointer;
    &:hover {
      background-color: darken($button-color, 10%);
    }
    margin-top: 20px;
  }
}
hr {
  border: 1px solid #0000002e;
  margin: 20px 0;
}
