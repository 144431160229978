// Variables
$COLOR_PRIMARY_DARK: var(--COLOR_PRIMARY_DARK);
$COLOR_BLACK: var(--COLOR_BLACK);
$COLOR_DARK: var(--COLOR_DARK);
$COLOR_WHITE: var(--COLOR_WHITE);

// Main styles
.media__item {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 0.2rem;
  margin: 0.5rem 1rem;
  iframe {
    border: none;
    border-radius: 5px;
    background: #1d1d1d;
    padding: 5px;
    box-shadow: inset -5px 5px 4px #171717, inset 5px -5px 4px #232323;
  }

  &.you {
    align-items: flex-end;

    .item__meta__data {
      flex-direction: row-reverse;
    }

    .content {
      p {
        // background-color: #fff;
        color: #f1f1f1;
        border-radius: 6px 0rem 6px 6px;
        padding: 6px 12px;
        margin-top: 5px;
        margin-right: 20px;
        background: #0044d0;
        box-shadow: 8px 8px 16px #0b0b0b, -8px -8px 16px #212121;
      }
    }
  }

  &.other {
    align-items: flex-start;

    .content {
      p {
        // background-color: #3c77fe;
        color: #fff;
        border-radius: 0px 6px 6px 6px;
        margin-top: 5px;
        margin-left: 20px;
        padding: 6px 12px;
        background: #161616;
        box-shadow: 8px 8px 16px #0b0b0b, -8px -8px 16px #212121;
      }
    }
  }

  .item__meta__data {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
  }

  .item__author {
    font-weight: 600;
    font-size: 0.8rem;
    margin: 0rem 0.25rem;
    letter-spacing: 1px;
  }

  .item__time {
    font-size: 0.7rem;
    letter-spacing: 1px;
  }

  .item__content {
    max-width: 30rem;
    padding: 6px 10px;
    font-size: 14px;
    word-wrap: break-word;
    white-space: pre-wrap;
    font-size: 'Open Sans", sans-serif';
  }
}

// Media query breakpoints
@media (max-width: 700px) {
  .media__item {
    .item__author {
      font-weight: 300;
    }

    .item__time {
      font-weight: 100;
    }

    .item__content {
      max-width: 25rem;
      padding: 0.4rem 0.65rem;
      font-size: 0.95rem;
    }
  }
}

@media (max-width: 600px) {
  .media__item {
    .item__author {
      font-weight: 100;
    }

    .item__content {
      max-width: 18rem;
    }
  }
}

@media (max-width: 440px) {
  .media__item {
    .item__content {
      max-width: 15rem;
    }
  }
}
