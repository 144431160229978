.manageAndApproveNews {
    width: calc(100% - 5px);
    padding: 4px 2px;
  
    h3 {
      padding: 8px;
    }
  
    .scrollnews {
      height: calc(100vh - 80px);
      width: calc(100% - 10px);
      overflow-y: scroll;
  
      .newsCard {
        transform-origin: top;
        transition: transform 0.3s ease;
        animation-duration: 0.5s;
        animation-name: fadeIn;
        &:not(:last-child) {
          margin-bottom: 10px;
        }
  
        &.slide-right {
          animation: slideRight 0.5s ease;
        }
  
        &.slide-left {
          animation: slideLeft 0.5s ease;
        }
  
        &.slide-bottom {
          animation: slideBottom 0.5s ease;
        }
      }
  
      .slide-right {
        animation: slideRight 0.5s ease;
      }
  
      @keyframes slideRight {
        from {
          transform: translateX(100%);
        }
        to {
          transform: translateX(0);
        }
      }
  
      .slide-left {
        animation: slideLeft 0.5s ease;
      }
  
      @keyframes slideLeft {
        from {
          transform: translateX(-100%);
        }
        to {
          transform: translateX(0);
        }
      }
  
      .slide-bottom {
        animation: slideBottom 0.5s ease;
      }
  
      @keyframes slideBottom {
        from {
          transform: translateY(100%);
        }
        to {
          transform: translateY(0);
        }
      }
    }
  
    .newsCard {
      display: flex;
      align-items: center;
      gap: 8px;
      flex-wrap: wrap;
      padding: 8px 4px;
  
      .card {
        display: flex;
        background-color: #ffffff;
        align-items: center;
        border-radius: 22px;
        width: 32%;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  
        .newsleft {
          padding: 6px;
          border-radius: 22px;
          background-color: #fff;
  
          img {
            width: 100px;
            height: 200px;
            object-fit: cover;
            object-position: center;
            border-radius: 22px;
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
          }
        }
  
        .newsright {
          padding: 8px;
          height: 200px;
          position: relative;
  
          .author-time {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding: 0;
            gap: 10px;
  
            .newsauthor {
              font-size: 10px;
              background-color: #f7a72f;
              padding: 1px 4px;
              border-radius: 2px;
            }
  
            .newstime {
              font-size: 8px;
              font-weight: 600;
              font-family: Verdana, Geneva, Tahoma, sans-serif;
            }
          }
  
          .newshead {
            color: #286eff;
            word-wrap: break-word;
          }
  
          .newsContent {
            color: #464646;
            word-wrap: break-word;
            height: 100px;
            overflow-y: scroll;
            font-size: 13px;
          }
  
          .newstype {
            font-size: 14px;
            font-weight: 600;
            font-family: Verdana, Geneva, Tahoma, sans-serif;
            padding: 1px 4px;
            border-radius: 2px;
            background-color: #286eff;
            color: #fff;
            width: fit-content;
            transition: all 0.3s ease;
            animation: blink 2s infinite;
            margin-bottom: 1%;
  
            &.flash {
              animation: flashShadow 0.3s infinite;
            }
          }
  
          .buttons {
            position: absolute;
            bottom: 0;
            right: 0;
            display: flex;
            gap: 4px;
            padding: 4px;
  
            button {
              padding: 4px 6px;
              border-radius: 8px;
              border: none;
              outline: none;
              background-color: #286eff;
              color: #fff;
              font-weight: 500;
              font-size: 12px;
              margin: 2px;
              cursor: pointer;
              box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
              transition: all 0.3s ease;
  
              &:hover {
                background-color: #286effc7;
              }
            }
  
            .delete {
              background-color: #d24141;
              &:hover {
                background-color: #d24141c7;
              }
            }
          }
        }
      }
    }
  }
  
  @keyframes blink {
    0% {
      opacity: 0.5;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.5;
    }
  }
  
  @keyframes flash {
    0% {
      box-shadow: 0 0 5px #286eff;
    }
    50% {
      box-shadow: 0 0 20px #286eff;
    }
    100% {
      box-shadow: 0 0 5px #286eff;
    }
  }
  @keyframes flashShadow {
    0% {
      box-shadow: 0 0 5px #f7a72f;
    }
    50% {
      box-shadow: 0 0 20px #f7a72f;
    }
    100% {
      box-shadow: 0 0 5px #f7a72f;
    }
  }