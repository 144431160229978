.home {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .container {
    height: 100vh;
    width: 90%;
    display: flex;
    overflow: hidden;

    .chat {
      width: 100%;
      // CSS
      .chatInfo {
        height: 50px;
        background-color: #f3f6ff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #000;
        border-bottom: #42424228 1px solid;
        // box-shadow: rgba(0, 0, 0, 0.082) 1.95px 1.95px 2.6px;
        z-index: 2;
        position: relative;
        margin: 0;
        padding: 10px;
        .back {
          color: #fff;
          font-size: 24px;
          padding: 10px;

          display: none;
          @media screen and (max-width: 480px) {
            display: block;
          }
        }
        .blur {
          position: absolute;
          z-index: -1;
          width: 100%;
          height: 51px;
          top: 0;
          padding: 10px;
          background-size: 105%;
          background-repeat: no-repeat;
          background-position: center;
          background-position-y: 30%;
          @media screen and (max-width: 480px) {
            background-position: left;
          }
        }

        .userProfile {
          display: flex;
          align-items: center;
          gap: 10px;

          img {
            height: 40px;
            width: 40px;
            border-radius: 50%;
            object-fit: cover;
            border: #286eff 3px solid;
            padding: 2px;
            cursor: pointer;

            &:hover {
              border: #d24141 3px solid;
            }
          }

          .chatName {
            font-family: "open sans", sans-serif;
            font-weight: 700;
            color: #ffffff;
            text-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
              rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
            font-size: 24px;
          }
        }
      }

      .chatIcons {
        display: flex;
        gap: 20px;
        color: #fff;
        font-size: 22px;
        animation: changeColor 4s infinite;
        i {
          &:hover {
            color: #286eff;
            cursor: pointer;
          }
        }
      }
      @keyframes changeColor {
        0%,
        100% {
          color: #fff;
        }
        50% {
          color: #286eff; // Change to a different color at 50% of the animation
        }
      }
    }
  }
}

// Media Queries
@media screen and (max-width: 1200px) {
  .home .container {
    height: 100vh;
    width: 100%;
    .sidebarRight,
    .sidebar {
      width: 30%;
    }
    .sidebar .navbar .logo {
      display: none;
    }
    .chat {
      width: 70%;
    }
  }
}

@media screen and (max-width: 768px) {
  .home .container {
    height: 100%;
    width: 100%;
    .sidebarRight {
      display: none;
    }
    .sidebar {
      width: 40%;
    }
    .chat {
      width: 60%;
      height: calc(100vh - 40px);
    }
  }
  .sidebarRight {
    display: none;
  }
}
