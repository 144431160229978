.support {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-image: url("../img/login.jpg");
    background-size: cover;
    background-position: center;
  
    .support-container {
      background-color: #fff;
      width: 30%;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      border-radius: 8px;
      animation: popup 0.2s ease-out forwards;
  
      @media screen and (max-width: 768px) {
        width: 60%;
      }
  
      @media screen and (max-width: 480px) {
        width: 90%;
      }
  
      .title {
        width: 100%;
        background-color: #286eff;
        padding: 10px 0px;
        border-radius: 8px 8px 0px 0px;
        text-align: center;
        font-size: 20px;
        color: #fff;
  
        p {
          font-size: 12px;
          padding-top: 5px;
        }
      }
      .form {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        animation: popup 0.2s ease-out forwards;
    
        form {
          padding: 10px;
          width: 100%;
    
          .form-group {
            display: flex;
            flex-direction: column;
            margin: 20px 0px;
    
            &:nth-child(1) {
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
            }
    
            label {
              font-size: 13px;
              color: #3b3b3b;
              font-weight: 600;
              animation: slideIn 0.5s ease-out forwards;
            }
          
            select{
              width: calc(100% - 110px);
            }
  
            select,
            input,
            textarea {
              padding: 10px;
              border: 1px solid #5e5e5e6b;
              outline: none;
              animation: slideIn 0.5s ease-out forwards;
    
              &:focus {
                border-bottom: 3px solid #286eff;
              }
            }
    
            textarea {
              resize: none;
              font-size: 14px;
            }
          }
    
          button {
            width: 100%;
            background-color: #286eff;
            padding: 10px;
            border: none;
            color: #fff;
            font-size: 600;
    
            &:hover {
              cursor: pointer;
              background-color: #1142a4;
            }
          }
  
          .message{
            font-size: 13px;
            margin: 0.5rem;
            color: green;
            font-weight: 600;
            text-align: center;
          }
          .faq{
            text-align: center;
            font-size: 13px;
            margin: 0.5rem;
            color: #286eff;
            font-weight: 600;
            
          }
          a{
            text-decoration: none;
            color: rgb(220, 143, 0);
            transition-duration: 0.4s;
            &:hover{
              color: #1142a4;
            }
          }
        }
      }
    }
  
  }
  
  .message-appear {
    animation: messageAppear 0.5s ease-out forwards;
  }
  
  .message-disappear {
    animation: messageDisappear 0.5s ease-out forwards;
  }
  
  @keyframes popup {
    0% {
      transform: scale(0.8);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  
  @keyframes slideIn {
    0% {
      transform: translateY(20px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  @keyframes messageAppear {
    0% {
      opacity: 0;
      transform: translateY(-20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes messageDisappear {
    0% {
      opacity: 1;
      transform: translateY(0);
    }
    100% {
      opacity: 0;
      transform: translateY(-20px);
    }
  }
  