.messages {
    .no-chat {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
  
      .no-chat-inner {
        text-align: center;
        // animation: fadeIn 0.5s ease-in-out, bounce 2s infinite, fadeOut 2s 2s; // Multiple animations
  
        img {
          width: 20%;
          max-width: 250px; // Limit the max width of the image
          animation: bounceUpDown 2s infinite; // Bouncing animation for the image
        }
  
        .quote {
          font-size: 15px;
          font-weight: 600;
          color: #424242;
          margin-top: 20px; // Add some space between the image and the quote
          // animation: changeColorChatBox 4s infinite; // Color change animation for the quote text
        }
        .no-chat{
          display: block;
          p{
            font-size: 12px;
            color: #727272;
            &:nth-child(1), &:nth-child(2){
              font-size: 14px;
              color: #222222;
            }
            &:nth-child(3){
              position: absolute;
              bottom: 3%;
              @media screen and (max-width: 480px) {
                display: none;
              }
            }

          }
        }
       
      }
    }
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(-10px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes bounce {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-20px); // Adjust the bouncing height as needed
    }
  }
  
  @keyframes bounceUpDown {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px); // Adjust the bouncing height as needed
    }
  }
  
  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  
 
  @keyframes changeColorChatBox {
    0%, 100% {
      color: #424242;
    }
    50% {
      color: #286EFF; // Change to a different color at 50% of the animation
    }
  }