.ProfileContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;

  .details {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    width: calc(100% - 65px);
    padding: 8px 15px;
    gap: 8px;

    @media screen and (max-width: 480px) {
      justify-content: center;
      gap: 10px;
      margin-bottom: 30px;
    }

    @media screen and (max-width: 768px) {
      justify-content: center;
      gap: 10px;
      margin-bottom: 30px;
    }

    i {
      color: #286eff;
    }
    p {
      font-weight: 600;
      &:nth-child(3) {
        color: #286eff;
      }
    }
  }

  .profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    width: 100%;

    .profilePic {
      label {
        font-size: 1.2rem;
        font-weight: 500;
        position: relative;
        img {
          width: 150px;
          height: 150px;
          border-radius: 50%;
          box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
          object-fit: cover;
          object-position: top;
          z-index: 1;

          @media screen and (max-width: 480px) {
            width: 100px;
            height: 100px;
          }

          @media screen and (max-width: 768px) {
            width: 100px;
            height: 100px;
          }

          &:hover {
            cursor: pointer;
          }
        }
        i {
          position: absolute;
          top: 0;
          right: 0;
          color: #1e4ed7;
          font-size: 22px;
          z-index: 100;
          &:hover {
            cursor: pointer;
          }
        }
      }
      input {
        display: none;
      }
    }

    .basicInfo {
      width: calc(100% - 65px);
      padding: 8px 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      gap: 8px;

      label {
        width: 45%;
        display: flex;
        flex-direction: column;
        align-items: start;
        flex-wrap: wrap;

        @media screen and (max-width: 480px) {
          width: 100%;
        }

        &.bio {
          width: calc(100% - 65px);
          @media screen and (max-width: 480px) {
            width: 100%;
          }

          @media screen and (max-width: 768px) {
            width: 100%;
          }
          textarea {
            width: calc(100% - 20px);
          }
        }

        input,
        select,
        textarea {
          width: calc(100% - 20px);
          border: none;
          padding: 10px 8px;
          border-radius: 4px;
          background-color: #f1f1f1;
          border-bottom: #2870ff00 2px solid;
          transition-duration: 0.4s;
          margin-bottom: 8px;

        

          &::placeholder {
            &::after {
              content: "Required";
              color: red;
            }
          }

          &:focus {
            outline: none;
            border-bottom: #286eff 2px solid;
          }
        }
        textarea {
          height: 80px;
          font-size: 14px;
        }
        select {
          width: calc(100% - 5px);
        }

        p {
          font-size: 0.8rem;
          font-weight: 500;
          margin-bottom: 5px;
          margin-left: 2px;
        }
      }
    }

    .submit {
      display: flex;
      width: calc(100% - 65px);
      padding: 8px 0;
      align-items: center;
      justify-content: center;

      button {
        width: fit-content;
        padding: 8px 15px;
        border-radius: 8px;
        width: 200px;
        border: none;
        font-size: 1rem;
        font-weight: 500;
        color: #fff;
        background-color: #286eff;
        transition-duration: 0.4s;
        &:hover {
          cursor: pointer;
          background-color: #1e4ed7;
        }
      }
    }
  }
}
