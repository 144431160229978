.SDashboard {
  .hero {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 5%;
    height: 90vh;

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }

    // mobile
    @media screen and (max-width: 480px) {
      flex-direction: column;
    }

    .left {
      width: 25%;
      padding: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      gap: 25px;

      @media screen and (max-width: 768px) {
        width: 100%;
        display: none;
      }

      @media screen and (max-width: 480px) {
        width: 100%;
        display: none;
      }

      h2 {
        font-size: 1.5rem;
        text-align: center;
        font-weight: 600;
        color: #286eff;
        margin: 0;
        padding: 0;
        i {
          font-size: 3rem;
          color: #00A2FF;
          width: fit-content;
        }
      }
      p {
        font-size: 1rem;
        font-weight: 400;
        color: #242424;
        
        margin: 0;
        padding: 0;
        margin: 3%;
      }
    }
    .center {
      width: 40%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      gap: 15px;

      @media screen and (max-width: 768px) {
        width: 100%;
      }

      @media screen and (max-width: 480px) {
        width: 100%;
        
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 1;
        pointer-events: none;
      }

      button {
        padding: 10px 20px;
        border: none;
        border-radius: 5px;
        background-color: #286eff;
        color: #fff;
        font-size: 1rem;
        font-weight: 600;
        box-shadow: 0px 10px 10px #2870ff61;

        transition: all 0.3s ease;
        &:hover {
          background-color: #1e4fd9;
          cursor: pointer;
          box-shadow: none;
        }
      }
      p {
        font-size: 0.7rem;
        font-weight: 400;
        color: #555555;
        margin-top: 2%;
        padding: 0;
      }

      i {
        font-size: 2rem;
        color: #555555;
        text-shadow: 0px 10px 10px #286eff;
        animation: floatUpAndDown 5s infinite;
        width: fit-content;
        &:hover {
          cursor: pointer;
        }
      }
    }
    .right {
      width: 25%;
      padding: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      gap: 25px;

      @media screen and (max-width: 768px) {
        width: 100%;
        display: none;
      }

      @media screen and (max-width: 480px) {
        width: 100%;
        display: none;
      }
      h2 {
        font-size: 1.5rem;
        text-align: center;
        font-weight: 600;
        color: #286eff;
        margin: 0;
        padding: 0;
        i {
          font-size: 3rem;
          color: #00A2FF;
          width: fit-content;
        }
      }
      p {
        font-size: 1rem;
        font-weight: 400;
        color: #242424;
        margin: 0;
        padding: 0;
        margin: 3%;
      }
    }
  }
}

@keyframes floatUpAndDown {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

