.DMagic {
  display: flex;
  flex-direction: column;
  width: 80%;
  padding: 10px;
  margin-top: 10%;

  @media screen and (max-width: 768px) {
    width: 100%;
  }

  @media screen and (max-width: 480px) {
    width: 100%;
  }

  .heading {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    position: relative;

    p {
      font-size: 16px;
      background: linear-gradient(75deg, #eb3fb3, #e125cc, #c718e7);
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent;
      font-weight: 800;
      margin-bottom: 10px;
      line-height: 1.4rem;
    }
    .message{
        position: absolute;
        font-size: 11px;
        right: 4.5%;
        top: -22%;
        font-weight: 400;
        font-style: italic;
        color: #242424;
    }

    .bar {
      width: 55%;
      height: 3px;
      background: linear-gradient(75deg, #eb3fb3, #e125cc, #c718e7);
      margin: 0 10px;
      margin-bottom: 8px;
    }
  }

  .Mform{
    display: flex;
    width: 100%;
    gap: 10px;
    input{
        width: 65%;
        padding: 10px 10px;
        border: none;
        border-radius: 8px;
        margin-bottom: 10px;
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
        font-weight: 600;
    }
    .magic-button{
        background: linear-gradient(75deg, #eb3fb3, #e125cc, #c718e7);
        border: none;
        border-radius: 8px;
        width: 120px;
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
        transition-duration: 0.2s;
        color: #fff;
        font-weight: 600;
        display: flex;
        height: 38px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        &:hover {
          background: linear-gradient(75deg, #e125cc, #eb3fb3, #c718e7);
          box-shadow: none;
          cursor: pointer;
        }
    }
  }

}
