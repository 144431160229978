.DatingContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;

  @media screen and (max-width: 480px) {
    height: 60vh;
  }

  .details {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    width: calc(100% - 65px);
    padding: 8px 15px;
    gap: 8px;

    @media screen and (max-width: 480px) {
      justify-content: center;
      gap: 10px;
      margin-bottom: 30px;
    }

    @media screen and (max-width: 768px) {
      justify-content: center;
      gap: 10px;
      margin-bottom: 30px;
    }

    i {
      color: #286eff;
    }
    p {
      font-weight: 600;
      &:nth-child(3) {
        color: #286eff;
      }
    }
  }

  .datingProfile {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;

    label {
      font-size: 1.2rem;
      font-weight: 500;
      position: relative;
      img {
        width: 250px;
        height: 300px;
        border-radius: 8px;
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
        object-fit: cover;
        object-position: center top;
        z-index: 1;

        @media screen and (max-width: 480px) {
          width: 110px;
          height: 110px;
        }

        &:hover {
          cursor: pointer;
        }
      }
      i {
        position: absolute;
        top: 0;
        right: 0;
        color: #1e4ed7;
        font-size: 22px;
        z-index: 100;
        &:hover {
          cursor: pointer;
        }
      }
    }
    input {
      display: none;
    }
  }

  .bio {
    width: calc(100% - 90px);
    padding: 8px 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 1%;

    textarea {
      width: calc(100%);
      height: 80px;
      border-radius: 4px;
      padding: 8px;
      border: none;
      border: #286eff 1px solid;
      resize: none;
    }
  }

  .controls {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin: 3%;

    button {
      padding: 8px 15px;
      width: 200px;
      border-radius: 8px;
      border: none;
      font-size: 1.2rem;
      font-weight: 500;
      color: #fff;
      text-transform: uppercase;
      background-color: #286eff;
      box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
      transition: all 0.3s ease;

      @media screen and (max-width: 480px) {
        width: 100px;
        font-size: 1rem;
      }

      @media screen and (max-width: 768px) {
        width: 100px;
        font-size: 1rem;
      }

      &:first-child {
        background-color: #ff4d4d;
      }

      &:hover {
        cursor: pointer;
        background-color: #1e4ed7;
      }
    }
  }
  .status_section {
    min-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      font-size: 1rem;
      font-weight: 400;
      color: #07c707;
      background-color: #fff;
      padding: 8px 15px;
      width: fit-content;
      border-radius: 4px;
      box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
      display: none;
    }
  }
}
