@import url("https://fonts.googleapis.com/css2?family=Dai+Banna+SIL:wght@900;600&family=Gochi+Hand&family=Handlee&family=Noticia+Text&family=Noto+Serif:wght@500&family=Patrick+Hand&display=swap");

$animationSpeed: 40s;

.oldNewsage {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f7cf00;
  background-image: url("../img/news.jpg");
  background-size: cover;
  background-position: center;
  background-attachment: scroll;
  padding: 40px 6px;
  padding-bottom: 0px;

  .news-full-body {
    width: 80%;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
      rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    height: calc(100vh - 40px);

    .news-paper {
      background-color: #f9f5f2e9;
      position: relative;
      height: calc(100vh - 41px);
      z-index: 1;
      transform: rotate(-1deg);
      transition-duration: 0.3s;
      //   img {
      //     filter: grayscale(100%);
      //     transition: filter 0.3s ease-in-out;
      //   }

      //   &:hover img {
      //     filter: grayscale(0%);
      //   }

      &:hover {
        transform: rotate(0deg);
        animation: bounce 0.5s ease-in-out;
      }

      .news-paper-psudo {
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        position: absolute;
        top: -2%;
        left: -1%;
        width: 100%;
        height: calc(100vh - 27px);
        background-color: #fcfdfc;
        z-index: 10;
      }

      .news-paper-title {
        font-size: 30px;
        font-weight: bold;
        margin-bottom: 10px;
        text-align: center;
        position: relative;
        padding: 10px 0px 0px 0px;
        animation: fadeInDown 0.5s ease-in-out;

        h3 {
          font-family: "Noticia Text", serif;
          padding-bottom: 6px;
        }

        .date {
          font-size: 12px;
          font-family: Verdana, Geneva, Tahoma, sans-serif;
          color: #414141c4;
          padding-bottom: 10px;
        }

        .filter {
          display: flex;
          justify-content: center;
          gap: 10px;
          flex-wrap: wrap;
          padding-bottom: 8px;

          button {
            background-color: transparent;
            border: none;
            font-weight: 600;
            color: #242424;
            font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
              "Lucida Sans", Arial, sans-serif;
            transition-duration: 0.3s;

            &:hover {
              color: #f01e36;
              cursor: pointer;
            }
          }
        }

        .marqueeOuter {
          position: relative;
          padding: 0;

          .trending-tag {
            font-size: 11px;
            position: absolute;
            left: 0;
            padding: 0px 6px;
            margin: 0;
            top: 19%;
            height: calc(100% - 8px);
            background-color: #f01e36;
            display: flex;
            align-items: center;
            color: #fff;
          }

          marquee {
            background-color: #efefef;
            color: #f01e36;
            width: 100%;
            box-shadow: rgba(0, 0, 0, 0.048) 0px 2px 4px 0px inset;

            p {
              font-size: 14px;
              font-weight: 100;
              font-family: Verdana Geneva Tahoma sans-serif;
              padding: 8px;
            }
          }
        }
      }

      .news-content {
        height: calc(100vh - 40px - 82px - 80px);
        overflow-y: scroll;
        //Styling
        .slider {
          background: white;
          height: 100px;
          margin: auto;
          overflow: hidden;
          position: relative;
          width: 1100px;

          &::before,
          &::after {
            background: linear-gradient(
              to right,
              rgba(255, 255, 255, 1) 0%,
              rgba(255, 255, 255, 0.115) 100%
            );
            content: "";
            height: 100px;
            position: absolute;
            width: 200px;
            z-index: 2;
          }

          &::after {
            right: 0;
            top: 0;
            transform: rotateZ(180deg);
          }

          &::before {
            left: 0;
            top: 0;
          }

          .slide-track {
            animation: scroll $animationSpeed linear infinite;
            display: flex;
            width: calc(250px * 14);
          }

          .slide {
            height: 100px;
            width: 250px;
          }
        }

        .news-posts {
          padding: 2px;
          margin: 0;

          .news-Card {
            padding: 20px;
            border-top: #1a1a1a11 1px solid;
            columns: 3 300px;
            column-gap: 1rem;
            &:nth-child(1) {
              border: none;
            }

            @media (max-width: 480px) {
              column-count: 1;
              column-width: 100%;
            }

            @media screen and (min-width: 481px) and (max-width: 768px) {
              column-count: 2;
              column-width: 50%;
            }
            
            .news-card-content {
              width: 100%;
              margin: 8px 0px;
              border-bottom: #1a1a1a1e 1px solid;
              &:last-child {
                border: none;
              }
            
              .top {
                img {
                  width: 100%;
                  object-fit: cover;
                }
              }

              .bottom {
                padding: 8px;

                .author-time {
                  display: flex;
                  justify-content: space-between;
                  padding: 1px 8px;
                  font-size: 10px;

                  .newsauthor {
                    font-weight: 600;
                    background-color: #f7cf00;
                    color: #1a1a1a;
                    padding: 1px 5px;
                  }

                  .newstime {
                    color: #1a1a1abc;
                  }
                }

                .newshead {
                  font-family: "Noto Serif", serif;
                }

                .newsContent {
                  font-size: 12px;
                  padding: 10px 0px;
                  word-wrap: break-word;
                  text-align: justify;
                }

                .newstype {
                  background-color: #f01e36;
                  color: #fff;
                  padding: 1px 5px;
                  font-size: 12px;
                  width: fit-content;
                }
              }
            }
          }
        }

        // Animation
        @keyframes bounce {
          0%,
          100% {
            transform: translateY(0);
          }
          50% {
            transform: translateY(-10px);
          }
        }

        @keyframes fadeInDown {
          0% {
            opacity: 0;
            transform: translateY(-20px);
          }
          100% {
            opacity: 1;
            transform: translateY(0);
          }
        }

        @keyframes fadeInUp {
          0% {
            opacity: 0;
            transform: translateY(20px);
          }
          100% {
            opacity: 1;
            transform: translateY(0);
          }
        }

        @keyframes fadeInRight {
          0% {
            opacity: 0;
            transform: translateX(-20px);
          }
          100% {
            opacity: 1;
            transform: translateX(0);
          }
        }

        @keyframes fadeInLeft {
          0% {
            opacity: 0;
            transform: translateX(20px);
          }
          100% {
            opacity: 1;
            transform: translateX(0);
          }
        }

        @keyframes fadeIn {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }

        @keyframes slideInRight {
          0% {
            opacity: 0;
            transform: translateX(50px);
          }
          100% {
            opacity: 1;
            transform: translateX(0);
          }
        }

        @keyframes slideInLeft {
          0% {
            opacity: 0;
            transform: translateX(-50px);
          }
          100% {
            opacity: 1;
            transform: translateX(0);
          }
        }

        @keyframes slideInUp {
          0% {
            opacity: 0;
            transform: translateY(50px);
          }
          100% {
            opacity: 1;
            transform: translateY(0);
          }
        }

        @keyframes scaleIn {
          0% {
            transform: scale(0);
          }
          100% {
            transform: scale(1);
          }
        }

        // Apply additional animations as needed to different elements
        .news-paper-title {
          animation: fadeInUp 0.5s ease-in-out;
        }

        .news-content {
          animation: slideInUp 0.5s ease-in-out;

          .news-card-content {
            animation: fadeIn 0.5s ease-in-out;
          }
        }

        .marqueeOuter {
          animation: fadeIn 0.5s ease-in-out;

          marquee {
            animation: slideInUp 0.5s ease-in-out;
          }
        }

        @keyframes scroll {
          0% {
            transform: translateX(0);
          }
          100% {
            transform: translateX(calc(-250px * 7));
          }
        }
      }
    }
  }
}