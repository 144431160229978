.searchUsers {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  margin: 0;
  .search-and-list {
    background-color: #e0f1fe;
    height: calc(100vh);
    width: 22%;
    h3 {
      font-size: 18px;
      padding: 8px;
    }
    .search {
      padding: 10px;
      display: flex;
      align-items: center;
      gap: 10px;
      input {
        width: 60%;
        padding: 8px 10px;
        border-radius: 5px;
        border: none;
        outline: none;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
      }
      button {
        padding: 8px 10px;
        border-radius: 5px;
        border: none;
        outline: none;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
        background-color: #286eff;
        color: #fff;
        cursor: pointer;
        &:hover {
          background-color: #144ec2;
        }
      }
    }

    .list {
      height: calc(100% - 95px);
      overflow-y: scroll;
      .userData {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        padding: 4px 6px;
        margin: 6px 0px;
        color: #000;
        background-color: #f7f7f7;
        border-left: 6px solid #286eff;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

        &:hover {
          background-color: #144ec2;
          color: #fff;
          box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
            rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
          cursor: pointer;
        }
        .image {
          width: 20%;
          img {
            width: 50px;
            height: 50px;
            object-fit: cover;
            border-radius: 50%;
            object-position: center;
            border: #286eff 3px solid;
          }
        }
        .data {
          width: 80%;
          display: block;
          height: calc(100% - 50px);
        }
      }
    }
  }
  .manage-user {
    width: 78%;
    height: calc(100vh - 40px);
    // background-image: url("../../img/chatbg3.jpg");
    overflow-y: scroll;
    background-color: #f3f6ff;

    padding: 20px;
    .user-data {
      background-color: #fff;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      border-radius: 12px;
      .basic {
        display: flex;
        align-items: center;
        gap: 10px;
        text-align: center;
        height: 150px;
        border-radius: 12px 12px 0px 0px;
        position: relative;
        z-index: 10;
        color: #fff;
        border: 2px solid #36363627;
        background: rgba(0, 0, 0, 0.46);
        backdrop-filter: blur(8.3px);
        -webkit-backdrop-filter: blur(8.3px);
        .background {
          position: absolute;
          height: 150px;
          width: 100%;
          background-size: cover;
          background-position: center;
          border-radius: 12px 12px 0px 0px;
          filter: blur(4px);
          z-index: -1;
        }
        .name-username {
          text-align: left;
          padding: 0px;
          p {
            &:nth-child(4) {
              font-size: 14px;
              color: #e0f1fe98;
            }
          }
          .datejoined {
            font-size: 10px;
            color: #e0f1fe98;
          }
        }

        .image {
          padding: 20px;
          img {
            width: 100px;
            height: 100px;
            border-radius: 4%;
            object-fit: cover;
            object-position: center;
            border: #ffffff9d 3px solid;
          }
        }
      }

      .user-data{
        padding: 10px 20px;
        display: flex;
        gap: 10px;
        flex-wrap: wrap;
        justify-content: start;
        align-items: center;
        padding-bottom: 3%;
        
        h2{
          width: 100%;
        }
        p{
          width: 30%;
          display: flex;
          gap: 10px;
          align-items: center;
          font-size: 16px;
          padding: 4px 6px;
          font-weight: 500;
          color: #363636;
          box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
          span{
            font-size: 14px;
            font-weight: 400;
            color: #363636;
          }
        }

      }

      .admin-action{
        display: flex;
        gap: 10px;
        justify-content: center;
        align-items: center;
        padding: 10px;
        margin-top: 3%;
        button{
          padding: 8px 10px;
          border-radius: 5px;
          border: none;
          outline: none;
          box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
          background-color: #286eff;
          color: #fff;
          cursor: pointer;
          &:hover{
            background-color: #144ec2;
          }
        }
        
      }

      .update-user{
        .basicInfo {
          width: calc(100% - 65px);
          padding: 8px 15px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-wrap: wrap;
          gap: 8px;
    
          label {
            width: 45%;
            display: flex;
            flex-direction: column;
            align-items: start;
            flex-wrap: wrap;
    
            @media screen and (max-width: 480px) {
              width: 100%;
            }
    
            &.bio {
              width: calc(100% - 65px);
              @media screen and (max-width: 480px) {
                width: 100%;
              }
    
              @media screen and (max-width: 768px) {
                width: 100%;
              }
              textarea {
                width: calc(100% - 20px);
              }
            }
    
            input,
            select,
            textarea {
              width: calc(100% - 20px);
              border: none;
              padding: 10px 8px;
              border-radius: 4px;
              background-color: #f1f1f1;
              border-bottom: #2870ff00 2px solid;
              transition-duration: 0.4s;
              margin-bottom: 8px;
    
            
    
              &::placeholder {
                &::after {
                  content: "Required";
                  color: red;
                }
              }
    
              &:focus {
                outline: none;
                border-bottom: #286eff 2px solid;
              }
            }
            textarea {
              height: 80px;
              font-size: 14px;
            }
            select {
              width: calc(100% - 5px);
            }
    
            p {
              font-size: 0.8rem;
              font-weight: 500;
              margin-bottom: 5px;
              margin-left: 2px;
            }
          }
        }
      }

      .submit {
        display: flex;
        width: calc(100% - 65px);
        padding: 8px 0;
        align-items: center;
        justify-content: center;
  
        button {
          width: fit-content;
          padding: 8px 15px;
          border-radius: 8px;
          width: 200px;
          border: none;
          font-size: 1rem;
          font-weight: 500;
          color: #fff;
          background-color: #286eff;
          transition-duration: 0.4s;
          &:hover {
            cursor: pointer;
            background-color: #1e4ed7;
          }
        }
      }
    }
  }
}
