.news-mainpage{
    .NewsSlider-mainpage{
        padding: 10px 20px;
        h2,h3{
            color: #286eff;
        }
        p{
            font-size: 12px;
        }
        li{
            font-size: 13px;
            list-style-type: square;

        }
    }
}