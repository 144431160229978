.DatingSettings {
  width: 30%;
  height: 100vh;
  order: 2;
  position: relative;

  @media screen and (max-width: 768px) {
    width: calc(100% - 40px);
    height: calc(100% - 40px);
    display: none;
  }

  @media screen and (max-width: 480px) {
    width: calc(100% - 40px);
    height: calc(100% - 40px);
    display: none;
  }

  .settings {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;

    @media screen and (max-width: 768px) {
      width: calc(100% - 40px);
      padding: 5px 10px;
    }

    @media screen and (max-width: 480px) {
      width: calc(100% - 40px);
      padding: 5px 10px 10px 20px;
      position: absolute;
      top: -70%;
    }

    h2 {
      font-size: 42px;
      margin-bottom: 10px;
      background: linear-gradient(75deg, #eb3fb3, #e125cc, #c718e7);
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent;

      @media screen and (max-width: 768px) {
        display: none;
      }
      @media screen and (max-width: 480px) {
        display: none;
      }
    }

    p {
      font-size: 13px;
      color: #424242;
      font-weight: 600;
      margin-bottom: 10px;
      line-height: 1.4rem;
      &.tag {
        @media screen and (max-width: 768px) {
          display: none;
        }
        @media screen and (max-width: 480px) {
          display: none;
        }
      }
    }

    .stats-matches {
      display: flex;
      justify-content: center;
      gap: 30px;
      width: 100%;

      button {
        background: linear-gradient(75deg, #eb3fb3, #e125cc, #c718e7);
        border: none;
        border-radius: 8px;
        width: 120px;
        padding: 10px 20px;
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
        transition-duration: 0.2s;
        color: #fff;
        font-weight: 600;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;

        img {
          width: 20px;
        }

        &:hover {
          background: linear-gradient(75deg, #e125cc, #eb3fb3, #c718e7);
          box-shadow: none;
          cursor: pointer;
        }
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      gap: 20px;
      margin-top: 10%;
      width: 100%;
      height: 350px;
      overflow-y: auto;
      align-items: center;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
        rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
      background: rgba(248, 206, 242, 0.04);
      backdrop-filter: blur(2px);
      -webkit-backdrop-filter: blur(2px);
      padding: 10px;
      border-radius: 8px;



      @media screen and (max-width: 768px) {
        margin-top:2%;
      }

      @media screen and (max-width: 480px) {
        margin-top:2%;
        height: 450px;
      }

      .title {
        font-size: 16px;
        font-weight: 700;
        color: #e125cc;
      }

      .card {
        display: flex;
        align-items: center;
        gap: 20px;
        width: 90%;
        border-radius: 8px;
        background-color: #fff;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
          rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;

        img {
          width: 80px;
          height: 80px;
          border-radius: 8px 0 0 8px;
          object-fit: cover;
          object-position: center;
        }
        .details {
          width: 55%;
          display: flex;
          flex-direction: column;
          align-items: start;
          h3 {
            background: linear-gradient(75deg, #eb3fb3, #e125cc, #c718e7);
            -webkit-background-clip: text;
            background-clip: text;
            color: transparent;
          }
          p {
            font-size: 12px;
            color: #424242;
            font-weight: 600;
          }
        }
        .clipboard {
          display: flex;
          gap: 10px;
          padding: 10px;
          flex-direction: row-reverse;

          i {
            opacity: 0.6;
            font-size: 20px;
            color: #242424;
            transition-duration: 0.3s ease-in-out;

            &:hover {
              opacity: 1;
              cursor: pointer;
              background: linear-gradient(75deg, #eb3fb3, #e125cc, #c718e7);
              -webkit-background-clip: text;
              background-clip: text;
              color: transparent;
            }
          }
        }
      }
    }
  }
}
