.add-music {
    .timeline-title {
      box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;
      padding: 0px 0px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .timerunning{
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #E50814;
          padding: 0px 2px;
          color: #fff;
          .timeline {
              font-size: 10px;
              padding: 1px 1px;
              font-weight: 600;
              letter-spacing: 2px;
              color: #fff;
              
            }
      }
      
      .videoTitle {
        font-size: 12px;
        padding: 1px 6px;
        color: #585858;
        font-weight: 600;
        background: #e6f2f9;
      background: linear-gradient(
        90deg,
        #e6f2f9 10%,
        #fadce2 100%
      );
      }
      marquee {
        width: 80%;
      }
    
      animation: appear 0.5s ease-in-out forwards;
      animation-fill-mode: forwards;
    }
  
    .addedby {
      display: none;
      align-items: center;
      justify-content: space-evenly;
      padding: 1px 10px;
     
  
      p {
        font-size: 12px;
      }
  
      button {
        border: none;
        color: #fff;
        font-size: 12px;
        cursor: pointer;
        transition: 0.3s;
  
        &:hover {
          background-color: red;
        }
      }
    
      animation: appear 0.5s ease-in-out forwards;
      animation-fill-mode: forwards;
    }
  }
  
  .ytmusic {
    position: relative;
    width: 100%;
    height: 63px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    z-index: 2;
  
    #ytplayer {
      filter: blur(5px);
      position: absolute;
      width: 100%;
      height: 200px;
    }
  
    .buttons {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 55px;
      width: 100%;
      z-index: 10;
    
  
      .timeline {
        color: #fff;
      }
  
      .controls {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        gap: 5px;
  
        button {
          background-color: transparent;
          border: none;
          color: #ffffff4d;
          font-size: 20px;
          cursor: pointer;
          transition: 0.3s;
  
          &:hover {
              color: #ffffff;
          }
        }
      }
    }
  }
  
  @keyframes appear {
    0% {
      opacity: 0;
      transform: translateY(-10px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }


@keyframes changeColors {
    0% {
        box-shadow: 0px -8px 10px rgba(156, 89, 182, 0.2);
    }
    10% {
        box-shadow: 0px -10px 20px rgba(156, 89, 182, 0.4);
    }
    20% {
        box-shadow: 0px -15px 30px rgba(156, 89, 182, 0.6);
    }
    30% {
        box-shadow: 0px -20px 25px rgba(63, 195, 129, 0.7);
    }
    40% {
        box-shadow: 0px -15px 33px rgba(63, 195, 129, 0.645);
    }
    50% {
        box-shadow: 0px -20px 20px rgba(52, 152, 219, 0.515);
    }
    60% {
        box-shadow: 0px -12px 30px rgba(52, 152, 219, 0.379);
    }
    70% {
        box-shadow: 0px -11px 13px rgba(241, 196, 15, 0.348);
    }
    80% {
        box-shadow: 0px -22px 10px rgba(241, 196, 15, 0.152);
    }
    90% {
        box-shadow: 0px -10px 60px rgba(231, 77, 60, 0.5);
    }
    100% {
        box-shadow: 0px -12px 18px rgba(231, 77, 60, 0.4);
    }
}



@media screen and (max-width: 480px) {
  .add-music{
    display: none;
    visibility: hidden;
    height: 0;
  }
}