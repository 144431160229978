.Confession {
  .top {
    height: 87vh;
   
    width: calc(100%);
    position: relative;
    background-image: url("../img/confessbg.png");
    background-size: cover;
    background-position: center;
    background-position: top;
    overflow-x: hidden;
    @media screen and (max-width: 480px) {
        height: 75vh;
    }

    display: flex;
    justify-content: center;
    align-items: center;
    

    .cat {
      position: absolute;
      bottom: -0.1%;
      right: 0;
      width: 200px;
      z-index: 1;
      animation: moveTheCat 6s infinite alternate 0.6s;
      &:hover{
        opacity: 0;
        cursor: pointer;
      }
    }
    @keyframes moveTheCat {
      0% {
        right: 0%;
      }
      15% {
        right: 5%;
      }
      30% {
        right: 10%;
      }
      45% {
        right: 5%;
      }
      60% {
        right: 10%;
      }
      75% {
        right: 5%;
      }
      90% {
        right: -10%;
      }
      100% {
        right: -30%;
      }
    }
  }
  .bottom {
    height: 13vh;
    @media screen and (max-width: 480px) {
        height: 25vh;
    }
    box-shadow: #13101177 0px 13px 27px 5px, #00000090 0px 8px 16px 12px;
    width: calc(100%);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: pink;
    background-image: url("../img/pinkCard.avif");
    background-size: 200px;

    .Cbutton {
      padding: 2px;
      
      button {
        border-radius: 4px;
        border: none;
        background-color: #fa5974;
        font-size: 12px;
        font-weight: 600;
        color: #fff;
        padding: 10px 28px;
        text-transform: uppercase;
        background-image: url("../img/confessbuttonbg.png");
        background-size: cover;
        &:hover {
          cursor: pointer;
          background-color: #f94260;
        }
      }
    }
    .rules {
      width: 61%;
      z-index: 5;
      @media screen and (max-width: 480px) {
        width: 100%;
    }
      p {
        font-size: 10px;
        color: Red;
        font-weight: 900;
        padding: 5px;
       
        span {
          color: #242424;
          font-weight: normal;
        }
      }
    }
  }
}
