// Variables
$COLOR_LIGHT: var(--COLOR_LIGHT);
$ERROR_COLOR: var(--ERROR_COLOR);
$COLOR_PRIMARY: var(--COLOR_PRIMARY);
$COLOR_DARK: var(--COLOR_DARK);

// Main styles
.newroom__page {
  z-index: 1;
}
.newchatroom {
  background-image: url("../../assets/newroom3.png");
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat;
  @media screen and (max-width: 480px) {
    background-position: top right;
  }

  .bulbflashing{
    position: fixed;
    top: 0%;
    right: 3%;
    width: 300px;
  }
  .newroom__contents {
    z-index: 5;
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    max-width: 30rem;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    align-content: center;
    justify-content: center;
    border-radius: 0.5rem;
    padding: 1rem 2rem;
    gap: 1rem;
    background-color: #fff;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

    .newroom__heading {
      margin-top: 1rem;
      margin-bottom: 1rem;
      color: #424242;
    }

    .newroom__form {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      justify-content: center;
      flex-wrap: nowrap;
      width: 20rem;
      min-height: 15rem;
      label{
        font-size: 13px;
        font-weight: 600;
        padding-left: 5px;
        margin: 5px 0px 0px 0px;
        color: #424242;
      }
      input {
        background-color: #f5ede8;
        border-radius: 0.2rem;
        border: none;
        outline: none;
        padding: 0.5rem 1rem;
        text-align: left;
      }

      select {
        appearance: none;
        border: 0;
        padding: 0.5rem 1rem;
        outline: 0;
        background-color: #E0ECF4;
        color: #000;
        border-radius: 0.25em;
        cursor: pointer;
        &::-ms-expand {
          display: none;
        }
        &:focus {
          outline: none;
        }
        option {
          color: inherit;
          background-color: var(--option-bg);
        }
      }

      .input__error {
        color: $ERROR_COLOR;
        letter-spacing: 1px;
        font-weight: 600;
      }
    }
    
    .buttons{
      text-align: center;
      .create__room__btn {
        width: calc(40% + 8px);
        padding: 0.5rem 1rem;
        background-color: #3B51ED;
        color: #fff;
        outline: none;
        border: none;
        cursor: pointer;
        border-radius: 0.25rem;
        letter-spacing: 1px;
        margin-bottom: 1rem;
        transition-duration: 0.3s;
  
        &:focus,
        &:hover {
          background-color: #1a2cb3;
        }
      }
    }
  }
}

// Media query breakpoints
@media (max-width: 776px) {
  .newroom__contents {
    max-width: 28rem;
    padding: 0rem 1.5rem 1rem;
    gap: 0.8rem;

    .newroom__heading {
      letter-spacing: 1px;
      font-size: 1.75rem;
    }

    .newroom__form > input {
      width: 18rem;
    }

    .input__error {
      font-weight: 200;
      font-size: 0.8rem;
      text-align: center;
    }

    .create__room__btn {
      width: 18rem;
    }
  }
}

@media (max-width: 450px) {
  .newroom__contents {
    max-width: 20rem;

    .newroom__heading {
      letter-spacing: normal;
      font-size: 1.45rem;
    }

    .newroom__form {
      gap: 0.75rem;
    }

    .newroom__form > input {
      width: 15rem;
    }

    .input__error {
      font-weight: 100;
    }

    .create__room__btn {
      width: 15rem;
      letter-spacing: normal;
    }
  }
}

@media (max-width: 350px) {
  .newroom__contents {
    max-width: 16rem;

    .newroom__heading {
      font-size: 1.3rem;
      margin-top: 0.75rem;
      margin-bottom: 0.25rem;
    }

    .newroom__form {
      gap: 0.75rem;
    }

    .newroom__form > input {
      width: 13rem;
      padding: 0.3rem 0.75rem;
    }

    .create__room__btn {
      width: 13rem;
      padding: 0.3rem 0.75rem;
      font-size: 0.95rem;
      margin-bottom: 0.25rem;
    }
  }
}
