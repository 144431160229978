.dating-navbar-top {
  display: none;
  justify-content: space-between;
  align-items: center;
  padding: 22px;
  padding-bottom: 5px;
  //   background-color: #f6e4f9;
  background: rgba(248, 206, 242, 0.04);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  order: 1;

  @media screen and (max-width: 768px) {
    display: flex;
  }

  @media screen and (max-width: 480px) {
    display: flex;
  }

  .dnav {
    display: flex;
    align-items: center;
    justify-content: start;
    background: linear-gradient(75deg, #eb3fb3, #e125cc, #c718e7);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    width: 65px;
  }

  .dname {
    background: linear-gradient(75deg, #eb3fb3, #e125cc, #c718e7);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    font-weight: 400;
    font-size: 22px;
  }

  .dsettings {
    display: flex;
    align-items: center;
    gap: 20px;
    cursor: pointer;
    background: linear-gradient(75deg, #eb3fb3, #e125cc, #c718e7);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
  }
}
