@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Shadows+Into+Light&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Sacramento&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Handlee&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Loved+by+the+King&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Preahvihear&display=swap");


.timer-card-alt{
    width: 100%;
    margin: 4%;
    border-radius: 4px;
    padding: 15px 10px;
    margin-top: 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.145) 0px 4px 12px;
    justify-content: center;
    background-image: url("../img/lightConfessionCard.gif");
    position: relative;
    h4{
        font-family: "Shadows Into Light", cursive;
        text-align: center;
        font-size: 22px;
        color: #5F611D;

    }
    h3{
        color: #B61414;
    }
    .image{
        position: absolute;
        width: 90px;
        right: -2%;
        top: 0;
    }
    a{
        text-decoration: none;
        color: #B61414;
        font-family: "Shadows Into Light", cursive;
        font-size: 18px;
        text-align: center;
        margin-top: 10px;
        font-weight: 600;
        border: 1px solid #B61414;
        padding: 1px 10px;
        border-radius: 4px;
        transition-duration: 0.4s;
        &:hover{
            color: #fff;
            background-color: #B61414;

        }
    }
}