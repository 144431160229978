.Shop {
    height: 100vh;
    background-color: #FFB500;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("../img/shpoing.jpg");
    background-size: cover;
    background-position: center bottom;
    @media screen and (max-width: 480px) {
      background-position: right top;
    }
    .main {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      flex-direction: column;
      h3 {
        padding: 20px 10px;
        font-size: 24px;
        animation: slideIn 0.5s ease-out forwards;
        color: #FFB500;
      }
      img {
        animation: slideIn 0.5s ease-out forwards;
        width: 400px;
      }
      p {
        width: 60%;
        font-size: 12px;
        animation: slideIn 0.5s ease-out forwards;
        padding: 10px;
        font-weight: 600;
      }
      button {
        width: 120px;
        padding: 8px 10px;
        border: none;
        border-radius: 4px;
        color: #fff;
        font-weight: 600;
        font-size: 13px;
        animation: slideIn 1.9s ease-out forwards;
        background-color: #FFCE58;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        transition-duration: 0.3s;
        &:hover {
          background-color: #FFB501;
          box-shadow: none;
          cursor: pointer;
        }
      }
    }
  }
  