.TopStartUpSliders {
  $animationSpeed: 40s;
  margin-top: 5%;

  .topStartUp {
    height: 100px;
    margin: auto;
    overflow: hidden;
    position: relative;
    width: calc(100vw - 85px);

    @media screen and (max-width: 768px){
      width: 100%;
    }


    &::before,
    &::after {
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0.949) 20%,
        rgba(255, 255, 255, 0.115) 80%
      );
      content: "";
      height: 100px;
      position: absolute;
      width: 200px;
      z-index: 2;
    }

    &::after {
      right: 0;
      top: 0;
      transform: rotateZ(180deg);
    }

    &::before {
      left: 0;
      top: 0;
    }

    .slide-track {
      animation: scroll $animationSpeed linear infinite;
      display: flex;
      width: calc(250px * 14);
      gap: 5px;
      .slide {
        height: 100px;
        width: 250px;
        img{
          border-radius: 8px;
        }
      }
    }

    
  }
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 7));
  }
}
