.circular-progress-container {
  position: relative;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  overflow: hidden;
  .circular-progress {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    .progress-text {
        font-size: 0.8rem;
        font-weight: bold;
      }   
  }
}
