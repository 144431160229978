@import url('https://fonts.googleapis.com/css2?family=Grandstander:wght@600&family=Poppins&display=swap');

$primary-color: #286eff;
$secondary-color: #d34848;
$tertiary-color: #646464;
$highlight-color: rgba(209, 209, 209, 0.438);
$primay-white: #fafcff;
$primary-color-hover: #1751c4;
$primay-white-hover: #e8e9ec;

.sidebar {
  background-color: $primay-white;
  position: relative;
  width: 40%;

  .chats {
    height: calc(100vh - 220px);
    overflow-y: scroll;

    @media screen and (max-width: 768px) {
      height: calc(100vh - 260px);
    }

  }

  .userChat {
    padding: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
    box-shadow: rgba(17, 17, 26, 0.067) 0px 1px 0px;
    transition: background-color 0.3s ease;
    border-left: $primay-white 4px solid;
    &:nth-last-child(1){
      box-shadow: none;
    }

    &:hover {
      background-color: $primay-white-hover;
      cursor: pointer;
      border-left: $primary-color 4px solid;
      

      .userChatInfo {
        .name-message {
          .name {
            color: $primary-color;
            font-family: 'Poppins', sans-serif;
            animation: wiggleAnimation 0.6s ease 1;
          }
          .message {
            color: $tertiary-color;
          }
        }
        .time {
           
          p {
            color: $secondary-color;
          }
        }
      }
    }

    img {
      height: 42px;
      width: 42px;
      border-radius: 50%;
      object-fit: cover;
      border: $primary-color 3px solid;
      padding: 2px;
      cursor: pointer;
      transition: border 0.3s ease;

      &:hover {
        border: $secondary-color 3px solid;
        animation: borderAnimation 0.5s ease infinite;
      }
    }

    .userChatInfo {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 80%;

      .name-message {
        .name {
          color: #424242;
          font-weight: 700;
          font-family: "open sans", sans-serif;
        }
        .message {
          color: $tertiary-color;
          font-weight: 300;
          font-family: "open sans", sans-serif;
          font-size: 12px;
          background-color: transparent;
        }
      }

      .time {
        p {
          color: $primary-color;
          font-size: 12px;
          font-weight: 600;
          font-family: "open sans", sans-serif;
        }
        .read{
            text-align: center;
            padding: 2px;
            .fa-check{
                color: $tertiary-color;
            }
          }
      }
    }
  }

  .selected-message {
    background-color: $primary-color;
    // background: linear-gradient(45deg, rgba(211, 72, 72, 1) 2%, rgba(40, 110, 255, 1) 92%);
    // background-size: 600% 600%;
    animation: gradientAnimation 2s linear 1;
    position: relative;
    border-left: $secondary-color 4px solid;
    &:hover{
        border-left: $primary-color 4px solid;
    }

    img {
      border: $highlight-color 3px solid;
    }

    .userChatInfo {
      .name-message {
        .name,
        .message {
          color: $primay-white;
        }
      }
      .time {
        p {
          color: $secondary-color;
        }
      }
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: $highlight-color;
      z-index: -1;
      pointer-events: none;
    }
  }

  // Responsive Styles
  @media screen and (max-width: 768px) {
    width: 100%;

    .userChat {
      img {
        height: 36px;
        width: 36px;
      }
      .userChatInfo {
        .name-message {
          .name {
            font-size: 16px;
          }
          .message {
            font-size: 11px;
          }
        }
        .time {
          p {
            font-size: 11px;
          }
          
        }
      }
    }

    .selected-message {
      border-left: $primary-color 3px solid;

      .userChatInfo {
        .name-message {
          .name,
          .message {
            font-size: 16px;
          }
        }
        .time {
          p {
            font-size: 11px;
          }
          
        }
      }
    }
  }

  @media screen and (max-width: 480px) {
    .userChat {
      padding: 8px;
      gap: 8px;
    }
  }
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes wiggleAnimation {
  0% {
    transform: rotateZ(0deg);
  }
  25% {
    transform: rotateZ(-3deg);
  }
  50% {
    transform: rotateZ(3deg);
  }
  75% {
    transform: rotateZ(-3deg);
  }
  100% {
    transform: rotateZ(0deg);
  }
}

@keyframes borderAnimation {
  0% {
    border-color: $primary-color;
  }
  50% {
    border-color: $secondary-color;
  }
  100% {
    border-color: $primary-color;
  }
}
