@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Shadows+Into+Light&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Sacramento&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Handlee&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Loved+by+the+King&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Preahvihear&display=swap");

$animation-duration: 0.5s;
.confession-card-Revaled {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  .confess-card-content {
    animation: appearAnimation $animation-duration forwards;
    min-width: 800px;
    min-height: 300px;
    background-image: url("../img/lightConfessionCard.gif");
    background-size: 400px;

    background-color: #fee5e0;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
      rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    position: absolute;
    border-bottom: 15px #ff4f85 solid;
    padding: 20px;
    &:nth-child(1) {
      margin-right: -35px;
      margin-top: 60px;
      background-image: url("../img/darkConfessionCard.jpg");
      background-size: 200px;
      background-position: right;
      background-color: #d12b33;
      transform: rotate(-3deg);
      border-bottom: 15px #fee5e0 solid;
    }
    @media screen and (max-width: 480px) {
      min-width: 250px;
      min-height: 300px;
    }

    h1 {
      font-family: "Shadows Into Light", cursive;
      font-size: 50px;
      position: absolute;
      top: 0%;
      left: 2;
      transform: rotate(-20deg);
      width: fit-content;
      color: #24242489;
    }
    p {
      margin-left: 10%;
      font-family: "Loved by the King", cursive;
      font-size: 24px;
      font-weight: 600;
      color: #c61922;
    }
    .confessions {
      padding: 10px 5px;
      p {
        font-family: "Handlee", cursive;
        font-size: 16px;
        color: #343434;
      }
    }
    .ribbonround {
      width: 80px;
      position: absolute;
      bottom: -3%;
      left: -2%;
    }
    .buttonss {
      position: absolute;
      bottom: 6%;
      right: 2%;
      button {
        background-color: #ffcbc2;
        border: none;
        margin: 2px;
        font-family: "Handlee", cursive;
        color: #d12b33;
        padding: 4px 10px;
        &:hover {
          cursor: pointer;
        }
        &:nth-child(2) {
          background-color: transparent;
        }
      }
    }
  }
}

@keyframes appearAnimation {
    from {
      transform: translateX(-10px);
    }
    to {
        transform: translateX(0);
    }
  }
  
  @keyframes disappearAnimation {
    from {
      opacity: 1;
      transform: scale(1);
    }
    to {
      opacity: 0;
      transform: scale(0);
    }
  }

