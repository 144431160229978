.chat{
  background-image: url("../img/chatbg3.jpg");
  background-size: cover;
  background-position: center;
}
.messages {
  background-color: #f3f6ff;
  // background: linear-gradient(135deg, #F2E6E2, #F3DCD0, #F0D2D2, #E4D5DF, #E1E5F0);
  padding: 10px;
  height: calc(100% - 170px);
  overflow-y: auto;
  overflow-x: none;
   background-image: url("../img/chatbg3.jpg");
  background-size: cover;
  background-position: center;

  .message {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;

    .messageInfo {
      display: flex;
      flex-direction: column;
      color: gray;
      font-weight: 300; /* Add position relative to enable stacking context for z-index */

      .delete {
        text-align: center;
        background-color: white;
        margin-top: 5px;
        padding: 3px;
        border-radius: 4px;
        display: none;
        font-size: 9px;
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
        font-weight: 700;
        transition: opacity 0.3s ease, transform 0.3s ease;
        &::after {
          content: "Remove";
        }
      }

      &:hover .delete {
        display: block;
        opacity: 1;
        animation: swipeAnimation 0.3s ease;
        cursor: pointer;
        background-color: #d24141;
        color: #fff;
      }

      img {
        height: 42px;
        width: 42px;
        border-radius: 50%;
        object-fit: cover;
        border: #286eff 3px solid;
        padding: 2px;
        cursor: pointer;
        transition: border 0.3s ease;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
        animation: bounceInOut 0.6s ease;

        &:hover {
          border: #d24141 3px solid;
        }
      }
    }
    .messageContent {
      padding: 20px 0px;
      max-width: 62%;
      display: flex;
      flex-direction: column;
      gap: 10px;
      //   background-color: red;
      word-wrap: break-word;
      position: relative;
      .inlargeImage{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 100;
        display: flex;
        justify-content: center;
        align-items: center;

      }
      
      p {
        background-color: white;
        padding: 8px 15px;
        border-radius: 0px 10px 10px 10px;
        max-width: max-content;
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
        font-size: 14px;
        font-family: "open sans", sans-serif;
        color: #434343;
        animation: slideUpDownOriginal 0.5s ease;
        .stringLink {
          text-decoration: none;
          color: blue;
          word-wrap: anywhere;
          word-wrap: all;
        }
      }
      .messageImg {
        width: fit-content;
        img {
          width: 100%;
          border-radius: 0px 10px 10px 10px;
          border: 10px #fff solid;
          box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
          animation: slideUpDownOriginal 0.5s ease;
          animation: blurIn 1.3s ease;
          @media screen and (max-width: 480px) {
            border: 6px #fff solid;
          }
        }
      }
      .file {
        .link {
          text-decoration: none;
          display: flex;
          align-items: center;
          background-color: #ffffffc5;
          color: blue;
          padding: 4px 8px;
          border-radius: 12px;
          font-style: italic;
          gap: 20;
          font-size: 13px;
          
        }
        .icon {
          font-size: 40px;
          margin: 0 5px;
        }
      }
      .time {
        font-size: 11px;
        font-weight: 600;
        animation: slideUpPopIn 2.8s ease;
      }
    }
    .ownerContent {
      text-align: right;
    }

    @keyframes swipeAnimation {
      0% {
        transform: translateX(0);
      }
      100% {
        transform: translateX(-10px);
      }
    }

    &.owner {
      flex-direction: row-reverse;

      img {
        border: #d24141 3px solid;
        &:hover {
          border: #286eff 3px solid;
        }
      }

      .messageContent {
        align-items: flex-end;
        img {
          border-radius: 10px 0px 10px 10px;
          animation: blurIn 1.3s ease;
          border: 10px #e2ebfc solid;
        }
        p {
          //   background: linear-gradient(45deg, #033391, #286eff, #033bab);
          background: #e2ebfc;
          //   background: linear-gradient(45deg, #e2ebfc, #89a8e0, #c4d7fb);
          background-size: 600% 600%;
          animation: gradientAnimation 4s linear infinite;
          color: #434343;
          border-radius: 10px 0px 10px 10px;
          animation: slideRightLeftOriginal 0.8s ease;
          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba(209, 209, 209, 0.2);
            z-index: -1;
            pointer-events: none;
          }
        }
      }
    }
  }
}

@keyframes bounceInOut {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}

@keyframes blurIn {
  0% {
    opacity: 0;
    filter: blur(40px);
  }
  50% {
    opacity: 0.5;
    filter: blur(10px);
  }
  100% {
    opacity: 1;
    filter: blur(0);
  }
}

@keyframes shake {
  0%,
  100% {
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translateX(-5px);
  }
  20%,
  40%,
  60%,
  80% {
    transform: translateX(5px);
  }
}

@keyframes spinAndScale {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2) rotate(180deg);
  }
  100% {
    transform: scale(1) rotate(360deg);
  }
}

@keyframes bounceIn {
  0%,
  20%,
  40%,
  60%,
  80%,
  100% {
    transform: translateY(0);
  }
  30% {
    transform: translateY(-30px);
  }
  50% {
    transform: translateY(0);
  }
}

@keyframes rotateAndMove {
  0% {
    transform: rotate(0) translateX(0);
  }
  50% {
    transform: rotate(180deg) translateX(50px);
  }
  100% {
    transform: rotate(360deg) translateX(0);
  }
}

@keyframes scaleAndBounce {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
}

@keyframes popSlideUp {
  0% {
    transform: scale(0.5);
    opacity: 0;
    transform: translateY(20px);
  }
  50% {
    transform: scale(1.4);
    opacity: 0.6;
  }
  100% {
    transform: scale(1);
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideUpPopIn {
  0% {
    transform: translateY(20px);
    opacity: 0;
  }
  50% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes slideUpDownOriginal {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes slideRightLeftOriginal {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(20px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes popInFadeOut {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  50% {
    transform: scale(1.2);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}

@keyframes wiggle {
  0%,
  100% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-10px);
  }
  50% {
    transform: translateX(0);
  }
  75% {
    transform: translateX(-10px);
  }
}
