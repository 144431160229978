
// Main styles
.show__toast {
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  margin: 0.5rem auto;
  text-align: center;
  font-size: 0.7rem;
  letter-spacing: 1px;
  color: #b2b2b2;
  
  
  // Media query breakpoints
  @media (max-width: 700px) {
    padding: 0.3rem 0.75rem;
    border-radius: 0.8rem;
    font-size: 0.6rem;
  }
}
