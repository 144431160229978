$primary-color: #286eff;
$secondary-color: #033391;
.Dashboard {
  height: 100vh;
  position: relative;
  iframe{
    width: 100%;
    height: 100%;
  }
  .shareWithFriends{
    position: absolute;
    right: 3%;
    bottom: 3%;
    background-color: $primary-color;
    color: white;
  }
}
