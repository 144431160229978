@import url("https://fonts.googleapis.com/css2?family=Dai+Banna+SIL:wght@300;600&family=Dancing+Script:wght@500&family=Gochi+Hand&family=Handlee&family=Noticia+Text&family=Noto+Serif:wght@500&family=Patrick+Hand&display=swap");
.NewsPage {
  background-color: #fff;
  background-size: cover;
  background-position: center;
  height: 100vh;

  .categories-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.471);
    backdrop-filter: blur(2px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    overflow: auto;

    @media screen and (max-width: 768px) {
      width: 100%;
      height: 100%;
    }

    .popup-content {
      background: #fff;
      border-radius: 8px;
      width: 100%;
      min-height: 50vh;
      max-width: 40vw;
      position: absolute;
      top: 4%;
      animation: slideUpToDown 0.2s ease-out forwards;
      gap: 0;
      padding: 10px;

      h4 {
        margin-bottom: 15px;
      }

      .categories-list {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;

        label {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 5px;
          cursor: pointer;
          transition: background-color 0.3s;
          padding: 10px;
          border-radius: 5px;

          &:hover {
            background-color: #0056b3;
          }
        }
      }

      button {
        background-color: #0056b3;
        color: white;
        border: none;
        padding: 10px;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s;
        margin:5px;

        &:nth-child(even){
          background-color: red;
        }

        &:hover {
          background-color: #0050a5;
        }
      }
    }
  }

  .newsImagePopup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.471);
    backdrop-filter: blur(2px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    overflow: auto;

    @media screen and (max-width: 768px) {
      width: 100%;
      height: 100%;
    }

    .newsImageContent {
      background: #fff;
      border-radius: 8px;
      width: 100%;
      min-height: 50vh;
      max-width: 40vw;
      position: absolute;
      top: 4%;
      animation: slideUpToDown 0.2s ease-out forwards;
      gap: 0;

      @media screen and (max-width: 768px) {
        width: 100%;
        min-height: 50vh;
        max-width: 100vw;
        top: 0%;
        border-radius: 0 0 12px 12px;
        top: 0%;
      }

      @media screen and (max-width: 480px) {
        width: 100%;
        min-height: 50vh;
        max-width: 100vw;
        top: 0%;
        border-radius: 0 0 12px 12px;
        top: 0%;
      }

      .closeButton {
        position: absolute;
        top: 10px;
        right: 10px;
        background: #fff;
        color: red;
        border: none;
        border-radius: 4px;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 22px;
        cursor: pointer;
        transition: background 0.3s ease;

        &:hover {
          background: #033391;
          color: #fff;
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 8px 8px 0 0;

        @media screen and (max-width: 768px) {
          width: 100%;
          height: 100%;
          border-radius: 0 0;
        }

        @media screen and (max-width: 480px) {
          width: 100%;
          height: 100%;
          border-radius: 0 0;
        }
      }

      .content {
        padding: 10px;

        border-radius: 0 0 8px 8px;

        .NLoading {
          text-align: center;
          font-size: 12px;
          color: #242424a3;
          font-weight: 600;
          display: flex;
          align-items: center;
          justify-content: center;
          width: calc(100% - 20px);
        }

        a {
          color: #0331ba;
          text-decoration: none;
          font-weight: 500;
        }

        @media screen and (max-width: 768px) {
          width: 100%;
          height: 100%;
          border-radius: 0 0 12px 12px;
          font-size: 11px;
        }

        @media screen and (max-width: 480px) {
          width: 100%;
          height: 100%;
          border-radius: 0 0 12px 12px;
          font-size: 11px;
        }
      }
    }
  }

  .addNewsPopup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.471);
    backdrop-filter: blur(2px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    overflow: auto;

    @media screen and (max-width: 768px) {
      width: 100%;
      height: 100%;
    }

    .addNewsContent {
      background: #fff;
      border-radius: 8px;
      width: 100%;
      min-height: 50vh;
      max-width: 40vw;
      position: absolute;
      top: 4%;
      animation: slideUpToDown 0.2s ease-out forwards;

      @media screen and (max-width: 768px) {
        width: 100%;
        min-height: 50vh;
        max-width: 100vw;
        top: 0%;
        border-radius: 0 0 12px 12px;
        top: 0%;
      }

      @media screen and (max-width: 480px) {
        width: 100%;
        min-height: 50vh;
        max-width: 100vw;
        top: 0%;
        border-radius: 0 0 12px 12px;
        top: 0%;
      }

      .currentUser {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 5%;
        width: fit-content;
        gap: 10px;
        padding: 20px;
        padding-bottom: 0;

        img {
          width: 40px;
          height: 40px;
          object-fit: cover;
          border-radius: 50%;
        }
        p {
          bottom: 0;
          left: 0;
          text-align: center;
          font-size: 18px;
          font-weight: bolder;
          color: #242424;
          width: 100%;
        }
      }

      .closeButton {
        position: absolute;
        top: 10px;
        right: 10px;
        background: transparent;
        color: #242424;
        border: none;
        border-radius: 4px;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 22px;
        cursor: pointer;
        transition: background 0.3s ease;

        &:hover {
          background: #033391;
          color: #fff;
        }
      }

      .publishContent {
        margin-top: 0%;

        form {
          position: relative;
          padding: 20px;
          padding-top: 0;

          textarea {
            width: 100%;
            border: none;
            resize: none;
            font-size: 12px;
            border-bottom: 1px solid #b36000;
            background-color: #f6f6f6;
            margin: 8px 2px;
            margin-top: 1%;
          }

          .input-field {
            margin-top: 8%;
            display: flex;
            gap: 10px;
            padding: 8px 2px;
            justify-content: space-between;
            align-items: center;

            @media screen and (max-width: 768px) {
              margin-top: 12%;
            }

            @media screen and (max-width: 480px) {
              margin-top: 12%;
            }

            select {
              width: 30%;
              padding: 8px 10px;
              border: none;
              outline: none;
              font-size: 13px;
              background-color: #f6f6f6;
              border-radius: 4px;

              @media screen and (max-width: 768px) {
                width: 50%;
              }

              @media screen and (max-width: 480px) {
                width: 50%;
              }
            }
            .action {
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 15px;

              .showEmojis {
                @media screen and (max-width: 768px) {
                  display: none;
                }

                @media screen and (max-width: 480px) {
                  display: none;
                }
              }

              i {
                font-size: 22px;
                background-color: #24242418;
                color: #242424;
                border-radius: 4px;
                padding: 5px;

                &:hover {
                  cursor: pointer;
                  background-color: #24242432;
                }
              }

              button {
                padding: 5px 10px;
                background-color: #0331ba;
                color: white;
                border: none;
                border-radius: 4px;
                cursor: pointer;

                &:hover {
                  background-color: #033391;
                }
              }
            }
          }
        }

        .emoji-picker {
          display: none;
          position: absolute;
          bottom: 19%;
          right: -60%;
        }

        .controls {
          display: flex;
          justify-content: space-between;
          padding: 10px 0;

          .icon {
            cursor: pointer;
            padding: 5px;
            font-size: 18px;

            &:hover {
              opacity: 0.7;
            }
          }
        }

        .img-upload {
          position: relative;
          .smallImagePreview {
            width: 100%;
            height: 200px;
            overflow: auto;
            border-radius: 12px 12px 0px 0px;

            img {
              width: 100%;
              border-radius: 0 0 12px 12px;
            }
          }
          button {
            position: absolute;
            top: 10px;
            right: 10px;
            background: #ccc;
            color: #242424;
            border: none;
            border-radius: 4px;
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 22px;
            cursor: pointer;
            transition: background 0.3s ease;

            &:hover {
              background: #033391;
              color: #fff;
            }
          }
        }

        .submit-btn {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-top: #cccccc75 1px solid;
          padding-top: 10px;

          button {
            padding: 8px 10px;
            background-color: #007bff;
            color: #fff;
            border: none;
            border-radius: 4px;
            cursor: pointer;

            &:nth-child(1) {
              background-color: #ccc;
              color: #242424;
              font-weight: 600;
              border-radius: 22px;
              &:hover {
                color: #fff;
              }
            }

            &:hover {
              background-color: #0331ba;
            }
          }
        }

        .imagePreview {
          margin-top: 10px;
          text-align: center;
          display: none;

          img {
            max-width: 100px;
            max-height: 100px;
            object-fit: cover;
            margin-bottom: 10px;
          }
        }

        .Status {
          padding: 10px;
          text-align: center;
          color: green;
          font-size: 12px;
        }

        .error {
          padding: 10px;
          text-align: center;
          color: red;
          font-size: 12px;
        }
      }
    }
  }

  .news-navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 3%;
    border-bottom: 1px solid #3c3c3c14;
    h1 {
      font-size: 1.5rem;
      color: #242424;
      width: fit-content;
      display: flex;
      align-items: center;
      gap: 5px;

      i {
        font-size: 1.2rem;
        color: #242424;
        display: none;

        @media screen and (max-width: 768px) {
          display: flex;
          align-items: center;
          gap: 5px;
        }

        @media screen and (max-width: 480px) {
          display: flex;
          align-items: center;
          gap: 5px;
        }
      }

      @media screen and (max-width: 768px) {
        font-size: 1.2rem;
      }

      @media screen and (max-width: 480px) {
        font-size: 1rem;
      }
    }

    .filters {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;

      @media screen and (max-width: 768px) {
        flex-direction: column;
      }

      @media screen and (max-width: 480px) {
        flex-direction: column;
      }

      .filter-item {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        p {
          font-size: 13px;
        }
        i {
          font-size: 15px;
          color: #3c3c3c;
        }

        @media screen and (max-width: 768px) {
          display: none;
        }
      }
    }

    .menus {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      button {
        background-color: #007bff;
        color: white;
        border: none;
        padding: 10px 20px;
        font-weight: 600;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s;

        @media screen and (max-width: 768px) {
          padding: 8px 10px;
          font-size: 9px;
        }

        &:hover {
          background-color: #0056b3;
        }
      }
    }
  }

  .container {
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    width: 100%;
    padding: 0px;
    width: 100%;

    .left {
      width: 20%;
      padding-top: 5px;
      @media screen and (max-width: 768px) {
        display: none;
      }

      .user-profile {
        width: 100%;
        border: 1px #cccccc3f solid;
        border-radius: 10px;

        .image {
          height: 80px;
          position: relative;
          background-size: cover;
          background-position: center;
          border-radius: 10px 10px 0px 0px;

          img {
            position: absolute;
            bottom: -30px;
            left: 10px;
            width: 80px;
            height: 80px;
            border-radius: 50%;
            object-fit: cover;
            border: 2px solid #fff;
          }
        }

        .user-details {
          padding: 30px 15px 15px;

          h2 {
            font-size: 18px;
          }
          p {
            font-size: 13px;
            font-weight: 500;
          }

          .bio {
            font-size: 13px;
            color: #3c3c3c;
            margin-top: 10px;
          }
        }
      }

      .top-users {
        border: 1px #cccccc3f solid;
        border-radius: 10px;
        margin-top: 20px;
        padding: 15px;
        height: 280px;
        overflow: auto;

        .user-list {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          flex-direction: column;
          flex-wrap: wrap;
          gap: 5px;
          width: 100%;

          h3 {
            font-size: 18px;
            font-weight: 500;
          }

          .top-user {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            gap: 10px;
            width: 100%;
            padding: 10px 0;
            border-bottom: 1px #cccccc3f solid;

            &:nth-last-child(1) {
              border-bottom: none;
            }

            .user-image {
              width: 35px;
              height: 35px;
              img {
                width: 35px;
                height: 35px;
                border-radius: 50%;
                object-fit: cover;
              }
            }

            div {
              width: 80%;
              h3 {
                font-size: 15px;
                font-weight: 500;
              }
              p {
                font-size: 11px;
              }
            }

            .send-message {
              width: 20%;
              display: flex;
              justify-content: center;
              align-items: center;
              i {
                width: 25px;
                height: 25px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                background: linear-gradient(45deg, #033391, #286eff, #033391);
                background-size: auto;
                background-size: 600% 600%;
                animation: gradientAnimation 4s linear infinite,
                  popAnimation 0.3s ease;
                color: #fff;
                border-radius: 8px;
                box-shadow: rgba(0, 0, 0, 0.177) 0px 3px 8px;
                transform: scale(1);
                color: #fff;
                animation: slideIn 0.5s ease-out forwards;
                cursor: pointer;
                padding: 2px;
              }
            }
          }
        }
      }

      .button {
        text-align: center;
        button {
          width: 120px;
          padding: 8px 10px;
          border: none;
          border-radius: 4px;
          color: #fff;
          font-size: 13px;
          animation: slideIn 0.5s ease-out forwards;
          background-color: #dd8e33;
          &:hover {
            background-color: #b36000;
            cursor: pointer;
          }
        }
      }
    }
    .center {
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 20px;

      @media screen and (max-width: 768px) {
        width: 80%;
      }

      .ctime {
        font-size: 11px;
      }

      .content {
        width: 100%;
        height: calc(100vh - 60px);

        @media screen and (max-width: 768px) {
          height: calc(100vh - 80px);
        }

        @media screen and (max-width: 480px) {
          height: calc(100vh - 90px);
          width: 98.4%;
        }

        .shareSomething {
          padding: 10px;
          background-color: #f3f1f1;
          width: calc(100% - 40px);
          border-radius: 0 0 12px 12px;

          @media screen and (max-width: 768px) {
            width: calc(100% - 20px);
          }

          .inner {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;
            padding: 4px 8px;
            background-color: #fff;
            border-radius: 18px;

            &:hover {
              cursor: pointer;
            }

            p {
              font-size: 13px;
              color: #585858;
              font-weight: 600;
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: space-between;
              gap: 5px;

              span {
                font-size: 22px;
              }
            }

            img {
              width: 40px;
              height: 40px;
              object-fit: cover;
              object-position: top;
              border-radius: 50%;
            }
          }
        }

        .load-more {
          text-align: center;
          padding: 10px;
          font-size: 13px;
          color: #242424a3;
          font-weight: 600;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 5px;

          i {
            animation: upDown 3s ease-in-out infinite;
            font-size: 13px;
          }
        }

        overflow: auto;
        ::-webkit-scrollbar {
          display: none;
        }
        ::-webkit-scrollbar {
          width: 0px;
        }
        .card {
          width: calc(99% - 20px);
          border-radius: 8px;
          margin: 20px 0px;
          // box-shadow: rgba(149, 157, 165, 0.149) 0px 8px 24px;

          @media screen and (max-width: 768px) {
            width: calc(100% - 10px);
          }

          @media screen and (max-width: 480px) {
            width: calc(100% - 5px);
            margin: 25px 0;
          }

          &:nth-child(odd) {
            background-color: #dfebff;
          }
          &:nth-child(even) {
            background-color: #fff6df;
          }

          margin-top: 10px;
          .card-top {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px 12px;
            border-bottom: 1px solid #3c3c3c14;

            .author {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              flex-direction: row;
              gap: 5px;

              img {
                width: 40px;
                height: 40px;
                border-radius: 50%;
              }

              h3 {
                font-size: 15px;
                color: 242424;
              }
              p {
                font-size: 11px;
                color: #3c3c3c;
                font-weight: 600;
              }
            }

            .more {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              flex-direction: row;
              gap: 10px;

              position: relative;

              .small-popup {
                position: absolute;
                top: 0;
                right: 0;
                background-color: #fff;
                padding: 4px;
                width: fit-content;
                border-radius: 4px;
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 4px;

                .small-popup-content {
                  display: flex;
                  gap: 4px;
                  flex-direction: column;
                  p {
                    font-size: 12px;
                    color: #3c3c3c;
                    background-color: transparent;
                    font-weight: 700;

                    &:hover {
                      cursor: pointer;
                      background-color: #242424;
                      color: #fff;
                      border-radius: 4px;
                    }
                  }
                }
              }

              p {
                font-size: 14px;
                color: #3c3c3c;
                background-color: #e2e2e2;
                padding: 1px 5px;
                border-radius: 4px;
                font-weight: 700;
              }
            }

            i {
              font-size: 20px;

              &:nth-child(1) {
                font-size: 12px;
              }

              &:hover {
                cursor: pointer;
              }
            }
          }
          .card-center {
            padding: 10px 12px;

            hr {
              margin: 6px 0;
              border: 1px solid #3c3c3c0a;
            }

            .readMore {
              font-weight: 500;
              background: linear-gradient(45deg, #eb912a, #f7a950, #b36000);
              -webkit-background-clip: text;
              background-clip: text;
              font-size: 14px;
              padding-bottom: 5px;
              color: transparent;
              &:hover {
                cursor: pointer;
              }
            }

            h2 {
              font-family: Noticia Text, serif;
              font-size: 17px;
              padding: 8px 0px;
              color: #272727;
            }
            .news {
              color: #4a4a4a;
              font-size: 13px;
              font-weight: 600;
              padding: 10px 6px;
              a {
                color: #0331ba;
                text-decoration: none;
              }
            }
            .image {
              width: 100%;
              min-height: auto;
              max-height: 300px;
              overflow: auto;
              img {
                width: 100%;
                object-fit: cover;
                border-radius: 8px;
              }
            }
            .type {
              font-family: Noticia Text, serif;
              font-size: 15px;
              color: #fff;
              background-color: #ffca2c;
              width: fit-content;
              padding: 4px 10px;
            }

            .likes-comments {
              display: flex;
              align-items: center;
              justify-content: space-between;
              flex-direction: row;
              gap: 20px;
              padding: 0 4px;

              .action {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                flex-direction: row;
                gap: 20px;
                width: 20%;

                font-size: 12px;
                font-weight: 700;

                i {
                  &:hover {
                    cursor: pointer;
                    transform: scale(1.1);
                    transition-duration: 0.3s;
                  }
                }

                .likes {
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;
                  flex-direction: row;
                  gap: 5px;

                  i {
                    font-size: 20px;
                    color: #df0606;
                  }
                }

                .comments {
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;
                  flex-direction: row;
                  gap: 5px;

                  i {
                    font-size: 20px;
                  }
                }
              }

              .recent-comment {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 10px;
                border-radius: 12px;
                padding: 8px 12px;
                background-color: #e4eeff;
                transition: background-color 0.3s;

                @media screen and (max-width: 768px) {
                  padding: 6px 8px;
                }

                &:hover {
                  background-color: #d1e1ff;
                  cursor: pointer;
                }

                .lastComment {
                  display: flex;
                  align-items: center;
                  gap: 8px;
                  font-size: 12px;
                  color: #3c3c3c;
                  font-weight: 500;

                  @media screen and (max-width: 768px) {
                    font-size: 10px;
                  }

                  img {
                    width: 24px;
                    height: 24px;
                    border-radius: 50%;
                  }

                  .time {
                    font-size: 10px;
                    color: #6c757d;

                    @media screen and (max-width: 768px) {
                      font-size: 8px;
                    }

                    @media screen and (max-width: 480px) {
                      font-size: 8px;
                    }
                  }

                  .authorName {
                    font-weight: bold;
                  }

                  div {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                  }

                  .comment-text {
                    white-space: nowrap;
                    max-width: 40%;

                    @media screen and (max-width: 768px) {
                      max-width: 100%;
                      color: #686868;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .right {
      width: 20%;
      display: flex;
      text-align: center;
      flex-direction: column;
      gap: 20px;
      margin-top: 5px;

      .ads {
        width: 100%;
        border: 1px #cccccc3f solid;
        border-radius: 10px;
        text-align: start;

        h3 {
          padding: 5px;
        }

        .ads-container {
          width: 100%;
          border-radius: 12px;
          padding: 0;
          overflow: hidden;

          img {
            width: 100%;
            object-fit: cover;
            border-radius: 8px;
            transform: scale(1.04);

            &:hover {
              cursor: pointer;
              transform: scale(1.2);
              transition-duration: 0.5s;
            }
          }
        }
      }

      @media screen and (max-width: 768px) {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .NewsPage {
    display: flex;
    flex-direction: column;
    margin: 0px 2px;
    .container {
      flex-direction: column;
      .center {
        width: 100%;
        justify-content: center;
        align-items: center;
        .ctime {
          display: none;
        }
        .content {
          .card {
            margin: 5px 0px;
            width: calc(100%);
            border-radius: 0px;
          }
        }
      }
      .left {
        display: none;
      }
      .right {
        display: none;
      }
    }
  }
}

@keyframes upDown {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-2px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes colorShift {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

.comments-popup {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.471);
  backdrop-filter: blur(2px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow: auto;

  @media screen and (max-width: 768px) {
    width: 100%;
    height: 100%;
  }

  .popup-content {
    background: #fff;
    border-radius: 8px;
    width: 100%;
    min-height: 60vh;
    max-height: 60vh;
    max-width: 40vw;
    position: absolute;
    top: 4%;
    animation: slideUpToDown 0.2s ease-out forwards;

    @media screen and (max-width: 768px) {
      width: 100%;
      min-height: 50vh;
      max-width: 100vw;
      top: 0%;
      border-radius: 0 0 12px 12px;
    }

    @media screen and (max-width: 480px) {
      width: 100%;
      min-height: 90vh;
      max-width: 100vw;
      top: 0%;
      border-radius: 0 0 12px 12px;
    }

    .close-popup {
      position: absolute;
      top: 10px;
      right: 10px;
      color: #242424;
      border: none;
      border-radius: 4px;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 22px;
      cursor: pointer;
    }

    h3 {
      font-size: 20px;
      color: #242424;
      font-weight: 700;
      padding: 10px;
    }

    .allComments {
      height: calc(50vh - 30px);
      overflow: auto;
      @media screen and (max-width: 768px) {
        height: calc(80vh - 30px);
      }

      @media screen and (max-width: 480px) {
        height: calc(80vh - 30px);
      }
    }

    .add-comment {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      background-color: #0331ba;
      border-radius: 0 0 7px 7px;

      input {
        width: 80%;
        border: none;
        resize: none;
        font-size: 12px;
        background-color: #f6f6f6;
        margin: 8px 2px;
        margin-top: 1%;
        padding: 9px 10px;
        border-radius: 5px;
        @media screen and (max-width: 768px) {
          width: 70%;
          margin-top: 1%;
        }

        @media screen and (max-width: 480px) {
          width: 70%;
          margin-top: 1%;
        }
      }

      button {
        padding: 8px 10px;
        background-color: #007bff;
        color: #fff;
        border: none;
        border-radius: 4px;
        cursor: pointer;

        &:hover {
          background-color: #0056b3;
        }
      }
    }

    .comment {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
      gap: 10px;
      padding: 10px;
      border-bottom: 1px solid #3c3c3c14;

      .delete-comment {
        background-color: #f44336;
        color: #fff;
        border: none;
        border-radius: 4px;
        padding: 5px 8px;
        cursor: pointer;
      }

      .Mcontent {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: row;
        gap: 10px;

        img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
        }

        .comment-content {
          .time {
            font-size: 9px;
          }

          p {
            font-size: 12px;
            color: #4e4e4e;
            font-weight: 700;
          }

          h4 {
            color: #242424;
          }
        }
      }
    }
  }
}

@keyframes slideUpToDown {
  0% {
    transform: translateY(-10%);
  }
  100% {
    transform: translateY(0%);
  }
}
