$primary-color: #286eff;
$secondary-color: #033391;
$background-color: #f6f8fc;
$box-shadow-color: rgba(0, 0, 0, 0.114);
$text-color: #fff;
$border-color: #00000012;
$gradient-color-1: #9bc1f7;
$gradient-color-2: #2270dc;
$bottom-gradient-1: #5088f6;
$bottom-gradient-2: #5088f6;
$link-color: #4e4e4e;

.sidebarLeft {
  display: block;
  text-align: center;
  background-color: $background-color;
  position: relative;
  border-right: 1px $border-color solid;
  z-index: 10;

  &.darkTheme {
    background-color: #0e0e0e;
    border-right: 1px #0000006d solid;
  }

  @media screen and (max-width: 480px) {
    -webkit-box-shadow: 0px -5px 7px 2px $box-shadow-color;
    -moz-box-shadow: 0px -5px 7px 2px $box-shadow-color;
    box-shadow: 0px -5px 7px 2px $box-shadow-color;
    overflow: auto hidden;
  }

  h1 {
    background: linear-gradient(45deg, $primary-color, $secondary-color);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    margin-bottom: 10px;
    &[data-hover]:hover::after {
      content: attr(data-hover);
      position: absolute;
      top: 0.5%;
      left: 100%;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      background: linear-gradient(
        90deg,
        $secondary-color,
        $primary-color,
        $primary-color
      );
      animation: popAnimation 0.3s ease-in-out 1;
      padding: 8px 10px;
      color: $text-color;
      font-weight: 600;
      z-index: 10;
      font-size: 18px;
      width: fit-content;
    }
  }

  .bottom {
    position: absolute;
    bottom: 0;
    width: 100%;
    @media screen and (max-width: 480px) {
      width: calc(100% - 20px);
    }
    .beta {
      background: linear-gradient(
        45deg,
        $primary-color,
        $gradient-color-1,
        $secondary-color
      );
      background-size: 600% 600%;
      background-size: 600% 600%;
      animation: gradientAnimation 4s linear infinite, popAnimation 0.3s ease;
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent;
      margin-bottom: 30%;
      font-weight: 900;
      font-size: 12px;
      transform: rotate(-90deg);
    }
    .developerInfo {
      padding: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      .image-container {
        background: linear-gradient(
          45deg,
          $primary-color,
          $gradient-color-2,
          $secondary-color
        );
        border-radius: 50%;
        padding: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50%;
        height: 50%;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
          rgba(0, 0, 0, 0.22) 0px 10px 10px;
        z-index: 5;
        &:hover {
          background: linear-gradient(45deg, $primary-color, $secondary-color);
          cursor: pointer;
          box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset,
            rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
        }

        img {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          margin: 0 5px;
        }
      }
      .image-container-2 {
        position: absolute;
        width: 60%;
        border-radius: 50%;
        height: 73%;
        background-color: $bottom-gradient-1;
        z-index: 4;
        animation-delay: 1s;
        animation: pulseCircle 4s infinite cubic-bezier(0.36, 0.11, 0.89, 0.32);
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
      }
      .image-container-3 {
        position: absolute;
        width: 70%;
        border-radius: 50%;
        height: 89%;
        background-color: $bottom-gradient-1;
        z-index: 4;
        animation-delay: 2s;
        animation: pulseCircle 4s infinite cubic-bezier(0.36, 0.11, 0.89, 0.32);
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
      }
      .image-container-4 {
        position: absolute;
        width: 70%;
        border-radius: 50%;
        height: 89%;
        background-color: $bottom-gradient-1;
        z-index: 4;
        animation-delay: 3s;
        animation: pulseCircle 4s infinite cubic-bezier(0.36, 0.11, 0.89, 0.32);
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
      }
      .image-container-5 {
        position: absolute;
        width: 70%;
        border-radius: 50%;
        height: 89%;
        background-color: $bottom-gradient-1;
        z-index: 4;
        animation-delay: 4s;
        animation: pulseCircle 4s infinite cubic-bezier(0.36, 0.11, 0.89, 0.32);
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
      }
    }
  }

  .sprinkle_two,
  .fa-sparkles {
    color: #ffb700 !important;
    animation: shine-glow 1s infinite;
  }

  .customTitle {
    position: relative;
    margin: 0;
    padding: 0;
    width: 100%;
    display: inline-block;

    &.download {
      margin-top: 20px;
      i {
        color: #35a854 !important;
        font-size: 23px;
        animation: shine-glow2 2s infinite;
        transition-duration: 0.6s;
      }
      &:hover i {
        color: #fff !important;
      }
    }

    .fa-sparkles {
      position: absolute;
      top: 1%;
      right: 6%;
      animation: shine-glow 2s infinite;
    }

    &[data-hover]:hover::after {
      content: attr(data-hover);
      position: absolute;
      top: 10%;
      left: 100%;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      background: linear-gradient(
        90deg,
        $secondary-color,
        $primary-color,
        $primary-color
      );
      animation: popAnimation 0.3s ease-in-out 1;
      padding: 8px 10px;
      color: $text-color;
      font-weight: 600;
      z-index: 10;
      width: fit-content;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 15px;
      @media screen and (max-width: 480px) {
        display: none;
      }
    }
  }

  .top {
    margin-top: 20px;
    @media screen and (max-width: 480px) {
      margin-top: 3px;
      padding: 10px;
    }
  }

  .mobile-menu {
    position: fixed;
    bottom: 6%;
    z-index: 1000;
    width: 100%;
    display: none;

    .more {
      background: rgba(0, 0, 0, 0.497);
      border-radius: 8px 8px 0 0;
      backdrop-filter: blur(10px);
      padding: 6px 0px;
      flex-wrap: wrap;
      justify-content: space-around;
      align-items: center;
      // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      animation: slideUp 0.2s ease, pop-in-scale 0.5s ease-out forwards;
      opacity: 0;

      h3{
        color: white;
        font-size: 20px;
      }

    }

    .center {
      background: rgba(0, 0, 0, 0.497);
      border-radius: 0 0 8px 8px;
      backdrop-filter: blur(10px);
      padding: 6px 0px;
      flex-wrap: wrap;
      justify-content: space-around;
      align-items: center;
      // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      animation: slideUp 0.2s ease, pop-in-scale 0.5s ease-out forwards;
      opacity: 0;
    }

    a {
      color: #fff;
    }
    .Activelink {
      border-bottom: none;
      
      i{
        font-size: 15px;
        
      }
    }

    .customTitle {
      flex: 1 0 10%;
      font-size: 20px;
    }

    @media screen and (max-width: 480px) {
      .center {
        display: flex;
        width: 95%;
      }
      display: none;
      flex-wrap: wrap;
      justify-content: space-around;
      align-items: center;
    }

    @media screen and (max-width: 768px) {
      .center {
        display: flex;
        width: 95%;
      }
      display: none;
      flex-wrap: wrap;
      justify-content: space-around;
      align-items: center;
    }

  }

  .top,
  .bottom {
    display: block;
    text-align: center;

    .link {
      i {
        color: $link-color;
      }
    }

    .link,
    .Activelink {
      display: block;
      text-align: center;
      font-weight: bold;
      font-size: 18px;
      text-decoration: none;
      padding: 6px 12px;
      transition: background-color 0.3s ease, color 0.3s ease,
        box-shadow 0.3s ease;
      cursor: pointer;

      &:hover {
        background: linear-gradient(90deg, $primary-color, $secondary-color);
        color: $text-color;
        i {
          color: $text-color;
        }
      }
    }
  }

  .Activelink {
    // Styles for Active Links
    border-left: 5px solid $primary-color;

    i {
      background: linear-gradient(
        45deg,
        $secondary-color,
        $primary-color,
        $secondary-color
      );
      background-size: 600% 600%;
      animation: gradientAnimation 4s linear infinite, popAnimation 0.3s ease;
      padding: 10px;
      color: $text-color;
      border-radius: 8px;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      transform: scale(1);

      @media screen and (max-width: 480px) {
        border-radius: 4px;
      }

      @media screen and (max-width: 768px) {
        border-radius: 4px;
      }


      &:hover {
        box-shadow: none;
        animation: popAnimation 0.3s ease;
      }
    }
  }

  .top {
    .desktop-menu {
      display: block;
      @media screen and (max-width: 480px) {
        display: none;
      }

      @media screen and (max-width: 768px){
        display: none;
        
      }

    }
    .tooglebar {
      display: none;
      @media screen and (max-width: 480px) {
        display: block;
      }

      @media screen and (max-width: 768px){
        display: block;
        
      }

    }
  }
  .toggleAnimation {
    animation: pop-in-scale 0.5s ease-in-out 1;
  }

  // Media Queries
  @media (max-width: 768px) {
    h1 {
      display: none;
    }

    .top,
    .bottom {
      display: flex;
      gap: 4px;
      margin: 0;
      align-items: center;
      justify-content: space-around;
    }
    .bottom {
      display: none;
    }

    .Activelink,
    link {
      border-left: none;
      border-bottom: 2px solid $primary-color;
      display: block;
      font-size: 16px !important;
      padding: 5px 8px;
      i {
        padding: 8px;
      }
    }
  }

  @media screen and (max-width: 480px) {
    .bottom {
      display: none;
    }
    .Activelink,
    link {
      border-left: none;
      border-bottom: 3px solid $primary-color;
      display: block;
      font-size: 12px !important;
      padding: 5px 2px;
      i {
        padding: 8px;
      }
    }
  }
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
    // border-radius: 12px 6px 10px 8px;
  }
  50% {
    background-position: 100% 50%;
    // border-radius: 8px 10px 6px 12px;
  }
  100% {
    background-position: 0% 50%;
    // border-radius: 12px 6px 10px 8px;
  }
}

@keyframes popAnimation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes pulseCircle {
  from {
    transform: scale(0.5, 0.5);
    opacity: 0.5;
  }
  to {
    transform: scale(1.6, 1.6);
    opacity: 0;
  }
}

@keyframes slideLeftToRight {
  0% {
    transform: translateX(-5%);
  }
  100% {
    transform: translateX(0%);
  }
}

@keyframes shine-glow {
  0% {
    text-shadow: 0 0 10px #cb9b18 0 0 20px rgba(255, 223, 186, 0.5);
  }
  50% {
    text-shadow: 0 0 20px rgba(255, 223, 186, 0.8),
      0 0 30px rgba(255, 223, 186, 0.6);
  }
  100% {
    text-shadow: 0 0 10px rgba(255, 223, 186, 1),
      0 0 20px rgba(255, 223, 186, 0.5);
  }
}

@keyframes shine-glow2 {
  0% {
    text-shadow: 0 0 30px #cb9b18 0 0 40px #ff951cb9;
  }
  50% {
    text-shadow: 0 0 240px rgba(255, 143, 14, 0.8), 0 0 40px #fc9b2c99;
  }
  100% {
    text-shadow: 0 0 30px rgb(255, 200, 0), 0 0 40px rgba(255, 203, 15, 0.944);
  }
}

@keyframes pop-in-scale {
  0% {
    opacity: 0;
    width: 90%; /* Start width */
  }
  100% {
    opacity: 1;
    width: 95%; /* End width */
  }
}


@keyframes slideUp {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0%);
  }
}