$primary-color: #1a73e8;
$secondary-color: #f9f9f9;
$button-bg: #2a2a2a;
$box-shadow-default: 10px 4px 8px rgba(0, 0, 0, 0.233), 0 0 15px rgba(0, 0, 0, 0.173);

.groupcoding-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 40px);
    background: linear-gradient(135deg, #e0eafc, #cfdef3);
    text-align: center;
    padding: 20px;

    .content {
        background-color: $secondary-color;
        padding: 40px;
        border-radius: 20px;
        box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
        max-width: 600px;
        width: 100%;
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
        align-items: center;


        @media screen and (max-width: 768px) {
            border-radius: 8px;
        }

        @media screen and (max-width: 480px) {
            border-radius: 8px;
        }

        .thumb-icon {
            font-size: 40px;
            margin-bottom: 20px;


        }

        .still-text {
            font-size: 1.2rem;
            color: #888;
            margin-bottom: 10px;
        }

        .main-title {
            font-size: 2.5rem;
            font-weight: bold;
            color: $primary-color;
            margin-bottom: 20px;
        }

        .description {
            font-size: 1.1rem;
            color: #555;
            margin-bottom: 30px;
        }

        .cta-button {
            background-color: $button-bg;
            color: white;
            padding: 12px 30px;
            font-size: 18px;
            border: none;
            border-radius: 8px;
            cursor: pointer;
            gap: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: background-color 0.3s ease;

            &:hover {
                background-color: darken($button-bg, 10%);
            }
        }

        .social-icons {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 30px;
            gap: 10px;
        }

        a {
            color: #fff;
            font-size: 14px;
            padding: 8px;
            border-radius: 6px;
            text-decoration: none;
            transition: box-shadow 0.3s ease;

            @media screen and (max-width: 768px) {
                padding: 8px;
                font-size: 14px;
            }

            @media screen and (max-width: 480px) {
                font-size: 12px;
                padding: 6px;
            }

            &:hover {
                box-shadow: $box-shadow-default;
            }
        }
    }

    @media screen and (max-width: 768px) {
        .content {
            padding: 30px;
            max-width: 90%;

            .main-title {
                font-size: 2rem;
            }

            .description {
                font-size: 1rem;
            }

            .cta-button {
                font-size: 16px;
                padding: 10px 25px;
            }
        }
    }

    @media screen and (max-width: 480px) {
        .content {
            padding: 20px;
            max-width: 100%;

            .thumb-icon {
                font-size: 35px;
                margin-bottom: 15px;
            }

            .still-text {
                font-size: 1rem;
            }

            .main-title {
                font-size: 1.8rem;
            }

            .description {
                font-size: 0.95rem;
            }

            .cta-button {
                font-size: 14px;
                padding: 8px 20px;
            }
        }
    }
}

/* Social media share button styles */

/* Social media share button styles */
.instagramShare, .githubShare, .linkedinShare, .facebookShare, .whatsappShare {
    padding: 8px 12px;
    border-radius: 6px;
    text-decoration: none;
    transition: box-shadow 0.3s ease;
    margin: 5px;

    &:hover {
        box-shadow: 10px 4px 8px rgba(0, 0, 0, 0.233), 0 0 15px rgba(0, 0, 0, 0.173);
    }

    @media screen and (max-width: 768px) {
        padding: 6px 10px;
    }

    @media screen and (max-width: 480px) {
        padding: 5px 8px;
        font-size: 12px;
    }
}

.instagramShare {
    background: linear-gradient(to right, #f9ce34, #ee2a7b, #6228d7);
}

.githubShare {
    background: linear-gradient(to right, #24292e, #24292e);
}

.linkedinShare {
    background: linear-gradient(to right, #0077b5, #00a2e1);
}

.facebookShare {
    background: linear-gradient(to right, #3b5998, #0077b5);
}

.whatsappShare {
    background: linear-gradient(to right, #075e54, #022f2a);
}
