/* Styling Variables */
$input-background-color: #f3f6ff;
$input-padding: 15px 20px;
$input-border-radius: 22px;
$button-border-radius: 8px;
$button-hover-background-color: #1050d1;

/* Keyframe Animations */
@keyframes slideUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes popUp {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  33% {
    opacity: 1;
    transform: scale(1.1);
  }
  66% {
    opacity: 1;
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes rotateIn {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes zoomIn {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

/* Navbar Styling */
.navbar {
  height: 50px;
  padding: 10px;
  border-bottom: #b1b1b138 1px solid;

  .user {
    display: flex;
    gap: 10px;
    width: 100%;
    justify-content: space-between;
    animation: fadeIn 0.6s ease-out;

    img {
      height: 40px;
      width: 40px;
      border-radius: 50%;
      object-fit: cover;
      border: #286eff 3px solid;
      padding: 2px;
      cursor: pointer;
      animation: zoomIn 0.2s ease-out;

      &:hover {
        border: #d24141 3px solid;
      }
    }

    .userData {
      text-align: left;
      width: calc(100% - 90px);

      h4 {
        color: #424242;
        font-size: 22px;
      }

      .joined {
        font-size: 11px;
        color: #286eff;
      }
    }

    .right {
      padding: 10px;
      width: 20px;

      i {
        padding: 10px;

        &:hover {
          background-color: #286eff;
          color: white;
          border-radius: 50%;
          cursor: pointer;
          color: #fff;
        }
      }
    }

    .menu {
      position: absolute;
      height: auto;
      width: calc(100% - 40px);
      left: 0%;
      top: 0%;
      border-radius:0px;
      // box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      box-shadow: #1050d19c 10px 0px 20px;
      z-index: 10;
      padding: 6%;
      background-color: white;
      background-image: url("../img/gradBack.png");
      animation: popfromTop 0.3s ease-out, fadeIn 0.5s ease-out;
      word-wrap: break-word;

      @media screen and (max-width: 480px) {
        width: calc(100% - 50px);
        left: 0%;
        top: 0;
        
      }
      

      .userProfileData {
        display: flex;
        gap: 5;

        .uploadError {
          color: red;
          text-align: center;
          font-size: 12px;
          p,
          .error {
            font-size: 11px;
          }
        }

        .custom-file-upload {
          position: relative;

          i {
            position: absolute;
            top: -10%;
            right: 5%;
            background-color: #7d7d7d;
            border-radius: 4px;
            padding: 5px;
            color: white;

            &:hover {
              background-color: #286eff;
              cursor: pointer;
            }
          }
        }
      }

      .content-top {
        padding: 10px;

        input,
        select {
          border: none;
          padding: 4px 6px;
          border-radius: 4px;
          margin: 5px 0px;
          width: 80%;
          background-color: $input-background-color; /* Using SCSS variable */
          transition: background-color 0.2s ease-in-out;
          color: #424242;
          box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
          animation: popUp 0.5s ease-out;

          &:hover {
            background-color: #e5e8ff;
          }

          &:focus {
            outline: none;
            background-color: #e5e8ff;
          }
        }

        select {
          width: 85%;
          background-color: $input-background-color; /* Using SCSS variable */
          transition: background-color 0.2s ease-in-out;
          color: #424242;
          box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
          animation: popUp 0.5s ease-out;

          &:hover {
            background-color: #e5e8ff;
          }

          &:focus {
            outline: none;
            background-color: #e5e8ff;
          }
        }
      }

      .joined {
        font-size: 12px;
        color: #286eff;
        margin-bottom: 30px;
        margin-left: 15px;
      }

      img {
        width: 80px;
        height: 80px;
        object-fit: cover;
        object-position: center;
        animation: popUp 0.5s ease-out;
      }

      .name {
        padding: 4px 15px;
        font-size: 18px;
        font-weight: 600;
        text-align: left;
      }
      .dating{
        color: #424242;
        margin-left: 15px;
        span{
          padding: 2px;
          color: #d24141;
        }
      }
      .username {
        padding: 4px 15px;
        font-size: 16px;
        text-align: left;
      }

      .buttons {
        display: flex;
        gap: 5px;
        justify-content: center;
      }

      .updatebtn {
        background-color: #286eff;
        &:hover {
          background-color: #033391;
        }
      }

      .canclebtn {
        background-color: #d24141;
        &:hover {
          background-color: #a91d1d;
        }
      }

      .editbtn {
        background-color: #286eff;
        &:hover {
          background-color: #033391;
        }
      }
      .settings{
        background-color: #286eff;
        border-radius: $button-border-radius; /* Using SCSS variable */
        border: none;
        color: #fff;
        padding: 5px 10px;
        text-align: center;
        transition: background-color 0.2s ease-in-out;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        animation: popUp 0.5s ease-out;
        text-decoration: none;
        position: relative;

        &::after {
          content: "New";
          position: absolute;
          background-color: #d24141;
          border-radius: 50%;
          padding: 0px 3px;
          width: 20px;
          height: 26px;
          font-size: 9px;
          display: flex;
          align-items: center;
          justify-content: center;
          top: -15%;
          right: -22%;
          transform: translate(-50%, -50%);
          color: #fff;
          z-index: 5;

        }

        &:hover {
          background-color: #033391;
        }
        
      }

      button {
        background-color: #286eff;
        border-radius: $button-border-radius;
        border: none;
        color: #fff;
        padding: 5px 10px;
        transition: background-color 0.2s ease-in-out;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        animation: popUp 0.5s ease-out;

        &:hover {
          cursor: pointer;
          background-color: $button-hover-background-color; /* Using SCSS variable */
        }
      }

      .exitbtn {
        position: absolute;
        top: 0%;
        right: 0%;
        padding: 8px;
        border-radius: 7%;
        height: 30px;
        width: 30px;
        background-color: #d24141;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          cursor: pointer;
        }
      }

      .logout {
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        animation: fadeIn 0.6s ease-out;
        background-color: #d24141;
      }
    }
  }
}

/* Additional Animations */

/* Appear Animation */
.appear {
  opacity: 0;
  transform: translateY(20px);
  animation: slideUp 0.3s ease-in-out forwards;
}

/* Disappear Animation */
.disappear {
  opacity: 1;
  transform: translateY(0);
  animation: slideUp 0.3s ease-in-out reverse forwards;
}


@media screen and (max-width: 480px) {
  .navbar{
    padding-top: 10px;
    height: none;
  }
}

@keyframes popfromTop { 
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  50%{
    opacity: 0.5;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
  
}