$background-color: #f8f9fa;
$text-color: #333;

.nearby-restaurants {
  display: flex;
  font-family: Arial, sans-serif;
  height: 100vh;
  @media screen and (max-width: 768px){
    flex-direction: column;
  }
  
}