.tab-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 35%;
  margin: 2% 0;

  @media screen and (max-width: 480px) {
    width: 100%;
  }

  @media screen and (max-width: 768px){
    width: 100%;
  }


  .ubuttons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    flex-direction: column;
    width: 100%;

    @media screen and (max-width: 480px) {
      flex-direction: row;
      width: 100%;
      gap: 10px;
    }

    @media screen and (max-width: 768px){
      flex-direction: row;
      width: 100%;
      gap: 10px;
    }

    .tab-button {
      width: 85%;
      display: flex;
      align-items: center;
      flex-direction: row;
      background-color: #fff;
      border: none;
      box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
      padding: 10px 20px;
      border-radius: 8px;
      gap: 20px;
    
      @media screen and (max-width: 480px) {
        width: 30%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100px;
        margin-bottom: 1%;
      }

        @media screen and (max-width: 768px){
            width: 30%;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }


      i {
        font-size: 26px;
        color: #242424;
      }

      div {
        display: flex;
        align-items: start;
        justify-content: start;
        flex-direction: column;
        p {
          font-weight: 400;
          color: #6c6c6c;

            @media screen and (max-width: 480px) {
                display: none;
            }

            @media screen and (max-width: 768px){
                display: none;
            }

        }
      }

      &:hover {
        cursor: pointer;
      }

      &.active {
        box-shadow: rgba(40, 112, 255, 0.26) 2px 4px 10px;
        border: 1px solid #286eff;
        // transition-duration: 0.5s;
        i {
          color: #286eff;
          animation: popupIcon 0.5s ease-in-out 1;
          &.fa-box-heart {
            background: linear-gradient(75deg, #eb3fb3, #e125cc, #c718e7);
            -webkit-background-clip: text;
            background-clip: text;
            color: transparent;
          }
        }
      }
    }
  }
}

@keyframes popupIcon {
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
