 .sidebarRight {
    width: 30%;
    //background-image: url("https://source.unsplash.com/1600x900/?study,laptop,desktop,study-room");
    height: 100vh;
    background-color: #dce8fe;
    background-image: url('../img/gradBack3.jpg');
    background-size: cover;
    background-position: bottom center;
    
    
  }