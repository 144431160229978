@import url("https://fonts.googleapis.com/css2?family=Loved+by+the+King&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Preahvihear&display=swap");

.timer-main {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  .timer-running {
    width: 50%;
    min-height: 300px;
    background-color: #fff;
    background-image: url("../img/timerbg.jpg");
    background-size: cover;
    padding: 20px 60px;
    padding-top: 8%;
    border-radius: 8px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    text-align: center;
    z-index: 10;
    animation: slideInFromRight 1s ease-in-out;
    position: absolute;
    .ribbon {
      position: absolute;
      top: 0%;
      right: -3.5%;
      width: 300px;
      @media screen and (max-width: 480px) {
        width: 200px;
      }
    }
    .ribbon1 {
      position: absolute;
      top: 0%;
      left: -0%;
      width: 300px;
      @media screen and (max-width: 480px) {
        width: 200px;
      }
    }
    .ribbon4 {
      position: absolute;
      bottom: -10%;
      left: -10%;
      width: 300px;
      @media screen and (max-width: 480px) {
        width: 200px;
      }
    }

    &:nth-child(1) {
      margin-right: -80px;
      margin-top: -80px;
      background-image: url("../img/timerbg2.jpg");
      transform: rotate(3deg);
      animation: slideInFromLeft 1s ease-in-out;
    }

    &:nth-child(2) {
      margin-right: -60px;
      margin-top: 50px;
      background-image: url("../img/timerbg4.jpg");
      transform: rotate(-4deg);
      animation: slideInFromTop 1s ease-in-out;
    }

    &:nth-child(3) {
      margin-right: 80px;
      margin-bottom: 50px;
      background-image: url("../img/timerbg3.jpg");
      transform: rotate(-7deg);
      background-position: right;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      animation: slideInFromRight 1s ease-in-out;
    }
    &:nth-child(4) {
      margin-right: 100px;
      margin-bottom: 70px;
      background-image: url("../img/timerbg7.jpg");
      transform: rotate(4deg);
      background-position: right;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      animation: slideInFromRight 1s ease-in-out;
    }

    @media screen and (max-width: 480px) {
      width: 80%;
      min-height: fit-content;
      background-image: url("../img/timerbg.jpg");
      &:nth-child(3),
      &:nth-child(2),
      &:nth-child(1) {
        display: none;
      }
    }

    h1 {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      font-family: "Loved by the King", cursive;
      font-weight: 900;
      font-size: 52px;
      color: #5f611d;

      span {
        font-family: "Loved by the King", cursive;
        font-size: 19px;
      }
    }
    h3 {
      font-family: "Preahvihear", sans-serif;
      color: #B01313;
    }

    p {
      font-family: "Preahvihear", sans-serif;
      color: #cc8f0e;
      
    }
    .quote{
        color: #ae268c;
    }
  }
}

@keyframes slideInFromLeft {
  from {
    transform: translateX(-40%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromTop {
  from {
    transform: translateY(-30%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideInFromRight {
  from {
    transform: translateX(-20%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
