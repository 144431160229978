// Variables (if needed)
$COLOR_OTHER: var(--COLOR_OTHER);

// Main styles
.picture__content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  margin: 0 5%;

  &.other {
    flex-direction: row-reverse;
  }
}

.picture {
  max-width: 20rem;
  border-radius: 8px;
  background: #0044d0;
  box-shadow: 8px 8px 16px #0b0b0b, -8px -8px 16px #212121;
  border-radius: 12px;
  padding: 2px;

  &.other {
    background: #161616;
    color: #fff;
    border-radius: 12px;
  }

  > img {
    width: 100%;
    border-radius: 12px;
    
  }
}

// Media query breakpoints
@media (max-width: 700px) {
  .picture__content {
    gap: 0.5rem;
  }

  .picture {
    max-width: 15rem;
  }
}

@media (max-width: 600px) {
  .picture {
    max-width: 12rem;
  }
}
