$primary-color: #558af3;
$secondary-color: #033391;

.user__detail {
  height: 100vh;
  position: relative;
  background: linear-gradient(55deg, #16191F, #16191F);
  // box-shadow: 4.2px 4.2px 7px #0e1013, -4.2px -4.2px 7px #272f3d;
  z-index: 2;
  overflow: hidden;

  @media (max-width: 480px) {
    width: 60%;
  }

  .blur__bg {
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    height: 100vh;
    top: 0;
    width: 100%;
    z-index: -1;
    background-position: center;
  }
  .user__detail__wrapper {
    .user__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 11px 20px;
      background: linear-gradient(145deg, #e1e1e1, #7e8faa);
      box-shadow: 4.2px 4.2px 7px #0e1013, -4.2px -4.2px 7px #292f39;
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent;
      animation: popAnimation 0.3s ease-in-out 1;
      .mobileView {
        display: none;
      }
      .default {
        display: block;
      }
      @media (max-width: 480px) {
        .default {
          display: none;
        }
        .mobileView {
          display: block;
        }
      }
      .fa-chevron-left {
        flex: 1;
        font-size: 22px;
        &:hover {
          cursor: pointer;
        }
      }
      .nowrap {
        flex: 5;
      }
    }

    .user__names {
      display: flex;
      flex-direction: column;
      align-items: start;
      height: 100vh;
      overflow: scroll;
      padding-top: 10px;
      .user {
        padding: 8px 12px;
        margin: 2%;
        margin-left: 3%;
        border-radius: 10px;
        width: calc(100% - 40px);
        display: flex;
        gap: 10px;
        justify-content: start;
        align-items: center;
        // background-color: #fff;
        font-weight: 600;
        color: #fff;
        // opacity: 0.84;
        background: linear-gradient(145deg, #16191f, #001430);
        box-shadow: 4.2px 4.2px 7px #0f1010, -4.2px -4.2px 7px #1d242d;
        // width: 100%;

        .king {
          position: relative;

          i {
            position: absolute;
            right: -20%;
            top: -11%;
            transform: rotate(50deg);
            color: #ffd700;
            z-index: 1;
          }
        }
        img {
          width: 40px;
          height: 40px;
          object-fit: cover;
          object-position: center;
          border-radius: 100%;
          z-index: 8;
        }
        span {
          font-size: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 5px;
          color: #ffd700;
        }
      }
    }
  }
}
