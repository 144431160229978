.index__page {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
  background-color: #faf1ed;
  background-image: url("../../assets/groupchatBgColor2.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: 25%;
  height: 100vh;

  .index__contents {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    align-content: center;
    flex-wrap: nowrap;
    gap: 5rem;
    margin: 5rem 0rem 5rem 2rem;
    @media screen and (max-width: 480px) {
      flex-direction: column;
      margin: 1rem 2rem;
    }

    .index__banner__text {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      gap: 1rem;
      align-items: center;
      width: 50%;

      @media screen and (max-width: 480px) {
        width: 100%;
        align-items: center;
        justify-content: center;
      }

      .hideOnMobile {
        @media screen and (max-width: 480px) {
          display: none;
        }
      }

      .groupChat {
        text-align: center;
        img {
          width: 60%;
          animation: bounce 2s infinite ease-in-out;
        }
      }

      h1 {
        font-size: 3rem;
        letter-spacing: 1px;
        text-transform: capitalize;

        @media screen and (max-width: 480px) {
          font-size: 1.8rem;
          text-align: center;
        }

        span {
          color: #f86320;
          animation: colorChange 3s infinite alternate;
        }
      }

      h3 {
        font-size: 1rem;
        letter-spacing: 1px;
        line-height: 1.5;
        font-weight: 300;
        margin-top: 4%;
        text-align: justify;

        span {
          text-transform: capitalize;
          font-weight: 600;
          color: #f86320;
          // animation: colorChange 3s infinite alternate;
        }
      }
    }

    .index__btns {
      display: flex;
      flex-direction: row;
      gap: 2rem;
      
      @media screen and (max-width: 480px) {
        gap: 1rem;
      }
      
      .start_a_room,
      .join_a_room {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        background-color: #fff;
        padding: 10px;
        margin: 2rem 0rem;
        border-radius: 12px;
        min-width: 200px;
        min-height: 150px;
        position: relative;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

        @media screen and (max-width: 480px) {
          padding: 4px;
          margin: 1rem 0rem;
          min-width: 150px;
          min-height: 100px;
        }

        img {
          width: 150px;
          height: 150px;
          object-fit: contain;
        }
        &:hover {
          box-shadow: none;
          transition-duration: 0.6s;
          cursor: pointer;
        }
      }

      button {
        padding: 0.75rem 1rem;
        border: none;
        outline: none;
        cursor: pointer;
        border-radius: 22px;
        transition: all 0.3s ease-in-out;

        &.btn__new__chat {
          background-color: #f86320;
          background: linear-gradient(45deg, #f86320, #ffdbca, #f86320);
          background-size: 600% 600%;
          animation: gradientAnimation 4s linear infinite, popAnimation 0.3s ease;
          color: #fff;
          box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
          border-radius: 22px !important;

          &:focus,
          &:hover {
            font-weight: 600;
            color: #FFFF;
            animation: hoverAnimation 0.3s ease-in-out;
          }
        }

        &.btn__old__chat {
          background-color: #fff;
          box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;

          &:focus,
          &:hover {
            color: #f86320;
            font-weight: 600;
            animation: hoverAnimation 0.3s ease-in-out;
          }
        }
      }
    }

    .index__banner__img {
      width: 35%;
      display: flex;
      justify-content: center;
      align-items: center;

      @media screen and (max-width: 480px) {
        display: none;
      }

      img {
        padding-top: 10%;
        width: 95%;
        vertical-align: middle;
        // animation: slideLeft 2s ease-in-out infinite;
      }
    }
  }
}

@keyframes shining-animation {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

@keyframes bounce {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(50px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes colorChange {
  0% {
    color: #000;
  }
  100% {
    color: #f86320;
  }
}

@keyframes hoverAnimation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes slideLeft {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  50% {
    transform: translateX(-20px);
    opacity: 0.8;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@media (max-width: 885px) {
  .index__contents {
    gap: 4rem;
    margin: 3rem 0rem 3rem 2rem;

    h1 {
      font-size: 2.8rem;
    }
  }
}

@media (max-width: 856px) {
  .index__contents {
    gap: 2rem;
    margin: 2rem 0rem 2rem 2rem;

    h1 {
      font-size: 2.6rem;
    }

    h3 {
      font-size: 0.9rem;
    }
  }
}

@media (max-width: 755px) {
  h1 {
    font-size: 2.2rem;
  }

  h3 {
    font-size: 0.8rem;
  }

  .index__btns {
    gap: 1rem;
  }

  button {
    font-size: 0.8rem;
  }
}

@media (max-width: 560px) {
  .index__contents {
    margin: 5rem 2rem 1rem;

    .index__banner__text {
      align-items: normal;
    }

    .index__btns {
      gap: 1rem;
      align-items: center;
      justify-content: center;

      button {
        padding: 0.6rem 0.8rem;
      }
    }

    .index__banner__img {
      display: none;
    }
  }
}

@media (max-width: 323px) {
  .index__contents {
    margin: 3rem 2rem 3rem;

    button {
      letter-spacing: 1px;
    }
  }
}

@keyframes gradientAnimation3 {
  0% {
    background-position: 0% 0%, 0% 50%;
  }
  100% {
    background-position: 100% 100%, 100% 50%;
  }
}
